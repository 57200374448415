import hardCodedValues from "../hardCodedValues";

export const validInches = function (measurement) {
  const valid = typeof measurement === "number" && measurement >= 0;
  if (!valid) {
    return valid;
  }

  const smallestIncrement = 1 / 16;
  const remainder = measurement % smallestIncrement;
  return remainder === 0;
};

export const validReveal = function (reveal) {
  const properties = [
    "topInInches",
    "rightInInches",
    "bottomInInches",
    "leftInInches"
  ];
  let valid = (typeof reveal === "object" && !Array.isArray(reveal));
  if (!valid) {
    return valid;
  }

  properties.forEach(function (property) {
    if (!(validInches(reveal[property]) &&
      reveal[property] >= 0)) {
      valid = false;
    }
  });

  return valid;
};

export const validMonogram = function (monogram) {
  const properties = [
    "fontStyle",
    "foilColor",
    "line1",
    "line2"
  ];
  let valid = (typeof monogram === "object" && !Array.isArray(monogram));
  if (!valid) {
    return valid;
  }

  properties.forEach(function (property) {
    if (typeof monogram[property] !== "string") {
      valid = false;
    }
  });

  return valid;
};

export const validMatSku = function (sku) {
  let valid = typeof sku === "string";
  if (!valid) {
    return valid;
  }
  const allMatOptions = hardCodedValues.primaryMatColors;
  const matches = allMatOptions.filter(function (matOption) {
    return !matOption.fake && matOption.name === sku;
  });
  valid = matches.length > 0;
  return valid;
};

export const validMountSpec = function (mountSpec) {
  if (!mountSpec) {
    return false;
  }

  if (mountSpec.name === "ACRY01") {
    return typeof mountSpec === "object" &&
      typeof mountSpec.elevated === "boolean";
  } else {
    return typeof mountSpec === "object" &&
      typeof mountSpec.elevated === "boolean" &&
      validMatSku(mountSpec.name) &&
      validReveal(mountSpec.reveal);
  }
};

export const validMatSpec = function (matSpec) {
  return typeof matSpec === "object" &&
    validMatSku(matSpec.name) &&
    validReveal(matSpec.reveal);
};

export const validMatsSpec = function (matsSpec) {
  let valid = typeof matsSpec === "object" && Array.isArray(matsSpec);
  if (!valid) {
    return valid;
  }

  matsSpec.forEach(function (matSpec) {
    if (valid) {
      valid = validMatSpec(matSpec);
    }
  });

  return valid;
};

const ifDefinedMatchesType = function (value, desiredType) {
  const actualType = typeof value;
  return actualType === "undefined" || actualType === desiredType;
};

export const validMouldingSpec = function (mouldingSpec) {
  return typeof mouldingSpec === "object" &&
    typeof mouldingSpec.permalink === "string" &&
    // make sure the optional stuff is at least of the right type when present
    ifDefinedMatchesType(mouldingSpec.mouldingWidth, "number") &&
    ifDefinedMatchesType(mouldingSpec.url, "string") &&
    ifDefinedMatchesType(mouldingSpec.widthInPixels, "number") &&
    ifDefinedMatchesType(mouldingSpec.heightInPixels, "number");
};

export const okUploadedArtwork = function (artwork) {
  if (typeof artwork !== "object") {
    return true;
  }
  return ifDefinedMatchesType(artwork.url, "string") &&
    ifDefinedMatchesType(artwork, "object") &&
    ifDefinedMatchesType(artwork.width, "number") &&
    ifDefinedMatchesType(artwork.height, "number");
};

export const validArtworkSpec = function (artworkSpec) {
  return typeof artworkSpec === "object" &&
    typeof artworkSpec.croppedAndNormalized === "object" &&
    typeof artworkSpec.croppedAndNormalized.url === "string" &&
    typeof artworkSpec.exterior === "object" &&
    typeof artworkSpec.exterior.widthInInches === "number" &&
    typeof artworkSpec.exterior.heightInInches === "number" &&
    typeof artworkSpec.offset === "object" &&
    (typeof artworkSpec.offset.topInInches === "number" ||
      artworkSpec.offset.topInInches === "auto") &&
    (typeof artworkSpec.offset.leftInInches === "number" ||
      artworkSpec.offset.leftInInches === "auto") &&
    // make sure the optional stuff is at least of the right type when present
    okUploadedArtwork(artworkSpec.croppedAndNormalized) &&
    okUploadedArtwork(artworkSpec.original);
};

export const validArtworksSpec = function (artworksSpec) {
  let valid = typeof artworksSpec === "object" && Array.isArray(artworksSpec);
  if (!valid) {
    return valid;
  }

  artworksSpec.forEach(function (artworkSpec) {
    if (valid) {
      valid = validArtworkSpec(artworkSpec);
    }
  });

  return valid;
};

export const validFrameSpec = function (frameSpec) {
  return typeof frameSpec === "object" &&
    validMatsSpec(frameSpec.mats) &&
    validMountSpec(frameSpec.mount) &&
    validArtworksSpec(frameSpec.artworks) &&
    validMouldingSpec(frameSpec.moulding);
};

export default {
  validInches,
  validReveal,
  validMonogram,
  validMatSku,
  validMountSpec,
  validMatSpec,
  validMatsSpec,
  validMouldingSpec,
  okUploadedArtwork,
  validArtworkSpec,
  validArtworksSpec,
  validFrameSpec
};
