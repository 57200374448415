import TRBL from "../TRBL";

// Preliminary versioning for URL-based frame spec data.
export const version = "1";
export const INDEX_REGEX = /_(\d*)/gm;
export const INDEX_CHAR = "_";

export const shortFrameSpecMap = {
  "a_cr-d-h":   {"artworks": Array, "croppedAndNormalized": Object, "dimensionsInPixels": Object, "height": Number},
  "a_cr-d-w":   {"artworks": Array, "croppedAndNormalized": Object, "dimensionsInPixels": Object, "width": Number},
  "a_cr-u":     {"artworks": Array, "croppedAndNormalized": Object, "url": String},
  "a_cs-d-h":   {"artworks": Array, "customerSpecified": Object, "dimensionsInPixels": Object, "height": Number},
  "a_cs-d-w":   {"artworks": Array, "customerSpecified": Object, "dimensionsInPixels": Object, "width": Number},
  "a_cs-u":     {"artworks": Array, "customerSpecified": Object, "url": String},
  "a_ou-d-h":   {"artworks": Array, "originalUnedited": Object, "dimensionsInPixels": Object, "height": Number},
  "a_ou-d-w":   {"artworks": Array, "originalUnedited": Object, "dimensionsInPixels": Object, "width": Number},
  "a_ou-u":     {"artworks": Array, "originalUnedited": Object, "url": String},
  "a_ed-cz-t":  {"artworks": Array, "edits": Object, "cropZone": Object, "top": Number},
  "a_ed-cz-l":  {"artworks": Array, "edits": Object, "cropZone": Object, "left": Number},
  "a_ed-cz-h":  {"artworks": Array, "edits": Object, "cropZone": Object, "height": Number},
  "a_ed-cz-w":  {"artworks": Array, "edits": Object, "cropZone": Object, "width": Number},
  "a_ed-b":     {"artworks": Array, "edits": Object, "brighten": Boolean},
  "a_ed-gs":    {"artworks": Array, "edits": Object, "grayscale": Boolean},
  "a_ed-rc":    {"artworks": Array, "edits": Object, "rotationCount": Number},
  "a_e-h":      {"artworks": Array, "exterior": Object, "heightInInches": Number},
  "a_e-w":      {"artworks": Array, "exterior": Object, "widthInInches": Number},
  "a_e-s":      {"artworks": Array, "exterior": Object, "sizeGroup": String},
  "a_o-t":      {"artworks": Array, "offset": Object, "topInInches": String},
  "a_o-l":      {"artworks": Array, "offset": Object, "leftInInches": String},
  "a_cmmn":     {"artworks": Array, "completedMafMatName": String},
  "a_id":       {"artworks": Array, "artworkId": Number},
  "a_t":        {"artworks": Array, "token": String},
  "a_pc":       {"artworks": Array, "productCategory": String},
  "a_pc-n":     {"artworks": Array, "productCategoryName": String},
  "a_at-s":     {"artworks": Array, "artTypeSlug": String},
  "a_c":        {"artworks": Array, "conveyance": String},
  "a_m":        {"artworks": Array, "medium": String},
  "a_sa":       {"artworks": Array, "sampleArt": Boolean},
  "a_fn":       {"artworks": Array, "imageFileName": String},
  "a_th":       {"artworks": Array, "thumbnail": Object, "url": String},
  "a_di":       {"artworks": Array, "allowDistortedImage": Boolean},
  "a_mq":       {"artworks": Array, "maxQuality": Boolean},
  "a":          {"artworks": Array},
  "ma_n":       {"mats": Array, "name": String},
  "ma_r-t":     {"mats": Array, "reveal": Object, "topInInches": Number},
  "ma_r-r":     {"mats": Array, "reveal": Object, "rightInInches": Number},
  "ma_r-b":     {"mats": Array, "reveal": Object, "bottomInInches": Number},
  "ma_r-l":     {"mats": Array, "reveal": Object, "leftInInches": Number},
  "ma_r":       {"mats": Array, "reveal": TRBL},
  "ma_mo-fs":   {"mats": Array, "monogram": Object, "fontStyle": String},
  "ma_mo-fc":   {"mats": Array, "monogram": Object, "foilColor": String},
  "ma_mo-l1":   {"mats": Array, "monogram": Object, "line1": String},
  "ma_mo-l2":   {"mats": Array, "monogram": Object, "line2": String},
  "ma_mc-fs":   {"mats": Array, "matCaption": Object, "fontStyle": String},
  "ma_mc-fc":   {"mats": Array, "matCaption": Object, "fontColor": String},
  "ma_mc-fa":   {"mats": Array, "matCaption": Object, "fontAlignment": String},
  "ma_mc-l1":   {"mats": Array, "matCaption": Object, "message": Object, "line1": String},
  "ma_mc-l2":   {"mats": Array, "matCaption": Object, "message": Object, "line2": String},
  "ma_mc-l3":   {"mats": Array, "matCaption": Object, "message": Object, "line3": String},
  "ma_mc-l4":   {"mats": Array, "matCaption": Object, "message": Object, "line4": String},
  "ma":         {"mats": Array},
  "ma_mc-ln":   {"mats": Array, "matCaption": Object, "layoutName": String},
  "ma_mc-l1fa": {"mats": Array, "matCaption": Object, "lines": Object, "line1": Object, "fontAlignment": String},
  "ma_mc-l1fs": {"mats": Array, "matCaption": Object, "lines": Object, "line1": Object, "fontStyle": String},
  "ma_mc-l1fz": {"mats": Array, "matCaption": Object, "lines": Object, "line1": Object, "fontSize": String},
  "ma_mc-l1m":  {"mats": Array, "matCaption": Object, "lines": Object, "line1": Object, "message": String},
  "ma_mc-l2fa": {"mats": Array, "matCaption": Object, "lines": Object, "line2": Object, "fontAlignment": String},
  "ma_mc-l2fs": {"mats": Array, "matCaption": Object, "lines": Object, "line2": Object, "fontStyle": String},
  "ma_mc-l2fz": {"mats": Array, "matCaption": Object, "lines": Object, "line2": Object, "fontSize": String},
  "ma_mc-l2m":  {"mats": Array, "matCaption": Object, "lines": Object, "line2": Object, "message": String},
  "ma_mc-l3fa": {"mats": Array, "matCaption": Object, "lines": Object, "line3": Object, "fontAlignment": String},
  "ma_mc-l3fs": {"mats": Array, "matCaption": Object, "lines": Object, "line3": Object, "fontStyle": String},
  "ma_mc-l3fz": {"mats": Array, "matCaption": Object, "lines": Object, "line3": Object, "fontSize": String},
  "ma_mc-l3m":  {"mats": Array, "matCaption": Object, "lines": Object, "line3": Object, "message": String},
  "ma_mc-l4fa": {"mats": Array, "matCaption": Object, "lines": Object, "line4": Object, "fontAlignment": String},
  "ma_mc-l4fs": {"mats": Array, "matCaption": Object, "lines": Object, "line4": Object, "fontStyle": String},
  "ma_mc-l4fz": {"mats": Array, "matCaption": Object, "lines": Object, "line4": Object, "fontSize": String},
  "ma_mc-l4m":  {"mats": Array, "matCaption": Object, "lines": Object, "line4": Object, "message": String},
  "md-p":       {"moulding": Object, "permalink": String},
  "md-pl-fs":   {"moulding": Array, "mouldingPlate": Object, "fontStyle": String},
  "md-pl-s":    {"moulding": Array, "mouldingPlate": Object, "sku": String},
  "md-pl-l1":   {"moulding": Array, "mouldingPlate": Object, "line1": String},
  "md-pl-l2":   {"moulding": Array, "mouldingPlate": Object, "line2": String},
  "mo-e":       {"mount": Object, "elevated": Boolean},
  "mo-n":       {"mount": Object, "name": String},
  "mo-r-t":     {"mount": Object, "reveal": Object, "topInInches": Number},
  "mo-r-r":     {"mount": Object, "reveal": Object, "rightInInches": Number},
  "mo-r-b":     {"mount": Object, "reveal": Object, "bottomInInches": Number},
  "mo-r-l":     {"mount": Object, "reveal": Object, "leftInInches": Number},
  "mo-r":       {"mount": Object, "reveal": TRBL},
  "sp-b":       {"storyPocket": Object, "body": String},
  "msp-b":      {"miniStoryPocket": Object, "body": String},
  "ckp-b":      {"customKraftPaper": Object, "body": String},
	"ca-b":				{"customAcrylic": Object, "body": String},
  "gb-b":       {"giftBox": Object, "body": String},
  "d-m":        {"designMethod": String},
  "ver":        {"version": String},
  "n":          {"number": String},
  "ckp-s":       {"customKraftPaper": Object, "sku": String},
	"ca-s":				{"customAcrylic": Object, "sku": String},
  "gb-s":        {"giftBox": Object, "sku": String},
  "gb-r":        {"giftBox": Object, "explicitlyRemoved": Boolean},
  "ca-s":       {"customAcrylic": Object, "sku": String},
  "uao":         {"upsellAddOns": Array},
  "uao_t":       {"upsellAddOns": Array, "type": String},
  "uao_i":       {"upsellAddOns": Array, "identifier": String},
  "uao_p-s":     {"upsellAddOns": Array, "properties": Object, "sku": String},
  "uao_q":       {"upsellAddOns": Array, "quantity": Number},
  "ti":          {"templateIdentifier": String},
  "dcr":         {"designersChoiceRequested": Boolean},
  "j-li-n":      {"lineItemNumber": String},

  "c-p": { "catalog": Object, "shopifyProductId": String },
  "c-v": { "catalog": Object, "shopifyVariantId": String },
  "c-gtl-p": { "catalog": Object, "shopifyGetThisLookProductId": String },
  "c-gtl-v": { "catalog": Object, "shopifyGetThisLookVariantId": String },
};

export const shortAddOnsMap = {
  "ao":       {"addOns": Array},
  "ao_t":     {"addOns": Array, "type": String},
  "ao_i":     {"addOns": Array, "identifier": String},
  "ao_p-s":   {"addOns": Array, "properties": Object, "sku": String},
  "ao_q":     {"addOns": Array, "quantity": Number}
};

export default {
  version,
  INDEX_REGEX,
  INDEX_CHAR,
  shortFrameSpecMap,
  shortAddOnsMap
};
