import {
  minimumMatReveal,
  defaultAccentMatReveal,
  whiteMatName
} from "./hardCodedValues";
import { merge } from "lodash";

export const createEqualReveal = function(value) {
  return {
    topInInches: value,
    rightInInches: value,
    bottomInInches: value,
    leftInInches: value
  };
};

export const createWithoutFloat = function(baseFrameSpec) {
  const newReveal = createEqualReveal(0);
  const newMount = { reveal: newReveal, elevated: false };

  const newFrameSpec = merge(
    {},
    baseFrameSpec,
    {
      mount: newMount,
      artworks: [{
        mount: newMount
      }]
    }
  );

  return newFrameSpec;
};

// Documentation for different floats can be found here:
// https://docs.google.com/document/d/15pqgFIB7OPEVfx2SntCZxzNkSl9P_bwSbq6Hny2lTrw/edit

export const createFloat1 = function(baseFrameSpec) {
  const defaultMountReveal = 1.5;
  const newReveal = createEqualReveal(defaultMountReveal);
  let newMount;
  if (baseFrameSpec.artworks[0]) {
    newMount = Object.assign(
      {},
      baseFrameSpec.mount,
      { reveal: newReveal, elevated: true },
      baseFrameSpec.artworks[0].mount,
      { reveal: newReveal, elevated: true }
    );
  } else {
    newMount = Object.assign(
      {},
      baseFrameSpec.mount,
      { reveal: newReveal, elevated: true }
    );
  }

  if (!baseFrameSpec.mats.length) {
    return Object.assign(
      {},
      baseFrameSpec,
      { mount: newMount }
    );
  } else {
    return Object.assign(
      {},
      baseFrameSpec,
      { mount: newMount, mats: [] }
    );
  }
};

export const createFloat2 = function(baseFrameSpec) {
  const defaultMountReveal = 0.25;
  const defaultMatReveal = 1.25;
  const newMountReveal = createEqualReveal(defaultMountReveal);
  const newMount = Object.assign(
    {},
    baseFrameSpec.mount,
    { reveal: newMountReveal, elevated: true }
  );
  // if there are already mats on this leave them as they are
  if (!baseFrameSpec.mats.length) {
    // otherwise add a white mat
    const newMats = [{
      name: whiteMatName,
      reveal: createEqualReveal(defaultMatReveal)
    }];
    return Object.assign(
      {},
      baseFrameSpec,
      { mount: newMount, mats: newMats }
    );
  } else {
    return Object.assign(
      {},
      baseFrameSpec,
      { mount: newMount }
    );
  }
};

export const createFloat3 = function(baseFrameSpec) {
  const defaultMountReveal = 0.25;
  const defaultMatReveal = 1.25;
  const newMountReveal = createEqualReveal(defaultMountReveal);
  const newMount = Object.assign(
    {},
    baseFrameSpec.mount,
    { reveal: newMountReveal, elevated: false }
  );
  // if there are already mats on this leave them as they are
  if (!baseFrameSpec.mats.length) {
    // otherwise add a white mat
    const newMats = [{
      name: whiteMatName,
      reveal: createEqualReveal(defaultMatReveal)
    }];
    return Object.assign(
      {},
      baseFrameSpec,
      { mount: newMount, mats: newMats }
    );
  } else {
    return Object.assign(
      {},
      baseFrameSpec,
      { mount: newMount }
    );
  }
};

export const createFloat4 = function(baseFrameSpec) {
  const defaultMountReveal = 1.5;
  const newMountReveal = createEqualReveal(defaultMountReveal);
  const newMount = Object.assign(
    {},
    baseFrameSpec.mount,
    { reveal: newMountReveal, elevated: false }
  );
  if (!baseFrameSpec.mats.length) {
    return Object.assign(
      {},
      baseFrameSpec,
      { mount: newMount }
    );
  } else {
    return Object.assign(
      {},
      baseFrameSpec,
      { mount: newMount, mats: [] }
    );
  }
};

export const addBottomMat = function(baseFrameSpec, matSpec) {
  if (!matSpec) {
    const width = !baseFrameSpec.mats.length ? minimumMatReveal : defaultAccentMatReveal;
    const reveal = createEqualReveal(width);
    matSpec = {
      name: whiteMatName,
      reveal
    };
  }

  return Object.assign(
    {},
    baseFrameSpec,
    { mats: baseFrameSpec.mats.concat([matSpec]) }
  );
};

export const removeBottomMat = function(baseFrameSpec) {
  return Object.assign(
    {},
    baseFrameSpec,
    { mats: baseFrameSpec.mats.slice(0, -1) }
  );
};

export default {
  createEqualReveal,
  createWithoutFloat,
  createFloat1,
  createFloat2,
  createFloat3,
  createFloat4,
  addBottomMat,
  removeBottomMat
};
