import { INDEX_REGEX, shortFrameSpecMap, shortAddOnsMap } from "./map";
import set from "lodash/set";
import TRBL from "../TRBL";

/**
 * Removes indices from a deflated shortKey for lookup in map
 * @private
 * @returns {String}  example; ma(1)-ab(2)-n => ma()-ab()-n
 */
const stripIndices = shortKey => {
  return shortKey.replace(INDEX_REGEX, "_");
};

/**
 * @private
 * @description Removes indices from a deflated shortKey
 * for lookup in map
 * @returns {String}  example; ma[1]-ab[2]-n => ma[]-ab[]-n
 */
const lookupRoute = (shortKey, map) => {
  map = map || shortFrameSpecMap;
  return map[stripIndices(shortKey)];
};

const hydratePath = (shortKey, map) => {
  map = map || shortFrameSpecMap;
  const route = lookupRoute(shortKey, map);
  const matches = shortKey.match(INDEX_REGEX);

  const components = [];
  if (!route) {
    return;
  }
  for (const [key, value] of Object.entries(route)) {
    if (value === Array && matches) {
      const matchIndex = components.length;
      const foundMatch = matches[matchIndex];
      const pathIndex = `[${foundMatch.match(/\d+/)[0]}]`;

      components.push(`${key}${pathIndex}`);
    } else {
      components.push(key);
    }
  }

  return components.join(".");
};

export const _hydratePath = hydratePath;

export const urlContainsFrameSpec = function(params) {
  const keysOnly = Object.keys(params).map(stripIndices);
  return Object.keys(shortFrameSpecMap).some(
    value => keysOnly.indexOf(value) >= 0
  );
};

export const castType = function(value, expectedType) {
  if (expectedType === Number) {
    return Number(value);
  } else if (expectedType === TRBL) {
    return new TRBL(...value.split(","));
  } else if (expectedType === Array) {
    return new Array;
  } else if (expectedType === Boolean) {
    return Boolean(parseInt(value));
  } else {
    return value;
  }
};

/**
 * Convert deflated url params to a frameSpec object
 * @function
 * @param {Object} params - key value pairs of url options
 * @returns {Object}
 */
export const expandedParamsFromUrl = function (params, map) {
  const output = {};

  let key;
  for (key in params) {
    const value = params[key];
    const keyPath = hydratePath(key, map);
    const route = lookupRoute(key, map);

    if (route) {
      const types = Object.values(route);
      const type = types[types.length - 1];

      set(output, keyPath, castType(value, type));
    }
  }

  return output;
};

export const populateFromUrlParams = function (params) {
  return expandedParamsFromUrl(params, shortFrameSpecMap);
};

export const addOnsFromUrl = function (params) {
  return expandedParamsFromUrl(params, shortAddOnsMap);
};

export default {
  _hydratePath,
  urlContainsFrameSpec,
  castType,
  expandedParamsFromUrl,
  populateFromUrlParams,
  addOnsFromUrl
};
