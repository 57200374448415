<template>
  <svg
    ref="frameSvg"
    :width="imgWidth"
    :height="imgHeight"
    :viewBox="viewBox"
    class="preview"
    :style="cssProps"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    tabindex="-1"
  >
    <defs>
      <g><pattern ref="roomPatterns" /></g>
      <g><defs ref="framePatterns" /></g>
    </defs>
    <g><rect ref="roomShapes" /></g>
    <transition name="subtle-fade-in">
      <g
        v-show="!hidePreview"
        ref="frameShapes"
      />
    </transition>
  </svg>
</template>

<script>
import { validFrameSpec } from "./validators/frameSpecValidators";
import { forFrame } from "./generateExtendedFrameSpec";
import { download } from "./svgUtilities";
import { svgPathCalculations } from "./generateSvgProperties";
import { defs, group } from "./generateFramePreviewEl";
import spreeClient from "./spree";
import {
  createRoomPattern,
  createRoomRect
} from "./svgRoomPreviewUtilities";
import uuidv4 from "uuid/v4";
import { DiffDOM, nodeToObj } from "diff-dom";

const component = {
  props: {
    triggerDownload: {
      default: false,
      type: Boolean
    },
    frameSpec: {
      validator: validFrameSpec
    },
    imgWidth: {
      type: String,
      default: "100%"
    },
    imgHeight: {
      type: String,
      default: "100%"
    },
    fillContainer: {
      type: Boolean,
      required: false
    },
    paddingTop: {
      type: Number,
      default: 0
    },
    paddingRight: {
      type: Number,
      default: 0
    },
    paddingBottom: {
      type: Number,
      default: 0
    },
    paddingLeft: {
      type: Number,
      default: 0
    },
    completedMafMatName: {
      type: String,
      required: false
    },
    roomPreview: {
      type: Object,
      required: false,
      default: () => {
        return {
          enabled: false
        };
      }
    },
    focus: {
      type: String,
      required: false,
      default: ""
    }
  },
  data () {
    return {
      outerFrameSize: {
        widthInInches: 0,
        heightInInches: 0
      },
      framePatterns: false,
      frameGroup: false,
      roomPattern: false,
      roomRect: false,
      autoPlacementType: "horizontal",
      // todo update focusable from attrs on component
      focusable: false,
      useDataUris: false,
      hidePreview: true,
      viewBox: "0,0,0,0"
    };
  },
  computed: {
    cssProps () {
      return {
        "--max-height": this.fillContainer ? "100%" : "90%"
      };
    },
    isMafIncomplete () {
      return !this.completedMafMatNameFromFrameSpecIfPresent;
    },
    isRoomPreviewEnabled () {
      return this.roomPreview && this.roomPreview.enabled;
    },
    viewBoxWidth () {
      let width = 0;
      if (this.isRoomPreviewEnabled) {
        return this.roomPreview.wallWidthInInches;
      }

      if (
        typeof this.outerFrameSize === "undefined" ||
        typeof this.outerFrameSize.widthInInches === "undefined"
      ) {
        return width;
      }

      width = this.outerFrameSize.widthInInches +
        this.paddingRight +
        this.paddingLeft;

      return width;
    },
    viewBoxHeight () {
      let height = 0;

      if (this.isRoomPreviewEnabled) {
        return this.roomPreview.wallHeightInInches;
      }

      if (
        typeof this.outerFrameSize === "undefined" ||
        typeof this.outerFrameSize.heightInInches === "undefined"
      ) {
        return height;
      }

      height = this.outerFrameSize.heightInInches +
        this.paddingTop +
        this.paddingBottom;

      return height;
    },
    completedMafMatNameFromFrameSpecIfPresent () {
      // This is an accomdation for legacy code. We use the completedMafMatName prop in places where
      // we should be using the frameSpec. This is a temporary fix until we can refactor the code to
      // use the frameSpec everywhere.
      if (this.frameSpec?.getArtwork) {
        return this.frameSpec?.getArtwork().completedMafMatName;
      } else {
        return this.completedMafMatName;
      }
    }
  },
  beforeCreate () {
    this.id = uuidv4();
    this.client = new spreeClient(this.$config?.joineryHost || "/");
  },
  async mounted() {
    this.updateRoomPreview();
    this.updatePreview();
  },
  watch: {
    triggerDownload (newVal){
      if (newVal) {
        this.downloadSvg();
      }
    },
    frameSpec (newVal, oldVal) {
      if (newVal.moulding.permalink !== oldVal.moulding.permalink) {
        this.hidePreview = true;
      }
      if (newVal?.mats[0]?.matCaption !== oldVal?.mats[0]?.matCaption) {
        [...this.$el.querySelectorAll(".mat-caption text")].forEach((line) => {
          line.style.transform = "scale(1)";
        });
      }
      if (newVal?.moulding?.mouldingPlate !== oldVal?.moulding?.mouldingPlate) {
        [...this.$el.querySelectorAll(".moulding-plate text")].forEach((line) => {
          line.style.transform = "scale(1)";
        });
      }
      this.updatePreview();
    }
  },
  methods: {
    downloadSvg () {
      this.useDataUris = true;
      this.updatePreview().then(() => {
      download(this.$refs.frameSvg);
        this.useDataUris = false;
        this.$emit("update:triggerDownload", false);
      });
    },
    updateViewBox () {
      if (this.focus === "mat-caption" && this.$el) {
        const matCaption = this.$el.querySelector(".mat-caption");
        
        if (matCaption) {
          const bbox = matCaption.getBBox();
          const x = bbox.x - bbox.width / 4;
          const y = bbox.y - bbox.height / 2;
          const width = bbox.width * 1.5;
          const height = bbox.height * 1.5;

          this.viewBox = `${x} ${y} ${width} ${height}`;
          return;
        }
      }

      if (this.focus === "moulding-plate" && this.$el) {
        const mouldingPlate = this.$el.querySelector(".moulding-plate");
        
        if (mouldingPlate) {
          const bbox = mouldingPlate.getBBox();
          const x = bbox.x - bbox.width / 2;
          const y = bbox.y - bbox.height / 2;
          const width = bbox.width * 2;
          const height = bbox.height * 2;

          this.viewBox = `${x} ${y} ${width} ${height}`;
          return;
        }
      }

      this.viewBox = `0, 0, ${this.viewBoxWidth}, ${this.viewBoxHeight}`;
    },
    generateRoomPreviewElements () {
      if (this.isRoomPreviewEnabled) {
        const roomPatternId = `frame-preview-${this.id}`;
        this.roomPattern = createRoomPattern(
          this.roomPreview.url,
          roomPatternId
        );
        this.roomRect = createRoomRect(this.roomPreview, roomPatternId);
      } else {
        this.roomPattern = false;
        this.roomRect = false;
      }
    },
    updateRoomPreview () {
      this.generateRoomPreviewElements();

      if (this.roomPattern && this.roomRect) {
        const diffDom = new DiffDOM();
        const roomPatternDiff =
          diffDom.diff(nodeToObj(this.$refs.roomPatterns), nodeToObj(this.roomPattern));
        diffDom.apply(this.$refs.roomPatterns, roomPatternDiff);

        const roomShapeDiff =
          diffDom.diff(nodeToObj(this.$refs.roomShapes), nodeToObj(this.roomRect));
        diffDom.apply(this.$refs.roomShapes, roomShapeDiff);
      }
    },
    generateFramePreview () {
      return forFrame(this.frameSpec, this.autoPlacementType, this.client)
        .then((extendedFrameSpec) => {
          const newOuterFrameSize = extendedFrameSpec.moulding.exterior;

          if (extendedFrameSpec) {
            const frameOffset = {
              x: this.paddingLeft,
              y: this.paddingTop
            };

            if (this.isRoomPreviewEnabled) {
              frameOffset.x =
                this.roomPreview.xMax - newOuterFrameSize.widthInInches;
              frameOffset.y =
                this.roomPreview.yMax - newOuterFrameSize.heightInInches;
            }

            return svgPathCalculations(
              extendedFrameSpec,
              frameOffset.x,
              frameOffset.y,
              this.useDataUris,
              this.client,
              false,
              this.isMafIncomplete
            ).then((frameProperties) => {
              this.outerFrameSize = newOuterFrameSize;
              this.framePatterns = defs(frameProperties, { frame: this.id });
              this.frameGroup = group(
                frameProperties,
                { frame: this.id },
                this.focusable,
                undefined,
                frameOffset.x,
                frameOffset.y
              );
            });
          }
        });
    },
    updatePreview () {
      return this.generateFramePreview().then(() => {
        const diffDom = new DiffDOM();
        const frameShapeDiff =
          diffDom.diff(nodeToObj(this.$refs.frameShapes), nodeToObj(this.frameGroup));
        diffDom.apply(this.$refs.frameShapes, frameShapeDiff);

        const framePatternDiff =
          diffDom.diff(nodeToObj(this.$refs.framePatterns), nodeToObj(this.framePatterns));
        diffDom.apply(this.$refs.framePatterns, framePatternDiff);

        this.updateViewBox();
        this.hidePreview = false;
      });
    }
  }
};

export default component;
</script>

<style lang="scss">


// 1. Presets for Vue's <transition> element

$cubic-bezier: cubic-bezier(0.5, 0.25, 0.01, 1);

$animation-time: 16s;

// Pop
// <transition name="pop"></transition>
// moves content up as it fades in and down as it fades out
.pop-enter-active {
  transform: 0.25s $cubic-bezier;
  transition: opacity 0.25s $cubic-bezier;
}

.pop-leave-active {
  transition: opacity 0.5s $cubic-bezier,  transform 0.5s $cubic-bezier;
}

.pop-enter,
.pop-leave-to {
  opacity: 0;
  transform: translateY(25px);
}

// Slide
// <transition name="slide"></transition>
// moves content in from the left as it fades in and out to the right as it fades out
.slide-enter-active {
  transition: opacity 1s $cubic-bezier 0.25s,  transform 1s $cubic-bezier 0.25s;
}

.slide-leave-active {
  transition: opacity 0.75s $cubic-bezier,  transform 0.75s $cubic-bezier;
}

.slide-enter {
  opacity: 0;
  transform: translateX(75px);
}

.slide-leave-to {
  opacity: 0;
  transform: translateX(-75px);
}

// Framing Flow Dissolve
// <transition name="dissolve"></transition>
// This is a dissolve effect intended for use in the framing flow
.dissolve-enter-active {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 1.3s $cubic-bezier;
}

.dissolve-leave-active {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.3s $cubic-bezier;
}

.dissolve-enter,
.dissolve-leave-to {
  left: 50%;
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

// Fade
// <transition name="fade"></transition>
// a simple, eased opacity change
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s $cubic-bezier 0.25s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

// Subtle Fade In
// <transition name="subtle-fade-in"></transition>
// a simple, eased opacity change, only on enter
.subtle-fade-in-enter-active {
  transition: opacity 0.5s $cubic-bezier 0.25s;
}

.subtle-fade-in-enter {
  opacity: 0;
}

.f10-spin,
.f10-spin svg {
  animation: f10-spin 3s infinite linear; /* Safari 4+ */
  animation: f10-spin 3s infinite linear; /* Fx 5+ */
  animation: f10-spin 3s infinite linear; /* Opera 12+ */
  animation: f10-spin 3s infinite linear; /* IE 10+, Fx 29+ */
}

.f10-marquee-1 {
  animation: marquee1 $animation-time linear infinite;
  animation-delay: calc((#{$animation-time} / 2) * -1);
  display: inline-block;
}

.f10-marquee-2 {
  animation: marquee2 $animation-time linear infinite;
  display: inline-block;
}

.preview {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-height: var(--max-height, 90%);
}

// 2. Global presets

@keyframes f10-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes f10-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes f10-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes f10-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


// To use this marquee you need to have two copies of the content you want to loop
// It can be two paragraphs. The first instance gets ".f10-marquee-1" and the
// second one get ".f10-marquee-2".

@keyframes marquee1 {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(-100%);
  }
}

@keyframes marquee2 {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(-200%);
  }
}

@keyframes tooltipFadeDownFadeUp {
  0% {
    opacity: 0;
    transform: translateX(-50%) translateY(-15px);
  }

  8%,
  92% {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateX(-50%) translateY(0);
    transform: translateX(-50%) translateY(-15px);
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }

  20%,
  80% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
</style>
