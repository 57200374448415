import svgUtilities from "./svgUtilities";
import manipulateFramePreview from "./manipulateFramePreview";
import { minimumArtworkMatOverlap } from "./hardCodedValues";

const appendSpinnerPaths = function(el) {
  let path;

  path = svgUtilities.createElement("path");
  path.setAttribute(
    "d",
    "M32,1c-1.657,0-3,1.343-3,3v10c0,1.657,1.343,3,3,3s3-1.343,3-3V4C35,2.343,33.657,1,32,1z"
  );
  el.appendChild(path);

  path = svgUtilities.createElement("path");
  path.setAttribute("opacity", "0.9");
  path.setAttribute(
    "d",
    "M44.728,22.272c0.768,0,1.536-0.293,2.121-0.879l7.071-7.071c1.172-1.171,1.172-3.071,0-4.242 c-1.171-1.172-3.071-1.172-4.242,0l-7.071,7.071c-1.172,1.171-1.172,3.071,0,4.242C43.192,21.979,43.96,22.272,44.728,22.272z"
  );
  el.appendChild(path);

  path = svgUtilities.createElement("path");
  path.setAttribute("opacity", "0.8");
  path.setAttribute(
    "d",
    "M60,29H50c-1.657,0-3,1.343-3,3c0,1.657,1.343,3,3,3h10c1.657,0,3-1.343,3-3 C63,30.343,61.657,29,60,29z"
  );
  el.appendChild(path);

  path = svgUtilities.createElement("path");
  path.setAttribute("opacity", "0.7");
  path.setAttribute(
    "d",
    "M46.85,42.606c-1.172-1.172-3.072-1.172-4.243,0c-1.172,1.171-1.172,3.07,0,4.242l7.071,7.071 c0.586,0.586,1.354,0.879,2.122,0.879c0.768,0,1.536-0.293,2.121-0.879c1.172-1.171,1.172-3.07,0-4.242L46.85,42.606z"
  );
  el.appendChild(path);

  path = svgUtilities.createElement("path");
  path.setAttribute("opacity", "0.6");
  path.setAttribute(
    "d",
    "M32,47c-1.657,0-3,1.343-3,3v10c0,1.657,1.343,3,3,3s3-1.343,3-3V50 C35,48.343,33.657,47,32,47z"
  );
  el.appendChild(path);

  path = svgUtilities.createElement("path");
  path.setAttribute("opacity", "0.5");
  path.setAttribute(
    "d",
    "M17.15,42.606l-7.071,7.071c-1.171,1.172-1.171,3.071,0,4.242 c0.585,0.586,1.354,0.879,2.121,0.879s1.536-0.293,2.122-0.879l7.071-7.071c1.171-1.172,1.171-3.071,0-4.242 C20.222,41.435,18.322,41.435,17.15,42.606z"
  );
  el.appendChild(path);

  path = svgUtilities.createElement("path");
  path.setAttribute("opacity", "0.4");
  path.setAttribute(
    "d",
    "M17,32c0-1.657-1.343-3-3-3H4c-1.657,0-3,1.343-3,3c0,1.657,1.343,3,3,3h10 C15.657,35,17,33.657,17,32z"
  );
  el.appendChild(path);

  path = svgUtilities.createElement("path");
  path.setAttribute("opacity", "0.3");
  path.setAttribute(
    "d",
    "M17.151,21.393c0.585,0.586,1.354,0.879,2.121,0.879s1.536-0.293,2.121-0.879 c1.172-1.171,1.172-3.071,0-4.242l-7.071-7.071c-1.171-1.172-3.071-1.172-4.242,0c-1.172,1.171-1.172,3.071,0,4.242L17.151,21.393z"
  );
  el.appendChild(path);
  return el;
};

const appendRetryPaths = function (el) {
  let path;

  path = svgUtilities.createElement("path");
  path.setAttribute(
    "d",
    "M39,25a.9.9,0,0,0-1,1,13.9,13.9,0,0,1-1,5.3A14.5,14.5,0,0,1,29.3,39a14.9,14.9,0,0,1-10.6,0A14.5,14.5,0,0,1,11,31.3a14.5,14.5,0,0,1,0-10.6A14.5,14.5,0,0,1,18.7,13a14.9,14.9,0,0,1,10.6,0,14.4,14.4,0,0,1,4.6,3.1l.4.4H26.8a1.1,1.1,0,0,0-1,1,1,1,0,0,0,1,1h9.9a.9.9,0,0,0,1-1V7.6a1,1,0,0,0-1-1,1.1,1.1,0,0,0-1,1v7.5l-.4-.4A15.8,15.8,0,0,0,30,11.2a15.6,15.6,0,0,0-12,0A15.8,15.8,0,0,0,9.2,20a15.6,15.6,0,0,0,0,12A15.8,15.8,0,0,0,18,40.8a15.6,15.6,0,0,0,12,0A15.8,15.8,0,0,0,38.8,32,15.3,15.3,0,0,0,40,26,.9.9,0,0,0,39,25Z"
  );
  el.appendChild(path);

  return el;
};

export const createBackgroundElement = function(artwork, hasMat) {
  const matOverlap = hasMat ? minimumArtworkMatOverlap : 0;

  const spinnerBackgroundEl = svgUtilities.createElement("rect");
  svgUtilities.addClasses(spinnerBackgroundEl, "spinner-background");
  spinnerBackgroundEl.setAttribute("x", artwork.box.x - matOverlap);
  spinnerBackgroundEl.setAttribute("y", artwork.box.y - matOverlap);
  spinnerBackgroundEl.setAttribute("width", artwork.box.width);
  spinnerBackgroundEl.setAttribute("height", artwork.box.height);

  return spinnerBackgroundEl;
}

export const createLoadingIconElement = function(
  artwork,
  frameEl,
  hasMat,
  activeArtworkChange
) {
  const spinnerContainerEl = svgUtilities.createElement("g");
  spinnerContainerEl.style.pointerEvents = "none";
  svgUtilities.addClasses(spinnerContainerEl, "artwork loading-artwork");
  spinnerContainerEl.setAttribute("data-artwork-key", artwork.box.artworkKey);
  if (typeof activeArtworkChange === "function") {
    spinnerContainerEl.setAttribute(
      "aria-label",
      `Loading artwork in opening ${artwork.box.artworkKey +
        1} in frame ${frameEl.getAttribute("data-frame-key") + 1}`
    );
    manipulateFramePreview.createEventsForArtwork(
      spinnerContainerEl,
      frameEl,
      activeArtworkChange
    );
  }

  const animateContainerEl = svgUtilities.createElement("g");
  svgUtilities.addClasses(animateContainerEl, "loading-artwork");

  const spinnerEl = svgUtilities.createElement("g");
  svgUtilities.addClasses(spinnerEl, "spinner");
  appendSpinnerPaths(spinnerEl);

  const retryEl = svgUtilities.createElement("g");
  svgUtilities.addClasses(retryEl, "retry");
  appendRetryPaths(retryEl);

  const spinnerBackgroundEl = createBackgroundElement(artwork, hasMat);

  const finalWidthOfSpinner = 1;
  const scale = finalWidthOfSpinner / 64;
  const center = svgUtilities.calculateCenterRect(spinnerBackgroundEl);
  const xAxisOffset = center.x - finalWidthOfSpinner / 2;
  const yAxisOffset = center.y - finalWidthOfSpinner / 2;

  // scale it down
  const scaleString = `scale(${scale}, ${scale})`;
  // move it to the center of the mat opening
  const translateString = `translate(${xAxisOffset}, ${yAxisOffset})`;
  spinnerEl.setAttribute("transform", `${translateString} ${scaleString}`);

  const finalWidthOfRetry = 2;
  const retryScale = finalWidthOfRetry / 64;
  const retryXAxisOffset = center.x - finalWidthOfRetry / 2;
  const retryYAxisOffset = center.y - finalWidthOfRetry / 2;

  const retryScaleString = `scale(${retryScale}, ${retryScale})`;
  const retryTranslateString = `translate(${retryXAxisOffset}, ${retryYAxisOffset})`;
  retryEl.setAttribute("transform", `${retryTranslateString} ${retryScaleString}`);
  retryEl.style.stroke = "black";
  retryEl.style.strokeWidth = "0.5px";
  retryEl.style.display = "none";

  // spin
  animateContainerEl.appendChild(spinnerEl);
  const spinTransformEl = svgUtilities.createSpinAnimateTransform(
    center.x,
    center.y,
    "2s"
  );
  animateContainerEl.appendChild(spinTransformEl);

  spinnerContainerEl.appendChild(spinnerBackgroundEl);
  spinnerContainerEl.appendChild(animateContainerEl);
  spinnerContainerEl.appendChild(retryEl);
  return spinnerContainerEl;
};

export default {
  createBackgroundElement,
  createLoadingIconElement
};
