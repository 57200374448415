const generateFramePreviewEl = {};

import svgUtilities from "./svgUtilities";
import hardCodedValues from "./hardCodedValues";
import manipulateFramePreview from "./manipulateFramePreview";
import generateFramePreviewLoadingEl from "./generateFramePreviewLoadingEl";

export const generateFrameId = function (ids) {
  return ids.gallery
    ? `gallery-${ids.gallery}-frame-${ids.frame}`
    : `frame-${ids.frame}`;
};

export const generateArtworkId = function (artworkKey) {
  return `artwork-${artworkKey}`;
};

export const generatePatternId = function (artwork, frameId, artworkId) {
  return `${artwork.pattern.id}-${frameId}`;
};

const createMountElement = function (mount, frameId) {
  const mountEl = svgUtilities.createElement("g");
  svgUtilities.addClasses(mountEl, "mount");
  mountEl.setAttribute("fill-rule", "evenodd");
  const mountPathEl = svgUtilities.createElement("path");
  svgUtilities.addClasses(mountPathEl, "mount-surface");

  if (mount.fill) {
    mountPathEl.setAttribute("fill", mount.fill);
  } else if (mount.name === "ACRY01") {
    mountPathEl.setAttribute("fill-opacity", "0.00");
  } else if (mount.pattern && mount.pattern.id) {
    mountPathEl.setAttribute("fill", `url(#${mount.pattern.id}-${frameId})`);
  }

  mountPathEl.setAttribute("d", mount.dataPoints);
  mountEl.appendChild(mountPathEl);
  return mountEl;
};

const createAdornmentsElement = function (
  adornment,
  offsetX,
  offsetY,
  frameId
) {
  const adornmentEl = svgUtilities.createElement("g");
  svgUtilities.addClasses(adornmentEl, "adornments");
  const adornments = svgUtilities.createElement("rect");
  adornments.setAttribute("width", adornment.supplementalData.adornmentWidthInInches);
  adornments.setAttribute("height", adornment.supplementalData.adornmentHeightInInches);
  adornments.setAttribute("x", offsetX);
  adornments.setAttribute("y", offsetY);
  adornments.setAttribute("fill", `url(#adornment-${frameId})`);
  adornmentEl.appendChild(adornments);
  return adornmentEl;
};

const createPathBox = function(dimX1, dimX2, dimY1, dimY2) {
  return `path('M${dimX1},${dimY1} L${dimX1},${dimY2} L${dimX2},${dimY2} L${dimX2},${dimY1} z')`;
};

const createArtworkElement = function (
  artwork,
  elevated,
  frameEl,
  activeArtworkChange,
  mountName,
  patternId
) {
  let artOffset = 0;
  if(artwork.spec.exterior.artworkOpeningWidthInInches < artwork.spec.exterior.widthInInches 
      && artwork.spec.exterior.artworkOpeningHeightInInches 
                            < artwork.spec.exterior.heightInInches) {
    artOffset = hardCodedValues.uploadedArtworkMatOverlap / 2;
  }
  const artworkEl = svgUtilities.createElement("rect");
  svgUtilities.addClasses(artworkEl, "artwork cropped");
  artworkEl.setAttribute("data-artwork-key", artwork.box.artworkKey);
  artworkEl.setAttribute("x", artwork.box.x - artOffset);
  artworkEl.setAttribute("y", artwork.box.y - artOffset);
  artworkEl.setAttribute("width", artwork.box.width);
  artworkEl.setAttribute("height", artwork.box.height);
  artworkEl.setAttribute("fill", `url(#${patternId})`);
  if((artwork.spec.exterior.artworkOpeningHeightInInches != artwork.spec.exterior.heightInInches 
      || artwork.spec.exterior.artworkOpeningWidthInInches != artwork.spec.exterior.widthInInches) 
      && !elevated) {
    let clipWidth = artwork.spec.exterior.artworkOpeningWidthInInches + 2 * artOffset;
    let clipHeight = artwork.spec.exterior.artworkOpeningHeightInInches + 2 * artOffset;
    if(typeof navigator !== "undefined" && navigator.userAgent.match(/firefox|fxios/i)) {
      clipWidth += artwork.box.x;
      clipHeight += artwork.box.y;
    }
    artworkEl.setAttribute("clip-path", createPathBox(0, clipWidth, 0, clipHeight));
  }
  if (elevated) {
    const filterId = `float-mount-shadow-${frameEl.id}`;
    artworkEl.setAttribute("filter", `url(#${filterId})`);
  }
  if (mountName === "ACRY01") {
    const filterId = `acrylic-mount-shadow-${frameEl.id}`;
    artworkEl.setAttribute("filter", `url(#${filterId})`);
  }
  if (typeof activeArtworkChange === "function") {
    artworkEl.setAttribute(
      "aria-label",
      `Artwork in opening ${artwork.box.artworkKey +
      1} in frame ${frameEl.getAttribute("data-frame-key") + 1}`
    );
    manipulateFramePreview.createEventsForArtwork(
      artworkEl,
      frameEl,
      activeArtworkChange
    );
  }
  return artworkEl;
};

const createAndAppendCloseIcon = function (artwork, frameEl, framePreviewProps) {
  if (artwork.spec.allowUploadRemoval) {
    const artworkBoxHeight = artwork.box.height;
    const artworkBoxOffsetY = artwork.box.y;
    const artworkBoxWidth = artwork.box.width;
    const artworkBoxOffsetX = artwork.box.x;

    let circleRadiusInPixels = 24;
    if (typeof window !== 'undefined') {
      if (window.innerWidth <= 832) {
        // Mobile
        circleRadiusInPixels = 42;
      }
    }

    const dpi = artwork.spec.width / artwork.spec.exterior.widthInInches;
    const circleRadius = circleRadiusInPixels / dpi;
    const stroke = 0.04;

    const circleY = artworkBoxOffsetY - stroke;
    let circleX = artworkBoxOffsetX + artworkBoxWidth;

    if (!framePreviewProps.mount.elevated) {
      circleX = circleX - (circleRadius / 2);
    }

    const circleEl = svgUtilities.createElement("circle");
    circleEl.setAttribute('cx', circleX);
    circleEl.setAttribute('cy', circleY);
    circleEl.setAttribute('r', circleRadius);
    circleEl.setAttribute('fill', '#eee171');
    circleEl.setAttribute('stroke', '#000');
    circleEl.setAttribute('stroke-width', stroke);
    svgUtilities.addClasses(circleEl, "circle-upload-remove-icon");

    const line1 = svgUtilities.createElement("line");
    const line2 = svgUtilities.createElement("line");

    line1.setAttribute('x1', circleX - circleRadius / 2);
    line1.setAttribute('y1', circleY - circleRadius / 2);
    line1.setAttribute('x2', circleX + circleRadius / 2);
    line1.setAttribute('y2', circleY + circleRadius / 2);
    line1.setAttribute('stroke', '#000');
    line1.setAttribute('stroke-width', stroke);
    svgUtilities.addClasses(line1, "circle-upload-remove-cross")

    line2.setAttribute('x1', circleX + circleRadius / 2);
    line2.setAttribute('y1', circleY - circleRadius / 2);
    line2.setAttribute('x2', circleX - circleRadius / 2);
    line2.setAttribute('y2', circleY + circleRadius / 2);
    line2.setAttribute('stroke', '#000');
    line2.setAttribute('stroke-width', stroke);
    svgUtilities.addClasses(line2, "circle-upload-remove-cross")

    frameEl.appendChild(circleEl);
    frameEl.appendChild(line1);
    frameEl.appendChild(line2);
  }
}

const createAndAppendIcon = function (artwork, frameEl, hasMat, activeArtworkChange, artworksEl) {
  const addIconEl = createAddArtworkIconElement(artwork, frameEl, hasMat, activeArtworkChange);
  artworksEl.appendChild(addIconEl);
}

const createAddArtworkIconElement = function (
  artwork,
  frameEl,
  hasMat,
  activeArtworkChange
) {
  const noArtworkEl = svgUtilities.createElement("g");
  svgUtilities.addClasses(noArtworkEl, "artwork no-artwork");
  noArtworkEl.setAttribute("data-artwork-key", artwork.box.artworkKey);

  if (typeof activeArtworkChange === "function") {
    noArtworkEl.setAttribute(
      "aria-label",
      `No artwork in opening ${artwork.box.artworkKey +
      1} in frame ${frameEl.getAttribute("data-frame-key") + 1}`
    );
    manipulateFramePreview.createEventsForArtwork(
      noArtworkEl,
      frameEl,
      activeArtworkChange
    );
  }

  const matOverlap = hasMat ? hardCodedValues.minimumArtworkMatOverlap : 0;
  const noArtworkMatEl = svgUtilities.createElement("rect");
  svgUtilities.addClasses(noArtworkMatEl, "no-artwork-mat");
  noArtworkMatEl.setAttribute("x", artwork.box.x - matOverlap);
  noArtworkMatEl.setAttribute("y", artwork.box.y - matOverlap);
  noArtworkMatEl.setAttribute("width", artwork.box.width);
  noArtworkMatEl.setAttribute("height", artwork.box.height);
  const iconEl = svgUtilities.createElement("g");
  svgUtilities.addClasses(iconEl, "no-art");

  const polygonEl = svgUtilities.createElement("polygon");
  svgUtilities.addClasses(polygonEl, "add-art-icon");

  const iconSvgWidth = 10.2325582;
  const artworkShorterSide = artwork.box.width > artwork.box.height ? artwork.box.height : artwork.box.width;
  const relativeIconSize = artworkShorterSide / 5;
  polygonEl.setAttribute("points", svgUtilities.addArtworkIconPoints);
  const addArtworkTransform = (iconSize) => {
    return {
      x: artwork.box.x + artwork.box.width / 2 - iconSize / 2 - matOverlap,
      y: artwork.box.y + artwork.box.height / 2 - iconSize / 2 - matOverlap,
      scale: iconSize / iconSvgWidth
    };
  };
  const transform = addArtworkTransform(relativeIconSize);
  const pulseTransform = addArtworkTransform(relativeIconSize * 1.5);

  polygonEl.setAttribute(
    "transform",
    `translate(${transform.x}, ${transform.y}) scale(${transform.scale})`
  );
  polygonEl.setAttribute(
    "data-transform",
    `translate(${transform.x}, ${transform.y}) scale(${transform.scale})`
  );
  polygonEl.setAttribute(
    "data-pulse-transform",
    `translate(${pulseTransform.x}, ${pulseTransform.y}) scale(${pulseTransform.scale})`
  );
  noArtworkEl.appendChild(noArtworkMatEl);
  noArtworkEl.appendChild(polygonEl);

  if (artwork.spec && artwork.spec.allowUpload) {
    polygonEl.setAttribute('display', 'none');
    const artworkBoxHeight = artwork.box.height;
    const artworkBoxOffsetY = artwork.box.y;
    const artworkBoxWidth = artwork.box.width;
    const artworkBoxOffsetX = artwork.box.x;

    const circleRadiusInPixels = 32;
    const dpi = artwork.spec.width / artwork.spec.exterior.widthInInches;
    const circleRadius = circleRadiusInPixels / dpi;
    const stroke = 0.04;

    const circleY = (artworkBoxHeight  / 2) + artworkBoxOffsetY - stroke;
    const circleX = (artworkBoxWidth / 2) + artworkBoxOffsetX - stroke
    
    const circleEl = svgUtilities.createElement("circle");
    circleEl.setAttribute('cx', circleX);
    circleEl.setAttribute('cy', circleY);
    circleEl.setAttribute('r', circleRadius);
    circleEl.setAttribute('fill', '#eee171');
    circleEl.setAttribute('stroke', '#000');
    circleEl.setAttribute('stroke-width', stroke);
    svgUtilities.addClasses(circleEl, "circle-upload-icon");

    const line1 = svgUtilities.createElement("line");
    const line2 = svgUtilities.createElement("line");
 
    line1.setAttribute('x1', circleX);
    line1.setAttribute('y1', circleY - circleRadius / 2);
    line1.setAttribute('x2', circleX);
    line1.setAttribute('y2', circleY + circleRadius / 2);
    line1.setAttribute('stroke', '#000');
    line1.setAttribute('stroke-width', stroke);
    svgUtilities.addClasses(line1, "circle-upload-cross")
    
    line2.setAttribute('x1', circleX - circleRadius / 2);
    line2.setAttribute('y1', circleY);
    line2.setAttribute('x2', circleX + circleRadius / 2);
    line2.setAttribute('y2', circleY);
    line2.setAttribute('stroke', '#000');
    line2.setAttribute('stroke-width', stroke);
    svgUtilities.addClasses(line2, "circle-upload-cross")

    noArtworkEl.appendChild(circleEl);
    noArtworkEl.appendChild(line1);
    noArtworkEl.appendChild(line2);
  }

  return noArtworkEl;
};

const createMonogramElement = function (monogram, frameId) {
  const fillId = `monogram-${monogram.foilColorName}-foil-gradient-${frameId}`;
  const fontSize = 0.4;
  const letterSpacing = 0.03;
  const monogramEl = svgUtilities.createElement("g");
  svgUtilities.addClasses(monogramEl, "monogram");

  const line1El = svgUtilities.createElement("text");
  svgUtilities.addClasses(line1El, "line1");
  line1El.setAttribute("font-size", fontSize);
  line1El.setAttribute("letter-spacing", letterSpacing);
  line1El.setAttribute("text-anchor", "middle");
  line1El.setAttribute("x", monogram.horizontalCenter);
  line1El.setAttribute("y", monogram.offsetTop);
  line1El.setAttribute("font-family", monogram.fontFamily);
  line1El.setAttribute("fill", `url(#${fillId})`);
  line1El.textContent = monogram.line1;
  monogramEl.appendChild(line1El);

  const line2El = svgUtilities.createElement("text");
  svgUtilities.addClasses(line2El, "line2");
  line2El.setAttribute("font-size", fontSize);
  line2El.setAttribute("letter-spacing", letterSpacing);
  line2El.setAttribute("text-anchor", "middle");
  line2El.setAttribute("x", monogram.horizontalCenter);
  line2El.setAttribute("y", monogram.offsetTop + fontSize);
  line2El.setAttribute("font-family", monogram.fontFamily);
  line2El.setAttribute("fill", `url(#${fillId})`);
  line2El.textContent = monogram.line2;
  monogramEl.appendChild(line2El);

  return monogramEl;
};

const svgValuesforLine = function (matCaption, lineNumber) {
  const stylesDefinedPerLine = Boolean(matCaption.lines && Object.values(matCaption.lines).some((line) => line.fontStyle));
  
  if (!stylesDefinedPerLine) {
    return {
      svgFontSize: matCaption.fontSize,
      letterSpacing: matCaption.letterSpacing,
      fontFamily: matCaption.fontFamily,
      lineSpace: matCaption.lineSpace,
      message: matCaption[`line${lineNumber}`],
      layoutName: matCaption.layoutName
    };
  }

  const matCaptionLine = matCaption.lines ? matCaption.lines[`line${lineNumber}`] : null;
  const matCaptionLineIsComplete = matCaptionLine && matCaptionLine.fontStyle && matCaptionLine.fontSize;

  if (!matCaptionLine || !matCaptionLineIsComplete) {
    return {
      svgFontSize: matCaption.fontSize,
      letterSpacing: matCaption.letterSpacing,
      fontFamily: matCaption.fontFamily,
      lineSpace: matCaption.lineSpace,
      message: "",
      layoutName: matCaption.layoutName
    };
  }

  const hardCodedCaptionFont = hardCodedValues.matCaption.fonts[matCaptionLine.fontStyle]
  const isLarge = matCaptionLine.fontSize === "large";
  return {
    svgFontSize: isLarge ? hardCodedCaptionFont.largeFontSize : hardCodedCaptionFont.fontSize,
    letterSpacing: isLarge ? hardCodedCaptionFont.largeLetterSpacing : hardCodedCaptionFont.letterSpacing,
    fontFamily: matCaptionLine.fontStyle,
    lineSpace: isLarge ? hardCodedCaptionFont.largeLineSpacing : hardCodedCaptionFont.lineSpacing,
    message: matCaptionLine.message,
    layoutName: matCaption.layoutName
  }
};

const createMatCaptionElement = function (matCaption) {
  const matCaptionEl = svgUtilities.createElement("g");
  svgUtilities.addClasses(matCaptionEl, "mat-caption");

  const boundingBoxEl = svgUtilities.createElement("rect");
  boundingBoxEl.setAttribute("width", matCaption.width);
  boundingBoxEl.setAttribute("height", matCaption.height);
  boundingBoxEl.setAttribute("y", matCaption.verticalTop - matCaption.fontSize);
  boundingBoxEl.setAttribute("x", matCaption.boxAlign);
  boundingBoxEl.setAttribute("fill", "transparent");
  matCaptionEl.appendChild(boundingBoxEl);

  const line1El = svgUtilities.createElement("text");
  svgUtilities.addClasses(line1El, "line1");
  line1El.setAttribute("font-size", svgValuesforLine(matCaption, 1).svgFontSize);
  line1El.setAttribute("letter-spacing", svgValuesforLine(matCaption, 1).letterSpacing);
  line1El.setAttribute("text-anchor", matCaption.textAlign);
  line1El.setAttribute("x", matCaption.horizontalAlign);
  line1El.setAttribute("y", matCaption.verticalTop + svgValuesforLine(matCaption, 1).lineSpace);
  line1El.setAttribute("font-family", svgValuesforLine(matCaption, 1).fontFamily);
  line1El.setAttribute("fill", matCaption.fontColor);
  line1El.setAttribute("stroke", matCaption.stroke);
  line1El.setAttribute("stroke-width", matCaption.strokeWidth);
  line1El.setAttribute("font-weight", matCaption.fontWeight);
  line1El.textContent = svgValuesforLine(matCaption, 1).message;
  matCaptionEl.appendChild(line1El);

  const line2El = svgUtilities.createElement("text");
  svgUtilities.addClasses(line2El, "line2");
  line2El.setAttribute("font-size", svgValuesforLine(matCaption, 2).svgFontSize);
  line2El.setAttribute("letter-spacing", svgValuesforLine(matCaption, 2).letterSpacing);
  line2El.setAttribute("text-anchor", matCaption.textAlign);
  line2El.setAttribute("x", matCaption.horizontalAlign);
  let yPos;
  if (svgValuesforLine(matCaption, 1).layoutName === "title-with-subtext") {
    yPos = matCaption.verticalTop + matCaption.fontSize + (svgValuesforLine(matCaption, 1).svgFontSize / 2) + svgValuesforLine(matCaption, 2).lineSpace;
    line2El.setAttribute("y", yPos);
  } else {
    yPos = matCaption.verticalTop + matCaption.fontSize + svgValuesforLine(matCaption, 2).lineSpace * 2
    line2El.setAttribute("y", yPos);
  }  
  line2El.setAttribute("font-family", svgValuesforLine(matCaption, 2).fontFamily);
  line2El.setAttribute("fill", matCaption.fontColor);
  line2El.setAttribute("stroke", matCaption.stroke);
  line2El.setAttribute("stroke-width", matCaption.strokeWidth);
  line2El.setAttribute("font-weight", matCaption.fontWeight);
  line2El.textContent = svgValuesforLine(matCaption, 2).message;
  matCaptionEl.appendChild(line2El);

  const line3El = svgUtilities.createElement("text");
  svgUtilities.addClasses(line3El, "line3");
  line3El.setAttribute("font-size", svgValuesforLine(matCaption, 3).svgFontSize);
  line3El.setAttribute("letter-spacing", svgValuesforLine(matCaption, 3).letterSpacing);
  line3El.setAttribute("text-anchor", matCaption.textAlign);
  line3El.setAttribute("x", matCaption.horizontalAlign);
  line3El.setAttribute("y", matCaption.verticalTop + matCaption.fontSize * 2 + svgValuesforLine(matCaption, 3).lineSpace * 3);
  line3El.setAttribute("font-family", svgValuesforLine(matCaption, 3).fontFamily);
  line3El.setAttribute("fill", matCaption.fontColor);
  line3El.setAttribute("stroke", matCaption.stroke);
  line3El.setAttribute("stroke-width", matCaption.strokeWidth);
  line3El.setAttribute("font-weight", matCaption.fontWeight);
  line3El.textContent = svgValuesforLine(matCaption, 3).message;
  matCaptionEl.appendChild(line3El);

  const line4El = svgUtilities.createElement("text");
  svgUtilities.addClasses(line4El, "line4");
  line4El.setAttribute("font-size", svgValuesforLine(matCaption, 4).svgFontSize);
  line4El.setAttribute("letter-spacing", svgValuesforLine(matCaption, 4).letterSpacing);
  line4El.setAttribute("text-anchor", matCaption.textAlign);
  line4El.setAttribute("x", matCaption.horizontalAlign);
  line4El.setAttribute("y", matCaption.verticalTop + matCaption.fontSize * 3 + svgValuesforLine(matCaption, 4).lineSpace * 4);
  line4El.setAttribute("font-family", svgValuesforLine(matCaption, 4).fontFamily);
  line4El.setAttribute("fill", matCaption.fontColor);
  line4El.setAttribute("stroke", matCaption.stroke);
  line4El.setAttribute("stroke-width", matCaption.strokeWidth);
  line4El.setAttribute("font-weight", matCaption.fontWeight);
  line4El.textContent = svgValuesforLine(matCaption, 4).message;
  matCaptionEl.appendChild(line4El);

  return matCaptionEl;
};

const createMatCoreElement = function (core, mat, frameId) {
  const coreEl = svgUtilities.createElement("path");
  coreEl.setAttribute("data-mat-key", core.matKey);
  coreEl.setAttribute("data-artwork-key", core.artworkKey);
  svgUtilities.addClasses(coreEl, "mat-core");
  svgUtilities.addClasses(coreEl, mat.coreShade);
  const patternId = `mat-core-gradient-${mat.id}-${frameId}`;

  if (mat.coreShade == "white" && mat.blackInnerCore) {
    // white core with a black base inner core
    // ensure black inner core doesn't leak to outer white core
    coreEl.setAttribute("fill", "#e3e3e3");
  } else if (mat.coreShade == "black" && (mat.blackInnerCore || !mat.singleMat)) {
    // black core with black base core, or black core with an accent
    // ensure black cores
    coreEl.setAttribute("fill", "#000000");
  } else {
    // only one mat, use standard fill
    coreEl.setAttribute("fill", `url(#${patternId})`);
  }
  coreEl.setAttribute("d", core.dataPoints);
  return coreEl;
};

const createBase64Mat = function(mat, frameId) {
  const matData = hardCodedValues.primaryMatColors.find((m) => m.name === mat.base64MatName);
  const group = svgUtilities.createElement("g");
  const matEl = svgUtilities.createElement("image");
  matEl.setAttribute("href", matData.matAsset);
  matEl.setAttribute("height", matData.matHeight);
  matEl.setAttribute("width", matData.matWidth);
  matEl.setAttribute("x", mat.offsetRight);
  matEl.setAttribute("y", mat.offsetDown);
  
  group.appendChild(matEl);
  
  if (mat.monogram) {
    group.appendChild(createMonogramElement(mat.monogram, frameId));
  }
  if (mat.matCaption) {
    group.appendChild(createMatCaptionElement(mat.matCaption));
  }
  
  return group;
};

const createMatElement = function (mat, frameId) {
  const matEl = svgUtilities.createElement("g");
  svgUtilities.addClasses(matEl, "mat");
  matEl.setAttribute("data-mat-key", mat.matKey);
  matEl.setAttribute("fill-rule", "evenodd");
  const matPathEl = svgUtilities.createElement("path");
  svgUtilities.addClasses(matPathEl, "mat-surface");
  if (mat.fill) {
    matPathEl.setAttribute("fill", mat.fill);
  } else if (mat.pattern && mat.pattern.id) {
    matPathEl.setAttribute("fill", `url(#${mat.pattern.id}-${frameId})`);
  }
  matPathEl.setAttribute("d", mat.dataPoints);
  matEl.appendChild(matPathEl);
  mat.cores.forEach(function (core) {
    matEl.appendChild(createMatCoreElement(core, mat, frameId));
  });


  // if frame requires a base64 image as its mat, figure out which one and return it.
  if (mat.base64MatName) {
    return createBase64Mat(mat, frameId);
  }

  if (mat.monogram) {
    matEl.appendChild(createMonogramElement(mat.monogram, frameId));
  }
  if (mat.matCaption) {
    matEl.appendChild(createMatCaptionElement(mat.matCaption));
  }

  return matEl;
};

const createStrutElement = function (strut, frameId) {
  const strutEl = svgUtilities.createElement("path");
  svgUtilities.addClasses(strutEl, "strut");
  strutEl.setAttribute("d", strut.dataPoints);
  strutEl.setAttribute("fill", `url(#${strut.fillId}-${frameId})`);
  let transform = `rotate(${strut.transform.rotate.degrees}, ${
    strut.transform.rotate.x
    }, ${strut.transform.rotate.y})`;
  transform = `${transform} translate(${strut.transform.translate.x}, ${
    strut.transform.translate.y
    })`;
  strutEl.setAttribute("transform", transform);
  return strutEl;
};

const createMouldingPlateElement = function (moulding, offsetX, offsetY, frameId) {
  const mouldingPlateEl = svgUtilities.createElement("g");
  svgUtilities.addClasses(mouldingPlateEl, "moulding-plate");
  const fillId = `plate-${frameId}`;
  const mouldingPlate = moulding.mouldingPlate;
  const halfMouldingWidth = moulding.patterns[0].mouldingWidth / 2;
  const horizontalCenter = mouldingPlate.frameWidth / 2 + offsetX;
  const plateWidth = hardCodedValues.mouldingPlate.brassPlate.width;
  const plateHeight = hardCodedValues.mouldingPlate.brassPlate.height;
  const lineSpacing = hardCodedValues.mouldingPlate.lineSpacing;
  const verticalCenter = mouldingPlate.frameHeight + offsetY - halfMouldingWidth;
  let line1VerticalCenter = verticalCenter;
  let line2VerticalCenter = verticalCenter;
  if (mouldingPlate.line1 && mouldingPlate.line2) {
    line1VerticalCenter = verticalCenter - (mouldingPlate.fontSize / 2 + lineSpacing / 2);
    line2VerticalCenter = verticalCenter + (mouldingPlate.fontSize / 2 + lineSpacing / 2);
  }


  const plateEl = svgUtilities.createElement("rect");
  plateEl.setAttribute("width", plateWidth);
  plateEl.setAttribute("height", plateHeight);
  plateEl.setAttribute("x", horizontalCenter - plateWidth / 2);
  plateEl.setAttribute("y", verticalCenter - plateHeight / 2);
  plateEl.setAttribute("fill", `url(#${fillId})`);

  const line1El = svgUtilities.createElement("text");
  svgUtilities.addClasses(line1El, "line1");
  line1El.textContent = mouldingPlate.line1;
  line1El.setAttribute("font-size", mouldingPlate.fontSize);
  line1El.setAttribute("letter-spacing", mouldingPlate.letterSpacing);
  line1El.setAttribute("font-family", mouldingPlate.fontFamily);
  line1El.setAttribute("fill", mouldingPlate.fontColor);
  line1El.setAttribute("text-anchor", "middle");
  line1El.setAttribute("x", horizontalCenter);
  line1El.setAttribute("y", line1VerticalCenter);
  line1El.setAttribute("dominant-baseline", "middle");

  const line2El = svgUtilities.createElement("text");
  svgUtilities.addClasses(line2El, "line1");
  line2El.textContent = mouldingPlate.line2;
  line2El.setAttribute("font-size", mouldingPlate.fontSize);
  line2El.setAttribute("letter-spacing", mouldingPlate.letterSpacing);
  line2El.setAttribute("font-family", mouldingPlate.fontFamily);
  line2El.setAttribute("fill", mouldingPlate.fontColor);
  line2El.setAttribute("text-anchor", "middle");
  line2El.setAttribute("x", horizontalCenter);
  line2El.setAttribute("y", line2VerticalCenter);
  line2El.setAttribute("dominant-baseline", "middle");

  mouldingPlateEl.appendChild(plateEl);
  mouldingPlateEl.appendChild(line1El);
  mouldingPlateEl.appendChild(line2El);

  return mouldingPlateEl;
};


const createMouldingElement = function (moulding, frameId) {
  const outter = svgUtilities.createElement("g");
  const mouldingEl1 = svgUtilities.createElement("g");
  svgUtilities.addClasses(mouldingEl1, "moulding");

  const patternId = `opening-frame-shadow-${frameId}`;
  mouldingEl1.setAttribute("filter", `url(#${patternId})`);

  mouldingEl1.appendChild(createStrutElement(moulding.struts.top, frameId));
  mouldingEl1.appendChild(createStrutElement(moulding.struts.left, frameId));

  const mouldingEl2 = svgUtilities.createElement("g");
  svgUtilities.addClasses(mouldingEl2, "moulding");
  mouldingEl2.appendChild(createStrutElement(moulding.struts.right, frameId));
  mouldingEl2.appendChild(createStrutElement(moulding.struts.bottom, frameId));

  outter.appendChild(mouldingEl1);
  outter.appendChild(mouldingEl2);
  return outter;
};

const createArtworkPatternElement = function(artwork, frameId, artworkId) {
  const patternId = generatePatternId(artwork, frameId, artworkId);

  const patternEl = svgUtilities.createCoverImagePattern(artwork);

  patternEl.setAttribute("id", patternId);
  return patternEl;
};

const createAdornmentPatternElement = function (adornment, frameId) {

  const patternId = `adornment-${frameId}`;
  const patternEl = svgUtilities.createAdornmentImagePattern(adornment);

  patternEl.setAttribute("id", patternId);
  return patternEl;
};

const createMatTexturePattern = function (matPattern, frameId, frameOffsetX, frameOffsetY) {
  if (frameOffsetX) {
    const initialMatOffsetX = matPattern.offsets.x || 0;
    const adjustedMatOffsetX = initialMatOffsetX + frameOffsetX;
    matPattern.offsets.x = adjustedMatOffsetX;
  }

  if (frameOffsetY) {
    const initialMatOffsetY = matPattern.offsets.y || 0;
    const adjustedMatOffsetY = initialMatOffsetY + frameOffsetY;
    matPattern.offsets.y = adjustedMatOffsetY;
  }

  const matPatternEl = svgUtilities.createTilingImagePattern(
    matPattern.uri || matPattern.url,
    { width: matPattern.widthInInches, height: matPattern.heightInInches },
    matPattern.offsets
  );
  matPatternEl.setAttribute("id", `${matPattern.id}-${frameId}`);
  return matPatternEl;
};

const createMatCoreGradient = function (mat, frameId) {
  const gradientEl = svgUtilities.createElement("linearGradient");
  gradientEl.setAttribute("id", `mat-core-gradient-${mat.id}-${frameId}`);
  gradientEl.setAttribute("x1", "0%");
  gradientEl.setAttribute("y1", "0%");
  gradientEl.setAttribute("x2", "100%");
  gradientEl.setAttribute("y2", "100%");
  gradientEl.setAttribute("gradientTransform", "rotate(45)");

  const stop1El = svgUtilities.createElement("stop");
  stop1El.setAttribute("offset", "0%");
  stop1El.setAttribute("stop-opacity", "1");
  stop1El.setAttribute("stop-color", mat.coreColors[0]);
  gradientEl.appendChild(stop1El);

  const stop2El = svgUtilities.createElement("stop");
  stop2El.setAttribute("offset", "2%");
  stop2El.setAttribute("stop-opacity", "1");
  stop2El.setAttribute("stop-color", mat.coreColors[1]);
  gradientEl.appendChild(stop2El);

  const stop3El = svgUtilities.createElement("stop");
  stop3El.setAttribute("offset", "100%");
  stop3El.setAttribute("stop-opacity", "1");
  stop3El.setAttribute("stop-color", mat.coreColors[2]);
  gradientEl.appendChild(stop2El);

  return gradientEl;
};

const createStrutPattern = function (strutPattern, frameId) {
  const strutPatternEl = svgUtilities.createTilingImagePattern(
    strutPattern.uri || strutPattern.url,
    { width: strutPattern.length, height: strutPattern.mouldingWidth },
    { x: strutPattern.offset }
  );
  strutPatternEl.setAttribute("id", `${strutPattern.id}-${frameId}`);
  return strutPatternEl;
};

const createFrameInnerShadowFilterElement = function (frameId) {
  const filterEl = svgUtilities.createElement("filter");
  filterEl.setAttribute("id", `opening-frame-shadow-${frameId}`);
  filterEl.setAttribute("x", "-20%");
  filterEl.setAttribute("y", "-20%");
  filterEl.setAttribute("width", "170%");
  filterEl.setAttribute("height", "140%");

  const blurEl = svgUtilities.createElement("feGaussianBlur");
  blurEl.setAttribute("in", "SourceAlpha");
  blurEl.setAttribute("stdDeviation", ".25");
  filterEl.appendChild(blurEl);

  const offsetEl = svgUtilities.createElement("feOffset");
  offsetEl.setAttribute("dx", "-.1");
  offsetEl.setAttribute("dy", ".25");
  filterEl.appendChild(offsetEl);

  const compFunctionEl = svgUtilities.createElement("feComponentTransfer");
  const funcAEl = svgUtilities.createElement("feFuncA");
  funcAEl.setAttribute("type", "linear");
  funcAEl.setAttribute("slope", ".25");
  compFunctionEl.appendChild(funcAEl);
  filterEl.appendChild(compFunctionEl);

  const mergeEl = svgUtilities.createElement("feMerge");
  const mergeNode1El = svgUtilities.createElement("feMergeNode");
  mergeEl.appendChild(mergeNode1El);
  const mergeNode2El = svgUtilities.createElement("feMergeNode");
  mergeNode2El.setAttribute("in", "SourceGraphic");
  mergeEl.appendChild(mergeNode2El);
  filterEl.appendChild(mergeEl);

  return filterEl;
};

const createFrameDropShadowFilterElement = function (frameId) {
  const filterEl = svgUtilities.createElement("filter");
  filterEl.id = `outer-frame-shadow-${frameId}`;
  filterEl.setAttribute("x", "-20%");
  filterEl.setAttribute("y", "-20%");
  filterEl.setAttribute("width", "170%");
  filterEl.setAttribute("height", "140%");

  const blurEl = svgUtilities.createElement("feGaussianBlur");
  blurEl.setAttribute("in", "SourceAlpha");
  blurEl.setAttribute("stdDeviation", ".25");
  filterEl.appendChild(blurEl);

  const offsetEl = svgUtilities.createElement("feOffset");
  offsetEl.setAttribute("dx", "-.1");
  offsetEl.setAttribute("dy", ".225");
  filterEl.appendChild(offsetEl);

  const compFunctionEl = svgUtilities.createElement("feComponentTransfer");
  const funcAEl = svgUtilities.createElement("feFuncA");
  funcAEl.setAttribute("type", "linear");
  funcAEl.setAttribute("slope", ".125");
  compFunctionEl.appendChild(funcAEl);
  filterEl.appendChild(compFunctionEl);

  const mergeEl = svgUtilities.createElement("feMerge");
  const mergeNode1El = svgUtilities.createElement("feMergeNode");
  mergeEl.appendChild(mergeNode1El);
  const mergeNode2El = svgUtilities.createElement("feMergeNode");
  mergeNode2El.setAttribute("in", "SourceGraphic");
  mergeEl.appendChild(mergeNode2El);
  filterEl.appendChild(mergeEl);

  return filterEl;
};

const createFloatMountShadowFilterElement = function (frameId) {
  const filterEl = svgUtilities.createElement("filter");
  filterEl.setAttribute("id", `float-mount-shadow-${frameId}`);
  filterEl.setAttribute("x", 0);
  filterEl.setAttribute("y", 0);
  filterEl.setAttribute("width", "200%");
  filterEl.setAttribute("height", "200%");
  filterEl.setAttribute("filterUnits", "userSpaceOnUse");

  const blurEl = svgUtilities.createElement("feGaussianBlur");
  blurEl.setAttribute("in", "SourceAlpha");
  blurEl.setAttribute("stdDeviation", ".1");
  filterEl.appendChild(blurEl);

  const offsetEl = svgUtilities.createElement("feOffset");
  offsetEl.setAttribute("dx", "-.15");
  offsetEl.setAttribute("dy", ".15");
  offsetEl.setAttribute("result", "offsetblur");
  filterEl.appendChild(offsetEl);

  const compFunctionEl = svgUtilities.createElement("feComponentTransfer");
  const funcAEl = svgUtilities.createElement("feFuncA");
  funcAEl.setAttribute("type", "linear");
  funcAEl.setAttribute("slope", ".4");
  compFunctionEl.appendChild(funcAEl);
  filterEl.appendChild(compFunctionEl);

  const mergeEl = svgUtilities.createElement("feMerge");
  const mergeNode1El = svgUtilities.createElement("feMergeNode");
  mergeEl.appendChild(mergeNode1El);
  const mergeNode2El = svgUtilities.createElement("feMergeNode");
  mergeNode2El.setAttribute("in", "SourceGraphic");
  mergeEl.appendChild(mergeNode2El);
  filterEl.appendChild(mergeEl);

  return filterEl;
};

const createAcrylicMountShadowFilterElement = function (frameId) {
  const filterEl = svgUtilities.createElement("filter");
  filterEl.setAttribute("id", `acrylic-mount-shadow-${frameId}`);
  filterEl.setAttribute("x", 0);
  filterEl.setAttribute("y", 0);
  filterEl.setAttribute("width", "200%");
  filterEl.setAttribute("height", "200%");
  filterEl.setAttribute("filterUnits", "userSpaceOnUse");

  const blurEl = svgUtilities.createElement("feGaussianBlur");
  blurEl.setAttribute("in", "SourceAlpha");
  blurEl.setAttribute("stdDeviation", ".1");
  filterEl.appendChild(blurEl);

  const offsetEl = svgUtilities.createElement("feOffset");
  offsetEl.setAttribute("dx", ".1");
  offsetEl.setAttribute("dy", ".1");
  offsetEl.setAttribute("result", "offsetblur");
  filterEl.appendChild(offsetEl);

  const compFunctionEl = svgUtilities.createElement("feComponentTransfer");
  const funcAEl = svgUtilities.createElement("feFuncA");
  funcAEl.setAttribute("type", "linear");
  funcAEl.setAttribute("slope", ".25");
  compFunctionEl.appendChild(funcAEl);
  filterEl.appendChild(compFunctionEl);

  const mergeEl = svgUtilities.createElement("feMerge");
  const mergeNode1El = svgUtilities.createElement("feMergeNode");
  mergeEl.appendChild(mergeNode1El);
  const mergeNode2El = svgUtilities.createElement("feMergeNode");
  mergeNode2El.setAttribute("in", "SourceGraphic");
  mergeEl.appendChild(mergeNode2El);
  filterEl.appendChild(mergeEl);

  return filterEl;
};

const createMonogramFoilGradientElements = function (frameId) {
  const gradientEls = [];
  hardCodedValues.monogramFoilColors.forEach(function (foilColor) {
    const gradientEl = svgUtilities.createElement("linearGradient");
    gradientEl.setAttribute(
      "id",
      `monogram-${foilColor.display}-foil-gradient-${frameId}`
    );
    gradientEl.setAttribute("x1", "100%");
    gradientEl.setAttribute("y1", "0%");
    gradientEl.setAttribute("x2", "0%");
    gradientEl.setAttribute("y2", "100%");
    const stop1El = svgUtilities.createElement("stop");
    stop1El.setAttribute("offset", "0%");
    stop1El.setAttribute("stop-opacity", "1");
    stop1El.setAttribute("stop-color", foilColor.colors[0]);
    gradientEl.appendChild(stop1El);
    const stop2El = svgUtilities.createElement("stop");
    stop2El.setAttribute("offset", "100%");
    stop2El.setAttribute("stop-opacity", "1");
    stop2El.setAttribute("stop-color", foilColor.colors[1]);
    gradientEl.appendChild(stop2El);
    gradientEls.push(gradientEl);
  });
  return gradientEls;
};

const createMouldingPlateHardwareElement = function (frameId) {
  const url = hardCodedValues.mouldingPlate.brassPlate.url;
  const patternEl = svgUtilities.createElement("pattern");
  patternEl.setAttribute("id", `plate-${frameId}`);
  patternEl.setAttribute("x", 0);
  patternEl.setAttribute("y", 0);
  patternEl.setAttribute("width", 1);
  patternEl.setAttribute("height", 1);
  patternEl.setAttribute("patternContentUnits", "userSpaceOnUse");
  const imageEl = svgUtilities.createElement("image");
  imageEl.setAttribute("width", hardCodedValues.mouldingPlate.brassPlate.width);
  imageEl.setAttribute("height", hardCodedValues.mouldingPlate.brassPlate.height);
  imageEl.setAttribute("preserveAspectRatio", "none");
  svgUtilities.setHref(imageEl, url);
  patternEl.appendChild(imageEl);
  return patternEl;
};

const createLine = function (dimX1, dimX2, dimY1, dimY2, color, width) {
  const line = svgUtilities.createElement("line");
  line.setAttribute("x1", dimX1);
  line.setAttribute("x2", dimX2);
  line.setAttribute("y1", dimY1);
  line.setAttribute("y2", dimY2);
  line.setAttribute("stroke", color);
  line.setAttribute("stroke-width", width);
  
  return line;
};

const createText = function(x, y, fontSize, textValue, textAnchor = "start") {
  const text = svgUtilities.createElement("text");
  
  text.setAttribute("x", x);
  text.setAttribute("y", y);
  text.textContent = textValue;
  text.setAttribute("font-size", fontSize);
  text.setAttribute("text-anchor", textAnchor);
  
  return text;
};

const createHorizontalAnnotation = function(x, topY, bottomY, borderSize, textValue) {
  const spaceHeight = bottomY - topY;
  const lineSpace = borderSize / 5.0;
  let fontSize = borderSize / 5.0;
  let y = topY + spaceHeight / 2 + fontSize / 2;
  
  if(fontSize > spaceHeight) {
    fontSize = spaceHeight;
    if(fontSize < (borderSize / 10.0)) {
      fontSize = borderSize / 10.0;
    }
    y = bottomY - fontSize / 10.0;
  }
    
  return createText(x + lineSpace, y, fontSize, Math.round(textValue * 100) / 100);
};

const createVerticalAnnotation = function(leftX, rightX, y, borderSize, textValue) {
  const spaceWidth = rightX - leftX;
  const lineSpace = borderSize / 5.0;
  let fontSize = borderSize / 5.0;
  textValue = Math.round(textValue * 100) / 100;
  let x = leftX + spaceWidth / 2 - ((textValue.toString().length - 1) / 2 * fontSize);
  
  if(x < leftX) {
    x = leftX + fontSize / 10.0;
  }
  
  if(fontSize > spaceWidth) {
    fontSize = spaceWidth;
    if(fontSize < (borderSize / 10.0)) {
      fontSize = borderSize / 10.0;
    }
    x = leftX + fontSize / 10.0;
  }
    
  return createText(x, y + lineSpace * 2, fontSize, textValue, "center");
};

const createHorizontalAnnotations = 
          function(containerEl, offsets, rightBorder, borderSize, lineWidth) {
  let height = offsets[0];
  const gridRight = rightBorder + borderSize;
  
  containerEl.appendChild(createLine(0, gridRight, height, height, "blue", lineWidth));
  for(let index = 1; index < offsets.length; ++index) {
    const nextHeight = height + offsets[index];
    containerEl.appendChild(createLine(0, gridRight, nextHeight, nextHeight, "blue", lineWidth));
    containerEl.appendChild(
        createHorizontalAnnotation(rightBorder, height, nextHeight, borderSize, offsets[index]));
    height = nextHeight;
  }
};

const createVerticalAnnotations = 
          function(containerEl, offsets, bottomBorder, borderSize, lineWidth) {
  let width = offsets[0];
  const gridBottom = bottomBorder + borderSize;
  
  containerEl.appendChild(createLine(width, width, 0, gridBottom, "blue", lineWidth));
  for(let index = 1; index < offsets.length; ++index) {
    const nextWidth = width + offsets[index];
    containerEl.appendChild(createLine(nextWidth, nextWidth, 0, gridBottom, "blue", lineWidth));
    containerEl.appendChild(
      createVerticalAnnotation(width, nextWidth, bottomBorder, borderSize, offsets[index]));
    width = nextWidth;
  }
};

const createAnnotations = function (frameSpec, borderSize) {
  const annotationEl = svgUtilities.createElement("g");
  svgUtilities.addClasses(annotationEl, "annotations");
  
  if(frameSpec) {  
    const lineWidth = borderSize / 300.0;
  
    //
    // main horizontal lines
    //
    const heights = [0, frameSpec.moulding.mouldingWidth];
    for(let index = frameSpec.mats.length - 1; index >= 0; --index) {
      heights.push(frameSpec.mats[index].reveal.topInInches);
    }
    if(frameSpec.mount && frameSpec.mount.reveal && frameSpec.mount.reveal.topInInches > 0) {
      heights.push(frameSpec.mount.reveal.topInInches);
    }
    heights.push(frameSpec.artworks[0].exterior.artworkOpeningHeightInInches);
    if(frameSpec.mount && frameSpec.mount.reveal && frameSpec.mount.reveal.bottomInInches > 0) {
      heights.push(frameSpec.mount.reveal.bottomInInches);
    }
        
    for(let index = 0; index < frameSpec.mats.length; ++index) {
      heights.push(frameSpec.mats[index].reveal.bottomInInches);
    }
    heights.push(frameSpec.moulding.mouldingWidth);
    createHorizontalAnnotations(annotationEl, 
      heights, 
      frameSpec.moulding.exterior.widthInInches, 
      borderSize, 
      lineWidth);
    
    //
    // main vertical lines
    //
    const widths = [0, frameSpec.moulding.mouldingWidth];
    for(let index = frameSpec.mats.length - 1; index >= 0; --index) {
      widths.push(frameSpec.mats[index].reveal.leftInInches);
    }
    if(frameSpec.mount && frameSpec.mount.reveal && frameSpec.mount.reveal.leftInInches > 0) {
      widths.push(frameSpec.mount.reveal.leftInInches);
    }
    widths.push(frameSpec.artworks[0].exterior.artworkOpeningWidthInInches);
    if(frameSpec.mount && frameSpec.mount.reveal && frameSpec.mount.reveal.rightInInches > 0) {
      widths.push(frameSpec.mount.reveal.rightInInches);
    }
    
    for(let index = 0; index < frameSpec.mats.length; ++index) {
      widths.push(frameSpec.mats[index].reveal.rightInInches);
    }
    widths.push(frameSpec.moulding.mouldingWidth);
      
    createVerticalAnnotations(annotationEl, 
      widths, 
      frameSpec.moulding.exterior.heightInInches, 
      borderSize, 
      lineWidth);  
  }
  
  return annotationEl;
};

export const group = function (
  framePreviewProps,
  ids,
  activeArtworkChange,
  loadingCallback,
  offsetX,
  offsetY,
  annotate = false,
  annotationSize = 0,
  frameSpec = null,
  artworkOverlay = false
) {

  if (!loadingCallback || typeof loadingCallback !== "function") {
    loadingCallback = generateFramePreviewLoadingEl.createLoadingIconElement;
  }

  const frameId = generateFrameId(ids);

  const frameEl = svgUtilities.createElement("g");
  svgUtilities.addClasses(frameEl, "frame");
  frameEl.id = frameId;

  if (ids.gallery) {
    frameEl.setAttribute(
      "filter",
      `url(#outer-frame-shadow-gallery-${ids.gallery}-frame-${ids.frame})`
    );
  } else {
    frameEl.setAttribute(
      "filter",
      `url(#outer-frame-shadow-frame-${ids.frame})`
    );
  }

  frameEl.setAttribute("data-frame-key", ids.frame);
  if (ids.gallery) {
    frameEl.setAttribute("data-gallery-key", ids.gallery);
  }
  frameEl.setAttribute(
    "data-artworks-count",
    framePreviewProps.artworks.length
  );
  if (framePreviewProps.artworks.length === 1) {
    frameEl.setAttribute("data-artwork-key", 0);
  }

  if (framePreviewProps.mount) {
    const mountEl = createMountElement(framePreviewProps.mount, frameId);
    frameEl.appendChild(mountEl);
  }

  const hasMat = framePreviewProps.mats && framePreviewProps.mats.length;

  const artworksEl = svgUtilities.createElement("g");
  svgUtilities.addClasses(artworksEl, "artworks");
  framePreviewProps.artworks.forEach(function (artwork, artworkKey) {
    const appendLoadingIcon = (hidden = false) => {
      const loadingIconEl = loadingCallback(
        artwork,
        frameEl,
        hasMat,
        activeArtworkChange
      );

      if (loadingIconEl !== null) {
        if (hidden) {
          loadingIconEl.style.display = 'none';
        }
        artworksEl.appendChild(loadingIconEl);
      }
    };
    const appendArtworkOverlay = () => {
      const backgroundEl = generateFramePreviewLoadingEl.createBackgroundElement(artwork, hasMat);
      artworksEl.appendChild(backgroundEl);
    };

    if (
      artwork.cropping &&
      (loadingCallback && typeof loadingCallback === "function")
    ) {
      // if the cropped artwork is being generated, show spinner
      appendLoadingIcon();
    } else if (artwork.pattern) {
      // if we have artwork, show it
      const artworkId = generateArtworkId(artworkKey);
      const patternId = generatePatternId(artwork, frameId, artworkId);
      const artworkEl = createArtworkElement(
        artwork,
        framePreviewProps.mount.elevated,
        frameEl,
        activeArtworkChange,
        framePreviewProps.mount.name,
        patternId
      );
      artworksEl.appendChild(artworkEl);

      appendLoadingIcon(true);

      if (artworkOverlay) {
        appendArtworkOverlay();  
      }

      if (artwork.spec && artwork.spec.allowUpload) { 
        createAndAppendIcon(artwork, frameEl, hasMat, activeArtworkChange, artworksEl);
      }

    } else {
      // if we don't have artwork, show a plus sign
      createAndAppendIcon(artwork, frameEl, hasMat, activeArtworkChange, artworksEl);
      appendLoadingIcon(true);
    }
  });
  frameEl.appendChild(artworksEl);

  const matsEl = svgUtilities.createElement("g");
  svgUtilities.addClasses(matsEl, "mats");

  const mats = framePreviewProps.mats;

  if (mats.length > 1) {
    // base mat needs to be aware if there's another mat
    // top mat needs to be aware if the base core is black
    mats[0].singleMat = false;
    if (mats[0].coreShade == "black") {
      mats[1].blackInnerCore = true;
    }
  } else if (mats.length) {
    mats[0].singleMat = true;
  }

  mats.forEach(function (mat) {
    matsEl.appendChild(createMatElement(mat, frameId));
  });
  frameEl.appendChild(matsEl);

  framePreviewProps.artworks.forEach(function (artwork) {
    if (artwork.spec && artwork.spec.allowUploadRemoval) {
      createAndAppendCloseIcon(artwork, frameEl, framePreviewProps);
    }
  });

  if (framePreviewProps.mount.elevated && framePreviewProps.mount.name === "ACRY01") {
    const glareElement = svgUtilities.createGlareImage(framePreviewProps.mount);
    frameEl.appendChild(glareElement);
  }

  const mouldingEl = createMouldingElement(
    framePreviewProps.moulding,
    frameId
  );
  frameEl.appendChild(mouldingEl);

  if (framePreviewProps.moulding.mouldingPlate) {
    const mouldingPlateEl = createMouldingPlateElement (
      framePreviewProps.moulding,
      offsetX,
      offsetY,
      frameId
    );
    frameEl.appendChild(mouldingPlateEl);
  }

  if (framePreviewProps.adornments && Object.keys(framePreviewProps.adornments).length > 0){
    if (framePreviewProps.adornments.length > 0) {
      framePreviewProps.adornments.forEach(function(adornment) {
        const adornmentEl = createAdornmentsElement(
          adornment,
          offsetX,
          offsetY,
          frameId
        );
        frameEl.appendChild(adornmentEl);
      });
    } else {
      const adornmentEl = createAdornmentsElement(
        framePreviewProps.adornments,
        offsetX,
        offsetY,
        frameId
      );
      frameEl.appendChild(adornmentEl);
    }
  }

  if (annotate) {
    frameEl.appendChild(createAnnotations(frameSpec, annotationSize));
  }

  return frameEl;
};

export const defs = function (framePreviewProps, ids, frameOffsetX, frameOffsetY) {
  const frameId = generateFrameId(ids);
  const defsEl = svgUtilities.createElement("defs");

  framePreviewProps.artworks.forEach(function (artwork, artworkKey) {
    const artworkId = generateArtworkId(artworkKey);
    if (artwork.pattern) {
      defsEl.appendChild(createArtworkPatternElement(artwork, frameId, artworkId));
    }
  });

  const mount = framePreviewProps.mount;

  if (mount.pattern) {
    defsEl.appendChild(createMatTexturePattern(mount.pattern, frameId));
  }

  framePreviewProps.mats.forEach(function (mat) {
    if (mat.pattern) {
      defsEl.appendChild(createMatTexturePattern(mat.pattern, frameId, frameOffsetX, frameOffsetY));
    }

    defsEl.appendChild(createMatCoreGradient(mat, frameId));
  });

  framePreviewProps.moulding.patterns.forEach(function (strutPattern) {
    defsEl.appendChild(createStrutPattern(strutPattern, frameId));
  });

  if (framePreviewProps.adornments && Object.keys(framePreviewProps.adornments).length > 0){
    defsEl.appendChild(createAdornmentPatternElement(framePreviewProps.adornments, frameId));
  }

  if (framePreviewProps.moulding.mouldingPlate) {
    defsEl.appendChild(createMouldingPlateHardwareElement(frameId));
  }

  defsEl.appendChild(createFrameInnerShadowFilterElement(frameId));
  defsEl.appendChild(createFrameDropShadowFilterElement(frameId));
  defsEl.appendChild(createFloatMountShadowFilterElement(frameId));
  defsEl.appendChild(createAcrylicMountShadowFilterElement(frameId));
  createMonogramFoilGradientElements(frameId).forEach(function (gradientEl) {
    defsEl.appendChild(gradientEl);
  });

  return defsEl;
};

export default {
  generateFrameId,
  group,
  defs
};
