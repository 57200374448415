export default class TRBL {
  /**
   *Creates an instance of TRBL.
   * @param {Number} top
   * @param {Number} right
   * @param {Number} bottom
   * @param {Number} left
   */
  constructor(top, right, bottom, left) {
    this.topInInches = Number(top);
    this.rightInInches = Number(right);
    this.bottomInInches = Number(bottom);
    this.leftInInches = Number(left);
  }

  deflate (){
    return [
      this.topInInches,
      this.rightInInches,
      this.bottomInInches,
      this.leftInInches
    ].join(",");
  }

  /**
   * @description Returns a plain object that can be serialized via JSON.stringify
   * @returns {Object}
   */
  toJSON() {
    return {
      topInInches: this.topInInches,
      rightInInches: this.rightInInches,
      bottomInInches: this.bottomInInches,
      leftInInches: this.leftInInches
    };
  }
};
