/**
 * @description Utility function for posting with progress
 * @param {string} url
 * @param {FormData} payload
 * @param {Object} opts
 * @returns {Promise}
 */
function post(url, payload, opts) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    if(typeof opts.onUploadProgress == "function") {
      xhr.upload.addEventListener("progress", function (event) {
        const percentComplete = (event.loaded / event.total) * 100;
        opts.onUploadProgress({percentComplete, event});
      });
    }
    const headers = opts.headers;
    xhr.addEventListener("load", function() {
      resolve(this.responseText);
    });
    xhr.addEventListener("abort", function() {
      reject(this.responseText);
    });
    xhr.addEventListener("error", function() {
      reject(this.responseText);
    });
    xhr.open("POST", url);

    // Loop over headers object attaching keys as header names.
    Object.entries(headers).forEach(([header, value]) => {
      xhr.setRequestHeader(header, value);
    });

    xhr.send(payload);
  });
}

function uploadFileToS3({
  file,
  client,
  onUploadProgress
}) {
  return client.get("/api/v1/artworks/new.json")
    .then((result) => {
      const {
        data
      } = result;
      const formData = new FormData();
      // Fix file name for s3 upload destination
      const sanitizedFileName = file.name.replace(/[^0-9A-Za-z_.-]/g, "_");
      const contentType = file.type !== "" ? file.type : "application/octet-stream";

      // AWS S3 API Params
      formData.append("key", data.key);
      formData.append("acl", data.acl);
      formData.append("AWSAccessKeyId", data.AWSAccessKeyId);
      formData.append("policy", data.policy);
      formData.append("signature", data.signature);
      formData.append("success_action_status", data.success_action_status);
      formData.append("content-type", contentType);

      // Utility params
      formData.append("x-requested-with", "xhr");
      formData.append("utf8", "✓");

      // Send the file last so headers are streamed first.
      formData.append("file", new File([file], sanitizedFileName));

      return post(data.bucket, formData, {
        onUploadProgress,
        headers: {
          "Accept": "application/json; charset=UTF-8"
        }
      });

    })
    .then(str => {
      const xml = (new DOMParser()).parseFromString(str, "text/xml");
      return xml.querySelector("PostResponse Location").textContent;
    });
}

/**
 * A artwork Uploader function
 *
 *
 * @param {Object} config
 * @param {string} config.file - A FileAPI object of an file to be uploaded
 * @param {string} config.client - The API client to use to communicate with.
 */
export const artworkUploader = async ({
  url,
  file,
  artwork,
  client,
  onArtworkCreate,
  onUploadProgress
}) => {
  try {
    let remoteUrl;

    if (file) {
      remoteUrl = await uploadFileToS3({
        file,
        client,
        onUploadProgress
      });
    } else if (url) {
      remoteUrl = url;
    } else {
      throw new Error("Invalid Arguments, you must provide a file or a URL");
    }

    const artworkPayload = {
      artwork: {
        image_remote_url: remoteUrl,
        ...artwork,
      },
    };

    const artworkCreateResponse = await client.post("/api/v1/artworks.json", artworkPayload);

    const { data, status, headers } = artworkCreateResponse;

    if (typeof onArtworkCreate === "function") {
      onArtworkCreate(data);
    }

    if (status < 200 || status > 299) {
      throw new Error(artworkCreateResponse);
    }

    const pollResp = await client.poll(async () => {
      const imageLocation = headers.get("Location");
      if (imageLocation) {
        return client.get(imageLocation);
      } else {
        throw new Error(artworkCreateResponse);
      }
    });

    const redirectUrl = pollResp.headers.get("Location");
    const result = await client.get(redirectUrl);

    return result;
  } catch (error) {
    throw error;
  }
};

export default artworkUploader;

