import {
  minimumPixelsPerInchForPrinting as minimumPPI,
  maxArtworkDimensions,
  maxOversizedArtworkDimensions
} from "./hardCodedValues";

export const proportionalArtworkDimensions = (width, height, minShortSide, minLongSide, dimensionSteps, allowOversize) => {
  minShortSide = minShortSide || 2;
  minLongSide = minLongSide || 4;
  dimensionSteps = dimensionSteps || 1;
  allowOversize = allowOversize || false;
  const tolerence = 1/32;

  const maxDim = (allowOversize, index) => {
    if (allowOversize) {
      return maxOversizedArtworkDimensions[index];
    } else {
      return maxArtworkDimensions[index];
    }
  };

  const maxShortSide = maxDim(allowOversize, 0);
  const maxLongSide = maxDim(allowOversize, 1);

  const aspectRatio = width / height;
  const maxInches = {
    width: width / minimumPPI,
    height: height / minimumPPI
  };

  // short side must be at least 2, long side must be at least 4
  const minRule = (w, h) => {
    return Math.min(w, h) + tolerence >= minShortSide && Math.max(w, h) + tolerence >= minLongSide;
  };

  // short side can't be over 32, long side can't be over 40
  const maxRule = (w, h) => {
    return Math.min(w, h) - tolerence <= maxShortSide && Math.max(w, h) - tolerence <= maxLongSide;
  };

  // sides can't exceed minimum PPI threshold
  const ppiRule = (w, h) => {
    return w <= maxInches.width && h <= maxInches.height;
  };

  const range = (left, right, inclusive) => {
    const range = [];
    const ascending = left < right;
    const end = !inclusive ? right : ascending ? right + 1 : right - 1;
    /* eslint-disable-next-line id-length */
    for (let i = left; ascending ? i < end : i > end; ascending ? i += dimensionSteps : i -= dimensionSteps) {
      range.push(i);
    }
    return range;
  };

  const possibleWidths = () => {
    return range(minShortSide, maxLongSide, true).filter((num) => {
      const width = num;
      const height = num / aspectRatio;
      return minRule(width, height) && maxRule(width, height) && ppiRule(width, height);
    });
  };

  const possibleDimensions = () => {
    return possibleWidths().map((width) => {
      return {
        widthInInches: width,
        heightInInches: Number((width / aspectRatio).toFixed(2))
      };
    });
  };

  return possibleDimensions();
};

export default proportionalArtworkDimensions;
