
const KNOWN_VERBS = ["GET", "HEAD", "POST", "PUT", "DELETE", "CONNECT", "OPTIONS", "TRACE", "PATCH"];

const delay = (time) => {
  return new Promise(resolve => setTimeout(resolve, time));
};

/**
 * @description A API client to be used with Framebridge style API handlers
 *
 * @class GlazeClient
 * @example
 * // Create a new client
 * const client = new GlazeClient("token")
 * const response = await client.get("/api/myapi")
 * @example
 * // Create a new client that rejects any API response that is not 2xx
 * const client = new GlazeClient("token", {rejectOnNotOk: true})
 * const response = await client.get("/api/myapi")
 * @example
 * // Create a new client that calls a function after every request
 * const client = new GlazeClient(
 *                  "token",
 *                  {interceptors: {response: ({response}) => { console.log(response.status)}}}
 *                )
 * const response = await client.get("/api/myapi")
 */
class GlazeClient {
  constructor(token, globalConfig) {
    this.token = token;
    this.globalConfig = globalConfig || {};
  }

  /**
   * PRIVATE
   * Build a url with query params from an object
   *
   * @param {String} uri
   * @param {Object} data
   * @returns {String}
   * @memberof GlazeClient
   */
  _buildGetQuery(params, prefix) {
      const query = Object.keys(params).map((key) => {
        let value = params[key];

        if (!value && (value === null || value === undefined || isNaN(value))) {
          value = "";
        }

        switch (params.constructor) {
          case Array:
            key = `${prefix}[]`;
            break;
          case Object:
            key = (prefix ? `${prefix}[${key}]` : key);
            break;
        }

        if (typeof value === "object") {
          return this._buildGetQuery(value, key); // for nested objects
        }

        return `${key}=${encodeURIComponent(value)}`;
      });

      return query.join("&");
    }

  _buildUri(uri, data) {
    if (typeof (data) !== "object") {
      return uri;
    }

    return `${uri}?${this._buildGetQuery(data)}`;
  }

  /**
   * PRIVATE
   * Ensures that http verbs confirm to spec
   *
   * @param {String} HTTP Verb
   * @returns {String}
   * @memberof GlazeClient
   */
  _conformMethodName(name) {
    name = name.toUpperCase();
    if (KNOWN_VERBS.includes(name)) {
      return name;
    } else {
      throw `Unknown HTTP verb ${name}`;
    }
  }

  /**
   * Send a generic request to a url, via a method, with the given data
   *
   * This must be a json endpoint
   *
   * @param {String} url
   * @param {String} method
   * @param {Object} [data]
   * @returns {Promise}
   * @memberof GlazeClient
   */
  async request(url, method, data, clientConfig) {
    method = this._conformMethodName(method);
    const headers = {
      "Content-Type": "application/json"
    };

    const csrfToken = document.querySelector("meta[name=csrf-token]");
    if (csrfToken) {
      headers["X-CSRF-Token"] = csrfToken.content;
    }

    if (this.token && url.match(/^\/(api|joinery)\//)) {
      headers["X-Spree-Token"] = this.token;
    }

    if (this.globalConfig.baseUrl && !url.match(/^http/)) {
      url = this.globalConfig.baseUrl + url;
    }

    let config = Object.assign({},
      this.globalConfig,
      {method},
      {headers},
      clientConfig
    );

    if (method === "GET" && data) {
      url = this._buildUri(url, data);
    } else if (data) {
      config.body = JSON.stringify(data);
    }

    // If a global request handler interceptor is registered call it
    // If the request interceptor returns a value, assume it is a mutated config object
    if (config.interceptors && typeof config.interceptors.request === "function") {
      const interceptorResponse = config.interceptors.request({ url, config });
      if (interceptorResponse) {
        config = interceptorResponse;
      }
    }

    window.pendingRequests = window.pendingRequests || 0;
    window.pendingRequests += 1;

    try {
      let response = await fetch(url, config);

      // Parse API response if it can be parsed with json
      if (response.status === 204) {
        // if the status code is 204 there is no content and the json
        // function on the object will error
        return response;
      } else {
        const text = await response.text();
        try {
          response.data = JSON.parse(text);
        } catch (error) {
          response.data = {};
        }
        // If a global handler response interceptor is registered call it
        // If the response interceptor returns a value, assume it is a mutated config object
        if (config.interceptors && typeof config.interceptors.response === "function") {
          const interceptorResponse = config.interceptors.response({ response, url, config });

          if (interceptorResponse) {
            response = interceptorResponse;
          }
        }
        // Determine if we should reject the promise, or resolve
        if (response.ok) {
          return response;
        } else if (config.rejectOnNotOk || this.globalConfig.rejectOnNotOk) {
          throw response;
        } else {
          return response;
        }
      }
    } finally {
      window.pendingRequests -= 1;
    }
  }

  post(url, data, config) {
    return this.request(url, "POST", data, config);
  }

  put(url, data, config) {
    return this.request(url, "PUT", data, config);
  }

  get(url, data, config) {
    return this.request(url, "GET", data, config);
  }

  delete(url, data, config) {
    return this.request(url, "DELETE", data, config);
  }

  /**
   * Polls a given endpoint per Framebridge Job api spec.
   * @param {function} operation
   * @param {Object} codes
   * @returns {Promise} codes
   */
  async poll(operation, codes) {
    codes = codes || {
      successCode: 204,
      retryCodes: [0, 202, 502, 503, 504],
      failureCode: 410
    };

    const BACKOFF = 500;

    try {
      const response = await operation();

      if (response && response.status === codes.successCode) {
        return response;
      } else if (response && response.status === codes.failureCode) {
        throw new Error(response.data.message);
      } else if (response && codes.retryCodes.includes(response.status)) {
        await delay(BACKOFF);
        return await this.poll(operation, codes);
      } else {
        console.log("Polling got unexpected response code:", response.status);
        await delay(BACKOFF);
        return await this.poll(operation, codes);
      }
    } catch (error) {
      console.log("Polling operation threw an error", error);
      throw error;
    }
  }
}

export default GlazeClient;
