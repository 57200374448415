import { minimumPixelsPerInchForPrinting } from "./hardCodedValues";
import { proportionalArtworkDimensions } from "./proportionalArtworkDimensions.js";

const MAX_SIZE = 5e7; // 50 MB
const MAX_WIDTH = 12000; // 300 dpi X 40" max width => no manufacturing value past 12,000
const MAX_HEIGHT = 12000; // 300 dpi X 40" max width => no manufacturing value past 12,000

const sanitizeFileName = (fileName) => {
  return fileName.replace(/[^0-9A-Za-z_.-]/g, "_");
};

export const getImageErrorsForFile = (
  file, reader, checkForQuality, fixedArtWidth, fixedArtHeight
) => {
  return new Promise(function (resolve, reject) {
    const image = file;
    const img = new Image();
    img.onload = () => {
      const width = img.width;
      const height = img.height;
      const size = file.size;
      const name = sanitizeFileName(file.name);
      const type = file.type;
      const imageErrorsString = getImageErrorsForAttributes(
        width, height, size, fixedArtWidth, fixedArtHeight
      );
      if (imageErrorsString.length > 0 && checkForQuality) {
        reject(imageErrorsString);
      } else {
        resolve({ width, height, size, name, type, img });
      }
    };
    img.onerror = (evt) => {
      console.error("Image loading error:", evt);
      reject("An unknown error occurred. Please try again.");
    };
    img.src = URL.createObjectURL(image);
  });
};

export const getImageErrorsForAttributes = (width, height, size, fixedArtWidth, fixedArtHeight) => {
  const imageErrors = [];
  const shortSide = fixedArtHeight >= fixedArtWidth ? fixedArtWidth : fixedArtHeight;
  const longSide = fixedArtHeight >= fixedArtWidth ? fixedArtHeight : fixedArtWidth;
  const fixedArtWidthInPixels = fixedArtWidth * minimumPixelsPerInchForPrinting;
  const fixedArtHeightInPixels = fixedArtHeight * minimumPixelsPerInchForPrinting;

  if (size > MAX_SIZE) {
    imageErrors.push("We're sorry, but your image is too large to upload. Please upload an image smaller than 50 MB.");
  }

  if (width > MAX_WIDTH) {
    imageErrors.push(`The image width of ${width} pixels is too large.  The maximum width is ${MAX_WIDTH} pixels.`);
  }

  if (height > MAX_HEIGHT) {
    imageErrors.push(`The image height of ${height} pixels is too large.  The maximum height is ${MAX_HEIGHT} pixels.`);
  }

  if (fixedArtWidth && fixedArtHeight) {
    if (width < fixedArtWidthInPixels || height < fixedArtHeightInPixels) {
      imageErrors.push(`The image size does not meet minimum requirements for selected frame. The minimum size is ${fixedArtWidthInPixels} x ${fixedArtHeightInPixels} pixels.`);
    }
  }

  if (proportionalArtworkDimensions(width, height, shortSide, longSide).length < 1) {
    imageErrors.push(`The ${width}px x ${height}px image is too small. Please upload a larger image.`);
  }
  
  return imageErrors.join("\n");
};
