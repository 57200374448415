import {
  INDEX_CHAR,
  INDEX_REGEX,
  shortAddOnsMap,
  shortFrameSpecMap,
  version
} from "./map";
import cloneDeep from "lodash/cloneDeep";
import merge from "lodash/merge";
import TRBL from "../TRBL";

const buildInvertedMap = (urlMap) => {
  const invertedMap = {};
  for (const key in urlMap) {
    if (Object.prototype.hasOwnProperty.call(urlMap, key)) {
      const value = urlMap[key];
      const types = Object.values(value);
      const type = types[types.length - 1];
      invertedMap[Object.keys(value).join(".")] = { key, type };
    }
  }

  return invertedMap;
};

const stripIndexes = trail => {
  const localTrail = cloneDeep(trail);

  localTrail.forEach(function(key) {
    if (parseInt(key) >= 0) {
      localTrail.splice(localTrail.indexOf(key), 1);
    }
  });

  return localTrail;
};

const eligibleForRoute = (route, value) => {
  if (route.type === Array) {
    return value.length === 0;
  } else {
    return true;
  }
};

const deflateType = (type, value) => {
  if (type === TRBL) {
    return new TRBL(...Object.values(value)).deflate();
  } else if (type === Array && value.length === 0){
    return "";
  } else if (type === Boolean){
    return value ? 1 : 0;
  } else {
    return value;
  }
};

export const _traverse = function(map, obj, callback, trail) {
  trail = trail || [];

  Object.keys(obj).forEach(key => {
    const value = obj[key];
    let route;
    const nextTrail = trail.concat(key);
    if (nextTrail.length > 0) {
      route = map[stripIndexes(nextTrail).join(".")];
    }

    if (!route && value && typeof value === "object") {
      _traverse(map, value, callback, nextTrail);
    } else if (route && !eligibleForRoute(route, value) && value && typeof value === "object") {
      _traverse(map, value, callback, nextTrail);
    } else {
      callback.call(obj, key, value, nextTrail, route);
    }
  });
};

export const buildParamsObject = function (sourceObject, sourceMap = shortFrameSpecMap) {
  const urlParams = {};

  if (!sourceObject) {
    return;
  }

  const paramMap = buildInvertedMap(sourceMap);

  _traverse(paramMap, sourceObject, function(_lastKey, value, trail, route) {
    if (!route) {
      return;
    }
    const indices = trail.filter(el => parseFloat(el) >= 0).map(parseFloat);

    const urlKey = route.key.replace(INDEX_REGEX, function() {
      return `${INDEX_CHAR}${indices.shift()}`;
    });

    value = deflateType(route.type, value);

    urlParams[urlKey] = value;
  });

  urlParams.ver = version;

  return urlParams;
};

export const frameSpecToQuery = function(frameSpec) {
  const params = buildParamsObject(frameSpec);
  const urlFragments = Object.entries(params).map(([key, value]) => {
    if (value || value === 0 || value === false || value === "") {
      return `${key}=${encodeURIComponent(value)}`;
    } else {
      return key;
    }

  });
  return urlFragments.join("&");
};

export const convexImageUrl = function (frameSpec) {
  return `https://convex.framebridge.io/fs?${frameSpecToQuery(frameSpec)}`;
};

export const deepLinkedImageUrl = (frameSpec) => {
  const url = [location.protocol, "//", location.host, location.pathname].join("");
  return `${url}?${frameSpecToQuery(frameSpec)}`;
};

export const buildPDPEditPath = ({
  easelAddOn,
  frameSpecification,
  productPath,
  variantSku
}) => {
  const flattenedProductCategories =
    _flattenArtworks(frameSpecification);

  const urlParamsObject = buildParamsObject(
    flattenedProductCategories,
    shortFrameSpecMap
  );

  if (easelAddOn) {
    const addOns = {
      addOns: [
        {
          type: "easel",
          identifier: easelAddOn.properties.variant_id
        }
      ]
    };

    const addOnsObject = buildParamsObject(addOns, shortAddOnsMap);
    merge(urlParamsObject, addOnsObject, { "variant-sku": variantSku });
  } else {
    merge(urlParamsObject, { "variant-sku": variantSku });
  }

  const queryString = Object.entries(urlParamsObject)
    .filter((param) => param[1] || param[1] === 0)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join("&");

  return `${productPath}?${queryString}`;
};

export const _flattenArtworks = (frameSpecification) => {
  const artworks = frameSpecification.artworks.map((art) => {
    if (art.productCategory && art.productCategory.slug) {
      art.productCategory = art.productCategory.slug;
    }
    return art;
  });

  return { ...frameSpecification, artworks };
};

export default {
  _traverse,
  buildParamsObject,
  frameSpecToQuery,
  convexImageUrl,
  deepLinkedImageUrl,
  buildPDPEditPath,
  _flattenArtworks
};
