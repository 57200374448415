import svgUtilities from "./svgUtilities";

export const createRoomRect = function(config, patternId) {
  const roomPreviewEl = svgUtilities.createElement("rect");
  const hackyOffset = 0.3; // This is a hack to remove a small gap between the top of the image and the top of the container
  svgUtilities.addClasses(roomPreviewEl, "room-preview");
  roomPreviewEl.setAttribute("x", 0);
  roomPreviewEl.setAttribute("y", -hackyOffset);
  roomPreviewEl.setAttribute("width", config.wall_width_in_inches || config.wallWidthInInches);
  roomPreviewEl.setAttribute("height", (config.wall_height_in_inches || config.wallHeightInInches) + (2 * hackyOffset));
  roomPreviewEl.setAttribute("fill", `url(#${patternId})`);
  return roomPreviewEl;
};

export const createRoomPattern = function(url, patternId) {
  const roomPreviewPattern = svgUtilities.createStretchingImagePattern(url);
  roomPreviewPattern.id = patternId;
  return roomPreviewPattern;
};

export default {
  createRoomRect,
  createRoomPattern
};
