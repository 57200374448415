import hardCodedValues from "./hardCodedValues";
import { plusIcon } from "./assets/icons";
import { DPI_MULTIPLIER } from "./constants";
import uuidv1 from "uuid/v1";

export const isMatMultiOpening = (matName) => {
  return hardCodedValues.mafMatOptions.includes(matName);
};

export const dataURLtoFile = (dataurl) => {
  const name = uuidv1();
  const arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]);
  let charCount = bstr.length;
  const u8arr = new Uint8Array(charCount);
      
  while(charCount--){
      u8arr[charCount] = bstr.charCodeAt(charCount);
  }
  
  return new File([u8arr], name, {type:mime});
};

export const generateMultiImageCanvasPreview = async function(
  artworksSpec,
  matName,
  config = {}
) {

  const matData = hardCodedValues.primaryMatColors.find((m) => m.name === matName);
  const canvas = document.createElement("canvas");
  canvas.height = matData.artworkHeight * DPI_MULTIPLIER;
  canvas.width = matData.artworkWidth * DPI_MULTIPLIER;
  const ctx = canvas.getContext("2d");

  function calculateCenteredImagePosition(imageWidth, imageHeight, artworkWidth, artworkHeight) {
    const imageWidthHeightRatio = imageWidth / imageHeight;
    const openingWidthHeightRatio = artworkWidth / artworkHeight;
    let left, top, width, height;

    if (imageWidthHeightRatio > openingWidthHeightRatio) {
      // height 100% and center horizontally
      height = artworkHeight;
      width = (height / imageHeight) * imageWidth;
      top = 0;
      left = -(width - artworkWidth) / 2;
    } else {
      // width 100% and center vertically
      width = artworkWidth;
      height = (width / imageWidth) * imageHeight;
      top = -(height - artworkHeight) / 2;
      left = 0;
    }
    return {
      artworktLeftInInches: left,
      artworkTopInInches: top,
      artworkWidthInInches: width,
      artworkHeightInInches: height
    };
  }

  function drawImage(image, opening) {
    const {width: imageWidth, height: imageHeight} = image;
    const imageWidthHeightRatio = Math.round(imageWidth / imageHeight * 100) / 100;
    const artworkWidth = opening.artworkWidthInInches * DPI_MULTIPLIER;
    const artworkHeight = opening.artworkHeightInInches * DPI_MULTIPLIER;
    const openingWidthHeightRatio = Math.round(artworkWidth / artworkHeight * 100) / 100;
    let artworkPosition = {
      artworktLeftInInches: opening.artworkLeftInInches * DPI_MULTIPLIER,
      artworkTopInInches: opening.artworkTopInInches * DPI_MULTIPLIER,
      artworkWidthInInches: opening.artworkWidthInInches * DPI_MULTIPLIER,
      artworkHeightInInches: opening.artworkHeightInInches * DPI_MULTIPLIER
    };

    const ratioTolerance = 0.1;
    if (Math.abs(imageWidthHeightRatio - openingWidthHeightRatio) > ratioTolerance) {
      artworkPosition = calculateCenteredImagePosition(
        imageWidth,
        imageHeight,
        artworkWidth,
        artworkHeight
      );
    }
    ctx.drawImage(
      image,
      artworkPosition.artworktLeftInInches,
      artworkPosition.artworkTopInInches,
      artworkPosition.artworkWidthInInches,
      artworkPosition.artworkHeightInInches
    );
  }

  async function drawPlaceholder(opening) {
    ctx.fillStyle = "#d8d8d8";
    ctx.fillRect(
      opening.artworkLeftInInches * DPI_MULTIPLIER,
      opening.artworkTopInInches * DPI_MULTIPLIER,
      opening.artworkWidthInInches * DPI_MULTIPLIER,
      opening.artworkHeightInInches * DPI_MULTIPLIER
    );
    if (config.renderPlaceholder) {
      const plusIconImage = new Image();
      plusIconImage.height = 10 * DPI_MULTIPLIER;
      plusIconImage.width = 10 * DPI_MULTIPLIER;
      const imageRes = await loadImage(plusIconImage, plusIcon);
      const iconSize = 200;

      // artwork placement + artworkWidth / 2 - iconSizeOffset
      const iconLeft = opening.artworkLeftInInches * DPI_MULTIPLIER
                      + ((opening.artworkWidthInInches * DPI_MULTIPLIER - iconSize) / 2);
      const iconTop  = opening.artworkTopInInches * DPI_MULTIPLIER
                      + ((opening.artworkHeightInInches * DPI_MULTIPLIER - iconSize) / 2);
      ctx.drawImage(
        imageRes,
        iconLeft,
        iconTop,
        iconSize,
        iconSize
      );
    }
  }

  for (let index = 0; index < matData.openings.length; index++) {
    const openingData = matData.openings[index];
    // this accounts for either the artwork knowing it's position in the frame
    // or using the array index at the opening key
    let artwork = artworksSpec.find((artwork) => artwork.openingKey === openingData.artworkKey);
    if (!config.hasOrderedArtworks) {
      artwork = artworksSpec[openingData.artworkKey];
    }
    
    if (artwork && (artwork.url || artwork.customerSpecified || artwork.croppedAndNormalized)) {
      const artworkUrl = 
        artwork.url ||
        artwork.customerSpecified.url ||
        artwork.croppedAndNormalized.url;
      const image = new Image();
      let imageRes;

      // load and draw opening image
      try {
        imageRes = await loadImage(image, artworkUrl);
      } catch (err) {
        throw new Error('artwork-fetch-failed')
      }

      drawImage(imageRes, openingData);
    } else {
      await drawPlaceholder(openingData);
    }
  }

  if (config.toBlob) {
    const canvasBlob = await new Promise(resolve => canvas.toBlob(resolve));
    return {...canvasBlob, name: uuidv1()};
  }
  return canvas.toDataURL();
};

export const loadImage = (image, src) => {
  image.crossOrigin = "Anonymous";
  const imageLoadPromise = new Promise((resolve, reject) => {
    image.onload = function() {
      return image.decode().then(() => {
        return resolve(image);
      });
    };
    image.onerror = reject;
    image.src = src;
  });

  return imageLoadPromise;
};

export default {
  isMatMultiOpening,
  dataURLtoFile,
  generateMultiImageCanvasPreview,
  loadImage
};
