import {
  theHorizontalDuetImage, theVerticalDuetImage, theHorizontalTrioImage,
  theVerticalTrioImage, theQuartetImage, theUnevenQuartetImage,
  thePhotoStripImage, heartstagramMatImage, framedGardenPortraitImage,
  framedGardenLandscapeImage, cornerGardenPortraitImage,
  cornerGardenLandscapeImage, theTwoGenerationHorizontalImage,
  theTwoGenerationVerticalImage, theThreeGenerationHorizontalImage,
  theThreeGenerationVerticalImage, theTwelveGridImage, theThirteenGridImage
} from "./assets/mats";

export const CONVEYANCE_PHYSICAL = "physical";
export const CONVEYANCE_DIGITAL = "digital";

export const CONVEYANCE_SHIPPED = "shipped";
export const CONVEYANCE_UPLOADED = "uploaded";
export const CONVEYANCE_MAILIN = "mailin";
export const CONVEYANCE_UPLOAD = "upload";

export const PHYSICAL_CONVEYANCES = [CONVEYANCE_MAILIN, CONVEYANCE_SHIPPED, CONVEYANCE_PHYSICAL];
export const DIGITAL_CONVEYANCES = [CONVEYANCE_UPLOAD, CONVEYANCE_UPLOADED, CONVEYANCE_DIGITAL];
export const ALL_CONVEYANCES = DIGITAL_CONVEYANCES.concat(PHYSICAL_CONVEYANCES);

// we should probably get these from an api call rather than hard code
export const sizeGroups = [
  {
    name: "XS",
    presentation: "Extra Small",
    sequence: 2,
    width: 5,
    height: 7,
    matWidth: 2.5,
    price: 65,
    expeditedShippingCost: 20,
    conveyanceMethods: ALL_CONVEYANCES,
    type: "standard"
  },
  {
    name: "S",
    presentation: "Small",
    sequence: 3,
    width: 9,
    height: 12,
    matWidth: 2.5,
    price: 85,
    expeditedShippingCost: 20,
    conveyanceMethods: ALL_CONVEYANCES,
    type: "standard"
  },
  {
    name: "M",
    presentation: "Medium",
    sequence: 4,
    width: 12,
    height: 18,
    matWidth: 3,
    price: 99,
    expeditedShippingCost: 30,
    conveyanceMethods: ALL_CONVEYANCES,
    type: "standard"
  },
  {
    name: "L",
    presentation: "Large",
    sequence: 5,
    width: 18,
    height: 24,
    matWidth: 3,
    price: 145,
    expeditedShippingCost: 40,
    conveyanceMethods: ALL_CONVEYANCES,
    type: "standard"
  },
  {
    name: "XL",
    presentation: "Extra Large",
    sequence: 6,
    width: 24,
    height: 34,
    matWidth: 3,
    price: 179,
    expeditedShippingCost: 40,
    conveyanceMethods: ALL_CONVEYANCES,
    type: "standard"
  },
  {
    name: "GR",
    presentation: "Grand",
    sequence: 7,
    width: 32,
    height: 40,
    matWidth: 0,
    price: 209,
    expeditedShippingCost: 60,
    conveyanceMethods: ALL_CONVEYANCES,
    type: "standard"
  },
  {
    name: "XG",
    presentation: "Extra Grand",
    sequence: 8,
    width: 40,
    height: 48,
    matWidth: 3,
    price: 420,
    expeditedShippingCost: 60,
    conveyanceMethods: ALL_CONVEYANCES,
    type: "standard"
  },
  {
    name: "MG",
    presentation: "Mega",
    sequence: 9,
    width: 40,
    height: 60,
    matWidth: 3,
    price: 600,
    expeditedShippingCost: 60,
    conveyanceMethods: ALL_CONVEYANCES,
    type: "standard"
  },
  {
    name: "INSTAGRAM",
    presentation: "Instagram",
    sequence: 1,
    width: 5,
    height: 5,
    matWidth: 2.5,
    price: 39,
    expeditedShippingCost: 15,
    conveyanceMethods: [],
    type: "exactsize"
  }
];

export const fixedArtProductTypes = [
  {
    taxon: "MAN",
    presentation: "Manrepeller"
  },
  {
    taxon: "PEN",
    presentation: "Kids Print"
  },
  {
    taxon: "U100",
    presentation: "Under 100"
  }
];

export const whiteCore = ["#cccccc", "#e3e3e3", "#f6f6f6"];
export const blackCore = ["#000000", "#242020", "#46464e"];

export const defaultMatThickness = 0.0625;

/**
 * A fake sku used on the backend to indicate that a frame should have no mat.
 * @static
 * @type {number}
 */
export const noMatName = "NM00";

/**
 * A fake sku used on the backend to indicate that a frame should have no mat
 * because it is canvas/wood and is being framed in a floating.
 * frame.
 * @static
 * @type {number}
 */
export const canvasMatName = "CV62";

/**
 * A fake sku used on the backend to indicate that receiving should select a
 * specific white mat shade to go with the artwork.
 * @static
 * @type {number}
 */
export const whiteMatName = "WH02";

/**
 * A fake sku used on the backend to indicate that receiving should select a
 * specific offwhite mat shade to go with the artwork.
 * @static
 * @type {number}
 */
export const offWhiteMatName = "OW12";
export const whiteWithBlackCoreMatName = "WB22";
export const blackMatName = "BL42";

/**
 * A fake sku used on the backend to indicate that the openning in the mat will
 * be heart shaped.
 * @static
 * @type {number}
 */
export const heartstagramMatName = "HE15";

/**
 * A fake sku used on the backend to indicate that the openning in the mat will
 * have the correctly sized mat openings in the correct placement.
 * @static
 * @type {number}
 */
export const theHorizontalDuet = "WH02-THD";

 /**
 * A fake sku used on the backend to indicate that the openning in the mat will
 * have the correctly sized mat openings in the correct placement.
 * @static
 * @type {number}
 */
export const theVerticalDuet = "WH02-TVD";

 /**
 * A fake sku used on the backend to indicate that the openning in the mat will
 * have the correctly sized mat openings in the correct placement.
 * @static
 * @type {number}
 */
export const theHorizontalTrio = "WH02-THT";

 /**
 * A fake sku used on the backend to indicate that the openning in the mat will
 * have the correctly sized mat openings in the correct placement.
 * @static
 * @type {number}
 */
export const theVerticalTrio = "WH02-TVT";

 /**
 * A fake sku used on the backend to indicate that the openning in the mat will
 * have the correctly sized mat openings in the correct placement.
 * @static
 * @type {number}
 */
export const theQuartet = "WH02-TQ";

 /**
 * A fake sku used on the backend to indicate that the openning in the mat will
 * have the correctly sized mat openings in the correct placement.
 * @static
 * @type {number}
 */
export const theUnevenQuartet = "WH02-TUQ";

 /**
 * A fake sku used on the backend to indicate that the openning in the mat will
 * have the correctly sized mat openings in the correct placement.
 * @static
 * @type {number}
 */
export const thePhotoStrip = "WH02-TPS";

/**
 * A fake sku used on the backend to indicate that the openning in the mat will
 * have the correctly sized mat openings in the correct placement.
 * @static
 * @type {number}
 */
export const theTwoGenerationHorizontal = "WH02-TTWGH";

/**
 * A fake sku used on the backend to indicate that the openning in the mat will
 * have the correctly sized mat openings in the correct placement.
 * @static
 * @type {number}
 */
export const theTwoGenerationVertical = "WH02-TTWGV";

/**
 * A fake sku used on the backend to indicate that the openning in the mat will
 * have the correctly sized mat openings in the correct placement.
 * @static
 * @type {number}
 */
export const theThreeGenerationHorizontal = "WH02-TTHGH";

/**
 * A fake sku used on the backend to indicate that the openning in the mat will
 * have the correctly sized mat openings in the correct placement.
 * @static
 * @type {number}
 */
export const theThreeGenerationVertical = "WH02-TTHGV";

/**
 * A fake sku used on the backend to indicate that the openning in the mat will
 * have the correctly sized mat openings in the correct placement.
 * @static
 * @type {number}
 */
export const theTwelveGrid = "WH02-TTWGR";

/**
 * A fake sku used on the backend to indicate that the openning in the mat will
 * have the correctly sized mat openings in the correct placement.
 * @static
 * @type {number}
 */
export const theThirteenGrid = "WH02-TTHGR";

/**
 * A fake sku used on the backend to indicate that artwork in the frame is going
 * thru designers choice and does not have a mat selected yet.
 * @static
 * @type {number}
 */
export const designersChoiceMatName = "DC52";

/**
 * A fake sku used on the backend to indicate that artwork in the frame is part
 * of a "ready made frame", and uses this mat to indicate the acrylic options.
 * @static
 * @type {number}
 */
export const acrylicMatName = "ACRY01";

/**
 * A fake sku used on the backend to indicate that artwork in the frame is part
 * of a "ready made frame", and uses this mat to indicate the brass options.
 * @static
 * @type {number}
 */
export const readymadeBrassMatName = "BRAS01";

/**
 * A fake sku used on the backend to indicate that artwork in the frame is part
 * of a "ready made frame", and uses this mat to indicate the blackened brass options.
 * @static
 * @type {number}
 */
export const readymadeBlackenedBrassMatName = "BLBRAS02";

/**
 * A fake sku used on the backend to indicate that artwork in the frame should
 * be float mounted. This does not indicate which float mount will be done, but
 * float 1 is the default.
 * @static
 * @type {number}
 */
export const floatMountMatName = "Float";

/**
 * A fake sku used for the top mat on the backend to indicate that a frame
 * should have an accent mat. The top mat will usually be WH02, unless the
 * accent mat is offwhite, in which case offwhite will be used for the top mat.
 * @static
 * @type {number}
 */
export const accentMatName = "AC22";

/**
 * A fake sku used on the backend to indicate that artwork is a jersey and
 * should be sew floated.
 * @static
 * @type {number}
 */
export const jerseyMatName = "JERS";

/**
 * A sku used to represent the floral printed mat, which will use a base64 image to display on
 * the front end.
 * @static
 * @type {number}
 */
export const framedGardenPortrait = "KV01-P";
export const framedGardenLandscape = "KV01-L";
export const cornerGardenPortrait = "KV02-P";
export const cornerGardenLandscape = "KV02-L";

export const stripedMats = [
  "CSLB", "CSST", "CSRB", "MSNV", "MSFR", "MSBG", "CSLB-H", "CSST-H", "CSRB-H", "MSNV-H", "MSFR-H", "MSBG-H"
]

export const base64MatOptions = [
  heartstagramMatName,
  theHorizontalDuet,
  theVerticalDuet,
  theVerticalTrio,
  theHorizontalTrio,
  theQuartet,
  theUnevenQuartet,
  thePhotoStrip,
  framedGardenPortrait,
  framedGardenLandscape,
  cornerGardenPortrait,
  cornerGardenLandscape,
  theTwoGenerationHorizontal,
  theTwoGenerationVertical,
  theThreeGenerationHorizontal,
  theThreeGenerationVertical,
  theTwelveGrid,
  theThirteenGrid
];

export const mafMatOptions = [
  theHorizontalDuet,
  theVerticalDuet,
  theVerticalTrio,
  theHorizontalTrio,
  theQuartet,
  theUnevenQuartet,
  thePhotoStrip,
  theTwoGenerationHorizontal,
  theTwoGenerationVertical,
  theThreeGenerationHorizontal,
  theThreeGenerationVertical,
  theTwelveGrid,
  theThirteenGrid
];

export const smallMatOpenings = [
  thePhotoStrip
];

export const primaryMatColors = [
  {
    name: whiteMatName,
    surfaceColor: "#fcfcfc",
    coreColors: whiteCore,
    presentation: "White",
    thickness: defaultMatThickness,
    standard: true,
    captionCategory: "light",
    baseMat: true
  },
  // AC22 is accent mat without a specified color
  {
    name: accentMatName,
    surfaceColor: "#fcfcfc",
    coreColors: whiteCore,
    presentation: "Accent Mat",
    thickness: defaultMatThickness,
    fake: true,
    captionCategory: "light"
  },
  {
    name: floatMountMatName,
    surfaceColor: "#fcfcfc",
    coreColors: whiteCore,
    presentation: "White",
    internalOnly: true,
    thickness: 0,
    fake: true,
    captionCategory: "light"
  },
  {
    name: offWhiteMatName,
    surfaceColor: "#f5edd8",
    coreColors: whiteCore,
    presentation: "Off White",
    thickness: defaultMatThickness,
    standard: true,
    captionCategory: "light",
    baseMat: true
  },
  {
    name: "SRM3567",
    presentation: "Fabric White",
    surfaceColor: "#fffefb",
    coreColors: whiteCore,
    thickness: defaultMatThickness,
    parentMat: whiteMatName,
    internalOnly: true,
    captionCategory: "light",
    baseMat: true,
    canOversize: true
  },
  {
    name: "9902",
    presentation: "White Art with Black Core",
    surfaceColor: "#fffefb",
    coreColors: blackCore,
    biColorSwatch: true,
    thickness: defaultMatThickness,
    parentMat: whiteWithBlackCoreMatName,
    internalOnly: true,
    captionCategory: "light"
  },
  {
    name: "BW222",
    presentation: "Dove White",
    surfaceColor: "#fffdff",
    coreColors: whiteCore,
    thickness: defaultMatThickness,
    parentMat: whiteMatName,
    internalOnly: true,
    captionCategory: "light",
    baseMat: true,
    canOversize: true
  },
  {
    name: "E4128",
    presentation: "Ice Cream",
    surfaceColor: "#fffef5",
    coreColors: whiteCore,
    thickness: defaultMatThickness,
    parentMat: offWhiteMatName,
    internalOnly: true,
    captionCategory: "light",
    baseMat: true,
    canOversize: true
  },
  {
    name: "E4065",
    presentation: "Flour White",
    surfaceColor: "#fdfdff",
    coreColors: whiteCore,
    thickness: defaultMatThickness,
    parentMat: whiteMatName,
    internalOnly: true,
    captionCategory: "light",
    baseMat: true
  },
  {
    name: "BW8701",
    presentation: "Digital White",
    surfaceColor: "#fffffd",
    coreColors: whiteCore,
    thickness: defaultMatThickness,
    parentMat: whiteMatName,
    internalOnly: true,
    captionCategory: "light",
    baseMat: true,
    canOversize: true
  },
  {
    name: "SRM3478",
    presentation: "Milk White",
    surfaceColor: "#fefefe",
    coreColors: whiteCore,
    thickness: defaultMatThickness,
    parentMat: whiteMatName,
    internalOnly: true,
    oversized: false,
    captionCategory: "light",
    baseMat: true
  },
  {
    name: "E4770",
    presentation: "Brulée",
    surfaceColor: "#f2e8e1",
    coreColors: whiteCore,
    thickness: defaultMatThickness,
    parentMat: offWhiteMatName,
    internalOnly: true,
    captionCategory: "light",
    baseMat: true
  },
  {
    name: "5770",
    presentation: "Off-White with Black Core",
    surfaceColor: "#F2E8E1",
    coreColors: blackCore,
    biColorSwatch: true,
    thickness: defaultMatThickness,
    parentMat: whiteWithBlackCoreMatName,
    internalOnly: true,
    captionCategory: "light"
  },
  {
    name: "BW404",
    presentation: "Sugar",
    surfaceColor: "#fcfcfb",
    coreColors: whiteCore,
    thickness: defaultMatThickness,
    parentMat: offWhiteMatName,
    internalOnly: true,
    captionCategory: "light",
    baseMat: true,
    canOversize: true
  },
  {
    name: "E4824",
    presentation: "Meringue",
    surfaceColor: "#fafdff",
    coreColors: whiteCore,
    thickness: defaultMatThickness,
    parentMat: offWhiteMatName,
    internalOnly: true,
    captionCategory: "light",
    baseMat: true,
    canOversize: true
  },
  {
    name: "E4203",
    presentation: "Cake",
    surfaceColor: "#fcfcfb",
    coreColors: whiteCore,
    thickness: defaultMatThickness,
    parentMat: offWhiteMatName,
    internalOnly: true,
    captionCategory: "light",
    baseMat: true
  },
  {
    name: "E4081",
    presentation: "Vanilla",
    surfaceColor: "#fffff0",
    coreColors: whiteCore,
    thickness: defaultMatThickness,
    internalOnly: true,
    parentMat: offWhiteMatName,
    captionCategory: "light",
    baseMat: true
  },
  {
    name: "E4772",
    presentation: "Pale Honey",
    surfaceColor: "#f5eed9",
    coreColors: whiteCore,
    thickness: defaultMatThickness,
    parentMat: offWhiteMatName,
    internalOnly: true,
    captionCategory: "light",
    baseMat: true
  },
  {
    name: "WB22",
    surfaceColor: "#fcfcfc",
    coreColors: blackCore,
    biColorSwatch: true,
    presentation: "White with Black Core",
    thickness: defaultMatThickness,
    standard: true,
    captionCategory: "light",
    canOversize: true
  },
  {
    name: "GY32",
    surfaceColor: "#808080",
    coreColors: whiteCore,
    presentation: "Gray",
    thickness: defaultMatThickness,
    standard: true,
    captionCategory: "dark",
    baseMat: true
  },

  {
    name: "E4789",
    presentation: "Soft Gray",
    surfaceColor: "#999590",
    coreColors: whiteCore,
    thickness: defaultMatThickness,
    captionCategory: "light",
    baseMat: true
  },
  {
    name: "E4794",
    presentation: "Warm Gray",
    surfaceColor: "#96959b",
    coreColors: whiteCore,
    thickness: defaultMatThickness,
    captionCategory: "dark",
    baseMat: true
  },
  {
    name: "BW802",
    presentation: "Cool Gray",
    surfaceColor: "#8F9392",
    coreColors: whiteCore,
    thickness: defaultMatThickness,
    captionCategory: "dark",
    baseMat: true
  },
  {
    name: "BW334",
    presentation: "Bottle Blue",
    surfaceColor: "#2a55a3",
    coreColors: whiteCore,
    thickness: defaultMatThickness,
    disabled: true,
    internalOnly: true,
    captionCategory: "dark"
  },
  {
    name: "E4804P",
    presentation: "Dark Green",
    surfaceColor: "#465f42",
    coreColors: whiteCore,
    thickness: defaultMatThickness,
    captionCategory: "dark",
    baseMat: false,
    disabled: true
  },
  {
    name: "BW9571C",
    presentation: "Blush",
    surfaceColor: "#F6D1C5",
    coreColors: whiteCore,
    thickness: defaultMatThickness,
    captionCategory: "light",
    baseMat: true
  },
  {
    name: "11053",
    presentation: "Parakeet",
    surfaceColor: "#B1BE98",
    coreColors: whiteCore,
    thickness: defaultMatThickness,
    captionCategory: "light",
    baseMat: true
  },
  {
    name: "BL42",
    surfaceColor: "#151411",
    coreColors: blackCore,
    biColorSwatch: true,
    presentation: "Black",
    thickness: defaultMatThickness,
    standard: true,
    captionCategory: "dark",
    baseMat: true,
    canOversize: true
  },
  {
    name: "5089",
    presentation: "Black with Black Core, novacore",
    surfaceColor: "#151411",
    coreColors: blackCore,
    biColorSwatch: true,
    thickness: defaultMatThickness,
    parentMat: blackMatName,
    internalOnly: true,
    captionCategory: "dark",
    baseMat: true
  },
  {
    name: "WHOV-1",
    presentation: "Oversized Digital White",
    surfaceColor: "#F8FCF6",
    coreColors: whiteCore,
    thickness: defaultMatThickness,
    internalOnly: true,
    captionCategory: "dark",
    baseMat: true,
    oversizedOnly: true
  },
  {
    name: "WHOV-2",
    presentation: "Oversized Antique White",
    surfaceColor: "#FBFAF2",
    coreColors: whiteCore,
    thickness: defaultMatThickness,
    internalOnly: true,
    captionCategory: "dark",
    baseMat: true,
    oversizedOnly: true
  },
  {
    name: "BLOV-1",
    presentation: "Black Oversized",
    surfaceColor: "#151411",
    coreColors: blackCore,
    thickness: defaultMatThickness,
    internalOnly: true,
    captionCategory: "dark",
    baseMat: true,
    oversizedOnly: true
  },
  {
    name: heartstagramMatName,
    surfaceColor: "#fcfcfc",
    coreColors: ["#e3e3e3", "#e3e3e3", "#e3e3e3"],
    presentation: "Heartstagram",
    thickness: defaultMatThickness,
    internalOnly: true,
    matHeight: 10,
    matWidth: 10,
    disabled: true,
    matAsset: heartstagramMatImage
  },
  {
    name: framedGardenPortrait,
    surfaceColor: "#fcfcfc",
    coreColors: whiteCore,
    presentation: "Framed Garden Portrait",
    thickness: defaultMatThickness,
    internalOnly: true,
    matHeight: 11,
    matWidth: 9,
    disabled: true,
    matAsset: framedGardenPortraitImage
  },
  {
    name: framedGardenLandscape,
    surfaceColor: "#fcfcfc",
    coreColors: whiteCore,
    presentation: "Framed Garden Landscape",
    thickness: defaultMatThickness,
    internalOnly: true,
    matHeight: 9,
    matWidth: 11,
    disabled: true,
    matAsset: framedGardenLandscapeImage
  },
  {
    name: cornerGardenPortrait,
    surfaceColor: "#fcfcfc",
    coreColors: whiteCore,
    presentation: "Corner Garden Portrait",
    thickness: defaultMatThickness,
    internalOnly: true,
    matHeight: 11,
    matWidth: 9,
    disabled: true,
    matAsset: cornerGardenPortraitImage
  },
  {
    name: cornerGardenLandscape,
    surfaceColor: "#fcfcfc",
    coreColors: whiteCore,
    presentation: "Corner Garden Landscape",
    thickness: defaultMatThickness,
    internalOnly: true,
    matHeight: 9,
    matWidth: 11,
    disabled: true,
    matAsset: cornerGardenLandscapeImage
  },
  {
    name: theHorizontalDuet,
    surfaceColor: "#fcfcfc",
    coreColors: whiteCore,
    presentation: "The Horizontal Duet",
    internalOnly: true,
    disabled: true,
    thickness: defaultMatThickness,
    captionCategory: "light",
    matWidth: 12.75,
    matHeight: 10,
    artworkWidth: 8.75,
    artworkHeight: 6,
    matAsset: theHorizontalDuetImage,
    openings: [
      {
        artworkKey: 0,
        topInInches: 0,
        leftInInches: 0,
        widthInInches: 3.75,
        heightInInches: 5.75,
        artworkTopInInches: 0,
        artworkLeftInInches: 0,
        artworkWidthInInches: 4,
        artworkHeightInInches: 6
      },
      {
        artworkKey: 1,
        topInInches: 0,
        leftInInches: 4.75,
        widthInInches: 3.75,
        heightInInches: 5.75,
        artworkTopInInches: 0,
        artworkLeftInInches: 4.75,
        artworkWidthInInches: 4,
        artworkHeightInInches: 6
      }
    ]
  },
  {
    name: theVerticalDuet,
    surfaceColor: "#fcfcfc",
    coreColors: whiteCore,
    presentation: "The Vertical Duet",
    internalOnly: true,
    disabled: true,
    thickness: defaultMatThickness,
    captionCategory: "light",
    matWidth: 10,
    matHeight: 12.75,
    artworkWidth: 6,
    artworkHeight: 8.75,
    matAsset: theVerticalDuetImage,
    openings: [
      {
        artworkKey: 0,
        topInInches: 0,
        leftInInches: 0,
        widthInInches: 5.75,
        heightInInches: 3.75,
        artworkTopInInches: 0,
        artworkLeftInInches: 0,
        artworkWidthInInches: 6,
        artworkHeightInInches: 4
      },
      {
        artworkKey: 1,
        topInInches: 4.75,
        leftInInches: 0,
        widthInInches: 5.75,
        heightInInches: 3.75,
        artworkTopInInches: 4.75,
        artworkLeftInInches: 0,
        artworkWidthInInches: 6,
        artworkHeightInInches: 4
      }
    ]
  },
  {
    name: theVerticalTrio,
    surfaceColor: "#fcfcfc",
    coreColors: whiteCore,
    presentation: "The Vertical Trio",
    internalOnly: true,
    disabled: true,
    thickness: defaultMatThickness,
    captionCategory: "light",
    matWidth: 10,
    matHeight: 17.5,
    artworkWidth: 6,
    artworkHeight: 13.5,
    matAsset: theVerticalTrioImage,
    openings: [
      {
        artworkKey: 0,
        topInInches: 0,
        leftInInches: 0,
        widthInInches: 5.75,
        heightInInches: 3.75,
        artworkTopInInches: 0,
        artworkLeftInInches: 0,
        artworkWidthInInches: 6,
        artworkHeightInInches: 4
      },
      {
        artworkKey: 1,
        topInInches: 4.75,
        leftInInches: 0,
        widthInInches: 5.75,
        heightInInches: 3.75,
        artworkTopInInches: 4.75,
        artworkLeftInInches: 0,
        artworkWidthInInches: 6,
        artworkHeightInInches: 4
      },
      {
        artworkKey: 2,
        topInInches: 9.5,
        leftInInches: 0,
        widthInInches: 5.75,
        heightInInches: 3.75,
        artworkTopInInches: 9.5,
        artworkLeftInInches: 0,
        artworkWidthInInches: 6,
        artworkHeightInInches: 4
      }
    ]
  },
  {
    name: theHorizontalTrio,
    surfaceColor: "#fcfcfc",
    coreColors: whiteCore,
    presentation: "The Horizontal Trio",
    internalOnly: true,
    disabled: true,
    thickness: defaultMatThickness,
    captionCategory: "light",
    matWidth: 17.5,
    matHeight: 10,
    artworkWidth: 13.5,
    artworkHeight: 6,
    matAsset: theHorizontalTrioImage,
    openings: [
      {
        artworkKey: 0,
        topInInches: 0,
        leftInInches: 0,
        widthInInches: 3.75,
        heightInInches: 5.75,
        artworkTopInInches: 0,
        artworkLeftInInches: 0,
        artworkWidthInInches: 4,
        artworkHeightInInches: 6
      },
      {
        artworkKey: 1,
        topInInches: 0,
        leftInInches: 4.75,
        widthInInches: 3.75,
        heightInInches: 5.75,
        artworkTopInInches: 0,
        artworkLeftInInches: 4.75,
        artworkWidthInInches: 4,
        artworkHeightInInches: 6
      },
      {
        artworkKey: 2,
        topInInches: 0,
        leftInInches: 9.5,
        widthInInches: 3.75,
        heightInInches: 5.75,
        artworkTopInInches: 0,
        artworkLeftInInches: 9.5,
        artworkWidthInInches: 4,
        artworkHeightInInches: 6
      }
    ]
  },
  {
    name: theQuartet,
    surfaceColor: "#fcfcfc",
    coreColors: whiteCore,
    presentation: "The Quartet",
    internalOnly: true,
    disabled: true,
    thickness: defaultMatThickness,
    captionCategory: "light",
    matWidth: 12.75,
    matHeight: 12.75,
    artworkWidth: 8.75,
    artworkHeight: 8.75,
    matAsset: theQuartetImage,
    openings: [
      {
        artworkKey: 0,
        topInInches: 0,
        leftInInches: 0,
        widthInInches: 3.75,
        heightInInches: 3.75,
        artworkTopInInches: 0,
        artworkLeftInInches: 0,
        artworkWidthInInches: 4,
        artworkHeightInInches: 4
      },
      {
        artworkKey: 1,
        topInInches: 0,
        leftInInches: 4.75,
        widthInInches: 3.75,
        heightInInches: 3.75,
        artworkTopInInches: 0,
        artworkLeftInInches: 4.75,
        artworkWidthInInches: 4,
        artworkHeightInInches: 4
      },
      {
        artworkKey: 2,
        topInInches: 4.75,
        leftInInches: 0,
        widthInInches: 3.75,
        heightInInches: 3.75,
        artworkTopInInches: 4.75,
        artworkLeftInInches: 0,
        artworkWidthInInches: 4,
        artworkHeightInInches: 4
      },
      {
        artworkKey: 3,
        topInInches: 4.75,
        leftInInches: 4.75,
        widthInInches: 3.75,
        heightInInches: 3.75,
        artworkTopInInches: 4.75,
        artworkLeftInInches: 4.75,
        artworkWidthInInches: 4,
        artworkHeightInInches: 4
      }
    ]
  },
  {
    name: theUnevenQuartet,
    surfaceColor: "#fcfcfc",
    coreColors: whiteCore,
    presentation: "The Uneven Quartet",
    internalOnly: true,
    disabled: true,
    thickness: defaultMatThickness,
    captionCategory: "light",
    matWidth: 12.75,
    matHeight: 12.75,
    artworkWidth: 8.75,
    artworkHeight: 8.75,
    matAsset: theUnevenQuartetImage,
    openings: [
      {
        artworkKey: 0,
        topInInches: 0,
        leftInInches: 0,
        widthInInches: 4.75,
        heightInInches: 3.75,
        artworkTopInInches: 0,
        artworkLeftInInches: 0,
        artworkWidthInInches: 5,
        artworkHeightInInches: 4
      },
      {
        artworkKey: 1,
        topInInches: 0,
        leftInInches: 5.75,
        widthInInches: 2.75,
        heightInInches: 3.75,
        artworkTopInInches: 0,
        artworkLeftInInches: 5.75,
        artworkWidthInInches: 3,
        artworkHeightInInches: 4
      },
      {
        artworkKey: 2,
        topInInches: 4.75,
        leftInInches: 0,
        widthInInches: 2.75,
        heightInInches: 3.75,
        artworkTopInInches: 4.75,
        artworkLeftInInches: 0,
        artworkWidthInInches: 3,
        artworkHeightInInches: 4
      },
      {
        artworkKey: 3,
        topInInches: 4.75,
        leftInInches: 3.75,
        widthInInches: 4.75,
        heightInInches: 3.75,
        artworkTopInInches: 4.75,
        artworkLeftInInches: 3.75,
        artworkWidthInInches: 5,
        artworkHeightInInches: 4
      }
    ]
  },
  {
    name: thePhotoStrip,
    surfaceColor: "#fcfcfc",
    coreColors: whiteCore,
    presentation: "The Photo Strip",
    internalOnly: true,
    disabled: true,
    thickness: defaultMatThickness,
    captionCategory: "light",
    matWidth: 5.25,
    matHeight: 12.75,
    artworkWidth: 2.25,
    artworkHeight: 9.75,
    matAsset: thePhotoStripImage,
    openings: [
      {
        artworkKey: 0,
        topInInches: 0,
        leftInInches: 0,
        widthInInches: 2,
        heightInInches: 2,
        artworkTopInInches: 0,
        artworkLeftInInches: 0,
        artworkWidthInInches: 2.25,
        artworkHeightInInches: 2.25
      },
      {
        artworkKey: 1,
        topInInches: 2.5,
        leftInInches: 0,
        widthInInches: 2,
        heightInInches: 2,
        artworkTopInInches: 2.5,
        artworkLeftInInches: 0,
        artworkWidthInInches: 2.25,
        artworkHeightInInches: 2.25
      },
      {
        artworkKey: 2,
        topInInches: 5,
        leftInInches: 0,
        widthInInches: 2,
        heightInInches: 2,
        artworkTopInInches: 5,
        artworkLeftInInches: 0,
        artworkWidthInInches: 2.25,
        artworkHeightInInches: 2.25
      },
      {
        artworkKey: 3,
        topInInches: 7.5,
        leftInInches: 0,
        widthInInches: 2,
        heightInInches: 2,
        artworkTopInInches: 7.5,
        artworkLeftInInches: 0,
        artworkWidthInInches: 2.25,
        artworkHeightInInches: 2.25
      }
    ]
  },
  {
    name: theTwoGenerationHorizontal,
    surfaceColor: "#fcfcfc",
    coreColors: whiteCore,
    presentation: "The Two Generation Horizontal",
    internalOnly: true,
    disabled: true,
    thickness: defaultMatThickness,
    captionCategory: "light",
    matWidth: 11,
    matHeight: 9,
    artworkWidth: 7,
    artworkHeight: 5,
    matAsset: theTwoGenerationHorizontalImage,
    openings: [
      {
        artworkKey: 0,
        topInInches: 0,
        leftInInches: 0,
        widthInInches: 3,
        heightInInches: 4.75,
        artworkTopInInches: 0,
        artworkLeftInInches: 0,
        artworkWidthInInches: 3.25,
        artworkHeightInInches: 5
      },
      {
        artworkKey: 1,
        topInInches: 0,
        leftInInches: 3.75,
        widthInInches: 3,
        heightInInches: 4.75,
        artworkTopInInches: 0,
        artworkLeftInInches: 3.75,
        artworkWidthInInches: 3.25,
        artworkHeightInInches: 5
      }
    ]
  },
  {
    name: theTwoGenerationVertical,
    surfaceColor: "#fcfcfc",
    coreColors: whiteCore,
    presentation: "The Two Generation Vertical",
    internalOnly: true,
    disabled: true,
    thickness: defaultMatThickness,
    captionCategory: "light",
    matWidth: 9,
    matHeight: 11,
    artworkWidth: 5,
    artworkHeight: 7,
    matAsset: theTwoGenerationVerticalImage,
    openings: [
      {
        artworkKey: 0,
        topInInches: 0,
        leftInInches: 0,
        widthInInches: 4.75,
        heightInInches: 3,
        artworkTopInInches: 0,
        artworkLeftInInches: 0,
        artworkWidthInInches: 5,
        artworkHeightInInches: 3.25
      },
      {
        artworkKey: 1,
        topInInches: 3.75,
        leftInInches: 0,
        widthInInches: 4.75,
        heightInInches: 3,
        artworkTopInInches: 3.75,
        artworkLeftInInches: 0,
        artworkWidthInInches: 5,
        artworkHeightInInches: 3.25
      }
    ]
  },
  {
    name: theThreeGenerationVertical,
    surfaceColor: "#fcfcfc",
    coreColors: whiteCore,
    presentation: "The Three Generation Vertical",
    internalOnly: true,
    disabled: true,
    thickness: defaultMatThickness,
    captionCategory: "light",
    matWidth: 9.75,
    matHeight: 15,
    artworkWidth: 5.25,
    artworkHeight: 10.5,
    matAsset: theThreeGenerationVerticalImage,
    openings: [
      {
        artworkKey: 0,
        topInInches: 0,
        leftInInches: 0,
        widthInInches: 5,
        heightInInches: 3,
        artworkTopInInches: 0,
        artworkLeftInInches: 0,
        artworkWidthInInches: 5.25,
        artworkHeightInInches: 3.25
      },
      {
        artworkKey: 1,
        topInInches: 3.625,
        leftInInches: 0,
        widthInInches: 5,
        heightInInches: 3,
        artworkTopInInches: 3.625,
        artworkLeftInInches: 0,
        artworkWidthInInches: 5.25,
        artworkHeightInInches: 3.25
      },
      {
        artworkKey: 2,
        topInInches: 7.25,
        leftInInches: 0,
        widthInInches: 5,
        heightInInches: 3,
        artworkTopInInches: 7.25,
        artworkLeftInInches: 0,
        artworkWidthInInches: 5.25,
        artworkHeightInInches: 3.25
      }
    ]
  },
  {
    name: theThreeGenerationHorizontal,
    surfaceColor: "#fcfcfc",
    coreColors: whiteCore,
    presentation: "The Three Generation Horizontal",
    internalOnly: true,
    disabled: true,
    thickness: defaultMatThickness,
    captionCategory: "light",
    matWidth: 15,
    matHeight: 9.75,
    artworkWidth: 10.5,
    artworkHeight: 5.25,
    matAsset: theThreeGenerationHorizontalImage,
    openings: [
      {
        artworkKey: 0,
        topInInches: 0,
        leftInInches: 0,
        widthInInches: 3,
        heightInInches: 5,
        artworkTopInInches: 0,
        artworkLeftInInches: 0,
        artworkWidthInInches: 3.25,
        artworkHeightInInches: 5.25
      },
      {
        artworkKey: 1,
        topInInches: 0,
        leftInInches: 3.625,
        widthInInches: 3,
        heightInInches: 5,
        artworkTopInInches: 0,
        artworkLeftInInches: 3.625,
        artworkWidthInInches: 3.25,
        artworkHeightInInches: 5.25
      },
      {
        artworkKey: 2,
        topInInches: 0,
        leftInInches: 7.25,
        widthInInches: 3,
        heightInInches: 5,
        artworkTopInInches: 0,
        artworkLeftInInches: 7.25,
        artworkWidthInInches: 3.25,
        artworkHeightInInches: 5.25
      }
    ]
  },
  {
    name: theTwelveGrid,
    surfaceColor: "#fcfcfc",
    coreColors: whiteCore,
    presentation: "The Twelve Grid",
    internalOnly: true,
    disabled: true,
    thickness: defaultMatThickness,
    captionCategory: "light",
    matWidth: 13,
    matHeight: 15,
    artworkWidth: 10.75,
    artworkHeight: 10.25,
    matAsset: theTwelveGridImage,
    openings: [
      {
        artworkKey: 0,
        topInInches: 0,
        leftInInches: 0,
        widthInInches: 2.25,
        heightInInches: 3,
        artworkTopInInches: 0,
        artworkLeftInInches: 0,
        artworkWidthInInches: 2.5,
        artworkHeightInInches: 3.25

      },
      {
        artworkKey: 1,
        topInInches: 0,
        leftInInches: 2.75,
        widthInInches: 2.25,
        heightInInches: 3,
        artworkTopInInches: 0,
        artworkLeftInInches: 2.75,
        artworkWidthInInches: 2.5,
        artworkHeightInInches: 3.25
      },
      {
        artworkKey: 2,
        topInInches: 0,
        leftInInches: 5.5,
        widthInInches: 2.25,
        heightInInches: 3,
        artworkTopInInches: 0,
        artworkLeftInInches: 5.5,
        artworkWidthInInches: 2.5,
        artworkHeightInInches: 3.25
      },
      {
        artworkKey: 3,
        topInInches: 0,
        leftInInches: 8.25,
        widthInInches: 2.25,
        heightInInches: 3,
        artworkTopInInches: 0,
        artworkLeftInInches: 8.25,
        artworkWidthInInches: 2.5,
        artworkHeightInInches: 3.25
      },
      {
        artworkKey: 4,
        topInInches: 3.5,
        leftInInches: 0,
        widthInInches: 2.25,
        heightInInches: 3,
        artworkTopInInches: 3.5,
        artworkLeftInInches: 0,
        artworkWidthInInches: 2.5,
        artworkHeightInInches: 3.25
      },
      {
        artworkKey: 5,
        topInInches: 3.5,
        leftInInches: 2.75,
        widthInInches: 2.25,
        heightInInches: 3,
        artworkTopInInches: 3.5,
        artworkLeftInInches: 2.75,
        artworkWidthInInches: 2.5,
        artworkHeightInInches: 3.25
      },
      {
        artworkKey: 6,
        topInInches: 3.5,
        leftInInches: 5.5,
        widthInInches: 2.25,
        heightInInches: 3,
        artworkTopInInches: 3.5,
        artworkLeftInInches: 5.5,
        artworkWidthInInches: 2.5,
        artworkHeightInInches: 3.25
      },
      {
        artworkKey: 7,
        topInInches: 3.5,
        leftInInches: 8.25,
        widthInInches: 2.25,
        heightInInches: 3,
        artworkTopInInches: 3.5,
        artworkLeftInInches: 8.25,
        artworkWidthInInches: 2.5,
        artworkHeightInInches: 3.25
      },
      {
        artworkKey: 8,
        topInInches: 7,
        leftInInches: 0,
        widthInInches: 2.25,
        heightInInches: 3,
        artworkTopInInches: 7,
        artworkLeftInInches: 0,
        artworkWidthInInches: 2.5,
        artworkHeightInInches: 3.25
      },
      {
        artworkKey: 9,
        topInInches: 7,
        leftInInches: 2.75,
        widthInInches: 2.25,
        heightInInches: 3,
        artworkTopInInches: 7,
        artworkLeftInInches: 2.75,
        artworkWidthInInches: 2.5,
        artworkHeightInInches: 3.25
      },
      {
        artworkKey: 10,
        topInInches: 7,
        leftInInches: 5.5,
        widthInInches: 2.25,
        heightInInches: 3,
        artworkTopInInches: 7,
        artworkLeftInInches: 5.5,
        artworkWidthInInches: 2.5,
        artworkHeightInInches: 3.25
      },
      {
        artworkKey: 11,
        topInInches: 7,
        leftInInches: 8.25,
        widthInInches: 2.25,
        heightInInches: 3,
        artworkTopInInches: 7,
        artworkLeftInInches: 8.25,
        artworkWidthInInches: 2.5,
        artworkHeightInInches: 3.25
      }
    ]
  },
  {
    name: theThirteenGrid,
    surfaceColor: "#fcfcfc",
    coreColors: whiteCore,
    presentation: "The Thirteen Grid",
    internalOnly: true,
    disabled: true,
    thickness: defaultMatThickness,
    captionCategory: "light",
    matWidth: 13,
    matHeight: 15,
    artworkWidth: 10.25,
    artworkHeight: 10.25,
    matAsset: theThirteenGridImage,
    openings: [
      {
        artworkKey: 0,
        topInInches: 0,
        leftInInches: 0,
        widthInInches: 2.125,
        heightInInches: 2.125,
        artworkTopInInches: 0,
        artworkLeftInInches: 0,
        artworkWidthInInches: 2.375,
        artworkHeightInInches: 2.375
      },
      {
        artworkKey: 1,
        topInInches: 0,
        leftInInches: 2.625,
        widthInInches: 2.125,
        heightInInches: 2.125,
        artworkTopInInches: 0,
        artworkLeftInInches: 2.625,
        artworkWidthInInches: 2.375,
        artworkHeightInInches: 2.375
      },
      {
        artworkKey: 2,
        topInInches: 0,
        leftInInches: 5.25,
        widthInInches: 2.125,
        heightInInches: 2.125,
        artworkTopInInches: 0,
        artworkLeftInInches: 5.25,
        artworkWidthInInches: 2.375,
        artworkHeightInInches: 2.375
      },
      {
        artworkKey: 3,
        topInInches: 0,
        leftInInches: 7.875,
        widthInInches: 2.125,
        heightInInches: 2.125,
        artworkTopInInches: 0,
        artworkLeftInInches: 7.875,
        artworkWidthInInches: 2.375,
        artworkHeightInInches: 2.375
      },
      {
        artworkKey: 4,
        topInInches: 2.625,
        leftInInches: 0,
        widthInInches: 2.125,
        heightInInches: 2.125,
        artworkTopInInches: 2.625,
        artworkLeftInInches: 0,
        artworkWidthInInches: 2.375,
        artworkHeightInInches: 2.375
      },
      {
        artworkKey: 5,
        topInInches: 2.625,
        leftInInches: 2.625,
        widthInInches: 4.75,
        heightInInches: 4.75,
        artworkTopInInches: 2.625,
        artworkLeftInInches: 2.625,
        artworkWidthInInches: 5,
        artworkHeightInInches: 5
      },
      {
        artworkKey: 6,
        topInInches: 2.625,
        leftInInches: 7.875,
        widthInInches: 2.125,
        heightInInches: 2.125,
        artworkTopInInches: 2.625,
        artworkLeftInInches: 7.875,
        artworkWidthInInches: 2.375,
        artworkHeightInInches: 2.375
      },
      {
        artworkKey: 7,
        topInInches: 5.25,
        leftInInches: 0,
        widthInInches: 2.125,
        heightInInches: 2.125,
        artworkTopInInches: 5.25,
        artworkLeftInInches: 0,
        artworkWidthInInches: 2.375,
        artworkHeightInInches: 2.375
      },
      {
        artworkKey: 8,
        topInInches: 5.25,
        leftInInches: 7.875,
        widthInInches: 2.125,
        heightInInches: 2.125,
        artworkTopInInches: 5.25,
        artworkLeftInInches: 7.875,
        artworkWidthInInches: 2.375,
        artworkHeightInInches: 2.375
      },
      {
        artworkKey: 9,
        topInInches: 7.875,
        leftInInches: 0,
        widthInInches: 2.125,
        heightInInches: 2.125,
        artworkTopInInches: 7.875,
        artworkLeftInInches: 0,
        artworkWidthInInches: 2.375,
        artworkHeightInInches: 2.375
      },
      {
        artworkKey: 10,
        topInInches: 7.875,
        leftInInches: 2.625,
        widthInInches: 2.125,
        heightInInches: 2.125,
        artworkTopInInches: 7.875,
        artworkLeftInInches: 2.625,
        artworkWidthInInches: 2.375,
        artworkHeightInInches: 2.375
      },
      {
        artworkKey: 11,
        topInInches: 7.875,
        leftInInches: 5.25,
        widthInInches: 2.125,
        heightInInches: 2.125,
        artworkTopInInches: 7.875,
        artworkLeftInInches: 5.25,
        artworkWidthInInches: 2.375,
        artworkHeightInInches: 2.375
      },
      {
        artworkKey: 12,
        topInInches: 7.875,
        leftInInches: 7.875,
        widthInInches: 2.125,
        heightInInches: 2.125,
        artworkTopInInches: 7.875,
        artworkLeftInInches: 7.875,
        artworkWidthInInches: 2.375,
        artworkHeightInInches: 2.375
      },
    ]
  },
  {
    name: noMatName,
    surfaceColor: "#ffffff",
    coreColors: whiteCore,
    presentation: "No Mat",
    thickness: 0,
    fake: true
  },
  {
    name: canvasMatName,
    surfaceColor: "#ffffff",
    coreColors: whiteCore,
    presentation: "Canvas",
    thickness: 0,
    fake: true
  },
  {
    name: designersChoiceMatName,
    surfaceColor: "#ffffff",
    coreColors: whiteCore,
    presentation: "Designers Choice",
    thickness: 0,
    fake: true,
    captionCategory: "light"
  },
  {
    name: acrylicMatName,
    surfaceColor: false,
    coreColors: whiteCore,
    presentation: "Clear Float",
    thickness: 0,
    fake: true
  },
  {
    name: readymadeBrassMatName,
    surfaceColor: false,
    coreColors: whiteCore,
    presentation: "Readymade Brass",
    thickness: 0,
    fake: true
  },
  {
    name: readymadeBlackenedBrassMatName,
    surfaceColor: false,
    coreColors: whiteCore,
    presentation: "Readymade Blackened Brass",
    thickness: 0,
    fake: true
  },
  {
    name: jerseyMatName,
    surfaceColor: "#ffffff",
    coreColors: whiteCore,
    presentation: "Jersey",
    thickness: 0,
    fake: true
  },
  {
    name: "WH02-5",
    presentation: "White Mat Oversized",
    surfaceColor: "#fcfcfc",
    coreColors: whiteCore,
    thickness: defaultMatThickness,
    internalOnly: true,
    oversized: true,
    captionCategory: "light"
  },
  {
    name: "SRM3567O",
    presentation: "Fabric White Oversized",
    surfaceColor: "#fffefb",
    coreColors: whiteCore,
    thickness: defaultMatThickness,
    internalOnly: true,
    oversized: true,
    captionCategory: "light"
  },
  {
    name: "E4824O",
    presentation: "Meringue Oversized",
    surfaceColor: "#fafdff",
    coreColors: whiteCore,
    thickness: defaultMatThickness,
    internalOnly: true,
    oversized: true,
    captionCategory: "light"
  },
  {
    name: "E4128O",
    presentation: "Ice Cream Oversized",
    surfaceColor: "#fffef5",
    coreColors: whiteCore,
    thickness: defaultMatThickness,
    internalOnly: true,
    oversized: true,
    captionCategory: "light"
  },
  {
    name: "BW8701O",
    presentation: "Digital White Oversized",
    surfaceColor: "#fffffd",
    coreColors: whiteCore,
    thickness: defaultMatThickness,
    internalOnly: true,
    oversized: true,
    captionCategory: "light"
  },
  {
    name: "BW404O",
    presentation: "Sugar Oversized",
    surfaceColor: "#fcfcfb",
    coreColors: whiteCore,
    thickness: defaultMatThickness,
    internalOnly: true,
    oversized: true,
    captionCategory: "light"
  },
  {
    name: "BW222O",
    presentation: "Dove White Oversized",
    surfaceColor: "#fffdff",
    coreColors: whiteCore,
    thickness: defaultMatThickness,
    internalOnly: true,
    oversized: true,
    captionCategory: "light"
  },
  {
    name: "9902O",
    presentation: "White with Black Core Oversized",
    surfaceColor: "#fffefb",
    coreColors: blackCore,
    biColorSwatch: true,
    thickness: defaultMatThickness,
    internalOnly: true,
    oversized: true,
    captionCategory: "light"
  },
  {
    name: "229508",
    presentation: "Off-White - 8-ply",
    style: "8-ply",
    surfaceColor: "#fcfae7",
    coreColors: whiteCore,
    thickness: defaultMatThickness * 2,
    oversized: false,
    captionCategory: "light"
  },
  {
    name: "229613",
    presentation: "Warm White - 8-ply",
    style: "8-ply",
    surfaceColor: "#fefdfb",
    coreColors: whiteCore,
    thickness: defaultMatThickness * 2,
    oversized: false,
    captionCategory: "light"
  },
  {
    name: "229502",
    presentation: "Cool White - 8-ply",
    style: "8-ply",
    surfaceColor: "#fefefe",
    coreColors: whiteCore,
    thickness: defaultMatThickness * 2,
    oversized: false,
    captionCategory: "light"
  },
  {
    name: "5632",
    presentation: "Cream - Linen",
    style: "linen",
    thickness: defaultMatThickness,
    coreColors: whiteCore,
    surfaceColor: false,
    disabled: false,
    surfaceTexture: {
      url: "https://cdn.framebridge.com/production/page_addition_assets/357/assets/original/LinenWhite-contrast.jpg",
      widthInInches: 2,
      heightInInches: 2
    }
  },
  {
    name: "5558",
    presentation: "Honey - Linen",
    style: "linen",
    thickness: defaultMatThickness,
    coreColors: whiteCore,
    surfaceColor: false,
    surfaceTexture: {
      url: "https://cdn.framebridge.com/assets/texture/LinenYellow-6f9c34b6ac60f49c7e5f3cf1e40716ed.jpg",
      widthInInches: 2,
      heightInInches: 2
    }
  },
  {
    name: "5560",
    presentation: "Black - Linen",
    style: "linen",
    thickness: defaultMatThickness,
    coreColors: whiteCore,
    surfaceColor: false,
    surfaceTexture: {
      url: "https://cdn.framebridge.com/assets/texture/LinenBlack-d2b4134448743f5b8422a143fae3060c.jpg",
      widthInInches: 2,
      heightInInches: 2
    }
  },
  {
    name: "5559",
    presentation: "Taupe - Linen",
    style: "linen",
    thickness: defaultMatThickness,
    coreColors: whiteCore,
    surfaceColor: false,
    surfaceTexture: {
      url: "https://cdn.framebridge.com/assets/texture/LinenTan-840a58ce2b48c812e1412b3082855f6d.jpg",
      widthInInches: 2,
      heightInInches: 2
    }
  },
  {
    name: "5561",
    presentation: "Camel - Linen",
    style: "linen",
    thickness: defaultMatThickness,
    coreColors: whiteCore,
    surfaceColor: false,
    surfaceTexture: {
      url: "https://cdn.framebridge.com/assets/texture/LinenBrown-48391a26ba154031c1715dacac58a573.jpg",
      widthInInches: 2,
      heightInInches: 2
    }
  },
  {
    name: "11-075",
    presentation: "Sage - Linen",
    style: "linen",
    thickness: defaultMatThickness,
    coreColors: whiteCore,
    surfaceColor: false,
    surfaceTexture: {
      url: "https://cdn.shopify.com/s/files/1/0769/3602/0283/files/LINEN-TEST-4B.jpg?v=1740155163",
      widthInInches: 2,
      heightInInches: 2
    }
  },
  {
    name: "11-076",
    presentation: "Navy - Linen",
    style: "linen",
    thickness: defaultMatThickness,
    coreColors: whiteCore,
    surfaceColor: false,
    surfaceTexture: {
      url: "https://cdn.shopify.com/s/files/1/0769/3602/0283/files/LINEN-TEST-4A.jpg?v=1740155162",
      widthInInches: 2,
      heightInInches: 2
    }
  },
  {
    name: "11-077",
    presentation: "Sage Green - Silk",
    style: "silk",
    thickness: defaultMatThickness,
    coreColors: whiteCore,
    surfaceColor: false,
    surfaceTexture: {
      url: "https://cdn.shopify.com/s/files/1/0769/3602/0283/files/sage-silk-2.jpg?v=1740439469",
      widthInInches: 48,
      heightInInches: 48,
      alignment: "center"
    }
  },
  {
    name: "11-078",
    presentation: "Pale Green - Silk",
    style: "silk",
    thickness: defaultMatThickness,
    coreColors: whiteCore,
    surfaceColor: false,
    surfaceTexture: {
      url: "https://cdn.shopify.com/s/files/1/0769/3602/0283/files/green-silk.jpg?v=1740426427",
      widthInInches: 48,
      heightInInches: 48,
      alignment: "center"
    }
  },
  {
    name: "11-079",
    presentation: "Warm Blue - Silk",
    style: "silk",
    thickness: defaultMatThickness,
    coreColors: whiteCore,
    surfaceColor: false,
    surfaceTexture: {
      url: "https://cdn.shopify.com/s/files/1/0769/3602/0283/files/silk-blue-2.jpg?v=1740439077",
      widthInInches: 48,
      heightInInches: 48,
      alignment: "center"
    }
  },
  {
    name: "11-080",
    presentation: "Chai - Silk",
    style: "silk",
    thickness: defaultMatThickness,
    coreColors: whiteCore,
    surfaceColor: false,
    surfaceTexture: {
      url: "https://cdn.shopify.com/s/files/1/0769/3602/0283/files/chai-silk.jpg?v=1740421347",
      widthInInches: 48,
      heightInInches: 48,
      alignment: "center"
    }
  },
  {
    name: "11-064",
    presentation: "Metallic Silver",
    thickness: defaultMatThickness,
    coreColors: whiteCore,
    surfaceColor: false,
    surfaceTexture: {
      url: "https://cdn.shopify.com/s/files/1/0769/3602/0283/files/metallic-mat.jpg?v=1740414628",
      widthInInches: 48,
      heightInInches: 48,
      alignment: "center"
    },
    baseMat: true
  },
  {
    name: "11-063",
    surfaceColor: "#75735c",
    coreColors: ["#444335", "#4F4E3D", "#696751"],
    presentation: "Fern - 6-Ply",
    style: "8-ply",
    thickness: defaultMatThickness * 1.5,
    captionCategory: "dark",
    baseMat: true
  },
  {
    name: "11-065",
    surfaceColor: "#4b3a32",
    coreColors: ["#120D0B", "#2E241F", "#4B3A32"],
    presentation: "Chocolate",
    thickness: defaultMatThickness,
    captionCategory: "dark",
    baseMat: true
  },
  {
    name: "11-066",
    surfaceColor: "#908C13",
    coreColors: whiteCore,
    presentation: "Pickle",
    thickness: defaultMatThickness,
    captionCategory: "dark",
    baseMat: true
  },
  {
    name: "11-067",
    surfaceColor: "#aaa4ad",
    coreColors: whiteCore,
    presentation: "Lavender",
    thickness: defaultMatThickness,
    captionCategory: "light",
    baseMat: true
  },
  {
    name: "11-068",
    surfaceColor: "#d89178",
    coreColors: whiteCore,
    presentation: "Sushi",
    thickness: defaultMatThickness,
    captionCategory: "light",
    baseMat: true
  },
  {
    name: "11-069",
    surfaceColor: "#b9b995",
    coreColors: whiteCore,
    presentation: "Sage",
    thickness: defaultMatThickness,
    captionCategory: "light",
    baseMat: true
  },
  {
    name: "11-070",
    surfaceColor: "#96502f",
    coreColors: whiteCore,
    presentation: "Russet",
    thickness: defaultMatThickness,
    captionCategory: "dark",
    baseMat: true
  },
  {
    name: "11-071",
    surfaceColor: "#6c6e60",
    coreColors: whiteCore,
    presentation: "Dark Olive",
    thickness: defaultMatThickness,
    captionCategory: "dark",
    baseMat: true
  },
  {
    name: "11-072",
    surfaceColor: "#7ca77e",
    coreColors: whiteCore,
    presentation: "Sea Green",
    thickness: defaultMatThickness,
    captionCategory: "dark",
    baseMat: true
  },
  {
    name: "11-073",
    surfaceColor: "#738f9a",
    coreColors: whiteCore,
    presentation: "Dusty Blue",
    thickness: defaultMatThickness,
    captionCategory: "light",
    baseMat: true
  },
  {
    name: "11-074",
    surfaceColor: "#697d88",
    coreColors: whiteCore,
    presentation: "Denim",
    thickness: defaultMatThickness,
    captionCategory: "dark",
    baseMat: true
  },
  {
    name: "FL01",
    presentation: "Blue Floral Mat",
    style: "pattern",
    thickness: defaultMatThickness,
    coreColors: whiteCore,
    surfaceColor: false,
    internalOnly: true,
    disabled: true,
    surfaceTexture: {
      url: "https://cdn.framebridge.com/production/page_addition_assets/395/assets/original/blue-floral.jpg",
      widthInInches: 7.89,
      heightInInches: 8.75
    }
  },
  {
    name: "FL02",
    presentation: "Pink Floral Mat",
    style: "pattern",
    thickness: defaultMatThickness,
    coreColors: whiteCore,
    surfaceColor: false,
    internalOnly: true,
    disabled: true,
    surfaceTexture: {
      url: "https://cdn.framebridge.com/production/page_addition_assets/396/assets/original/pink-floral.jpg",
      widthInInches: 4.757,
      heightInInches: 8.577
    }
  },
  {
    name: "CSLB",
    presentation: "Light Blue Cabana Stripe",
    style: "pattern",
    thickness: defaultMatThickness,
    coreColors: whiteCore,
    surfaceColor: false,
    internalOnly: false,
    disabled: false,
    surfaceTexture: {
      url: "https://cdn.shopify.com/s/files/1/0769/3602/0283/files/Light_Blue_Cabana_Stripe.jpg?v=1718737279",
      widthInInches: 96,
      heightInInches: 48,
      alignment: "center"
    }
  },
  {
    name: "CSRB",
    presentation: "Royal Blue Cabana Stripe",
    style: "pattern",
    thickness: defaultMatThickness,
    coreColors: whiteCore,
    surfaceColor: false,
    internalOnly: false,
    disabled: false,
    surfaceTexture: {
      url: "https://cdn.shopify.com/s/files/1/0769/3602/0283/files/Royal_Blue_Cabana_Stripe.jpg?v=1718739651",
      widthInInches: 96,
      heightInInches: 48,
      alignment: "center"
    }
  },
  {
    name: "CSST",
    presentation: "Stone Cabana Stripe",
    style: "pattern",
    thickness: defaultMatThickness,
    coreColors: whiteCore,
    surfaceColor: false,
    internalOnly: false,
    disabled: false,
    surfaceTexture: {
      url: "https://cdn.shopify.com/s/files/1/0769/3602/0283/files/Stone_Cabana_Stripe.jpg?v=1718739547",
      widthInInches: 96,
      heightInInches: 48,
      alignment: "center"
    }
  },
  {
    name: "MSNV",
    presentation: "Navy Mini Stripe",
    style: "pattern",
    thickness: defaultMatThickness,
    coreColors: whiteCore,
    surfaceColor: false,
    internalOnly: false,
    disabled: false,
    surfaceTexture: {
      url: "https://cdn.shopify.com/s/files/1/0769/3602/0283/files/Navy_Mini_Stripe.jpg?v=1718737184",
      widthInInches: 96,
      heightInInches: 48,
      alignment: "center"
    }
  },
  {
    name: "MSFR",
    presentation: "Forest Mini Stripe",
    style: "pattern",
    thickness: defaultMatThickness,
    coreColors: whiteCore,
    surfaceColor: false,
    internalOnly: false,
    disabled: false,
    surfaceTexture: {
      url: "https://cdn.shopify.com/s/files/1/0769/3602/0283/files/Forest_Mini_Stripe.jpg?v=1718739547",
      widthInInches: 96,
      heightInInches: 48,
      alignment: "center"
    }
  },
  {
    name: "MSBG",
    presentation: "Burgundy Mini Stripe",
    style: "pattern",
    thickness: defaultMatThickness,
    coreColors: whiteCore,
    surfaceColor: false,
    internalOnly: false,
    disabled: false,
    surfaceTexture: {
      url: "https://cdn.shopify.com/s/files/1/0769/3602/0283/files/Burgundy_Mini_Stripe.jpg?v=1718739547",
      widthInInches: 96,
      heightInInches: 48,
      alignment: "center"
    }
  },
  {
    name: "CSLB-H",
    presentation: "Light Blue Cabana Stripe - Horizontal",
    style: "pattern",
    thickness: defaultMatThickness,
    coreColors: whiteCore,
    surfaceColor: false,
    internalOnly: false,
    disabled: false,
    surfaceTexture: {
      url: "https://cdn.shopify.com/s/files/1/0769/3602/0283/files/Light_Blue_Cabana_Stripe-H.jpg?v=1719418271",
      widthInInches: 48,
      heightInInches: 96,
      alignment: "center"
    }
  },
  {
    name: "CSRB-H",
    presentation: "Royal Blue Cabana Stripe - Horizontal",
    style: "pattern",
    thickness: defaultMatThickness,
    coreColors: whiteCore,
    surfaceColor: false,
    internalOnly: false,
    disabled: false,
    surfaceTexture: {
      url: "https://cdn.shopify.com/s/files/1/0769/3602/0283/files/Royal_Cabana_Stripe-H.jpg?v=1719418401",
      widthInInches: 48,
      heightInInches: 96,
      alignment: "center"
    }
  },
  {
    name: "CSST-H",
    presentation: "Stone Cabana Stripe - Horizontal",
    style: "pattern",
    thickness: defaultMatThickness,
    coreColors: whiteCore,
    surfaceColor: false,
    internalOnly: false,
    disabled: false,
    surfaceTexture: {
      url: "https://cdn.shopify.com/s/files/1/0769/3602/0283/files/Stone_Cabana_StripeH.jpg?v=1719418270",
      widthInInches: 48,
      heightInInches: 96,
      alignment: "center"
    }
  },
  {
    name: "MSNV-H",
    presentation: "Navy Mini Stripe - Horizontal",
    style: "pattern",
    thickness: defaultMatThickness,
    coreColors: whiteCore,
    surfaceColor: false,
    internalOnly: false,
    disabled: false,
    surfaceTexture: {
      url: "https://cdn.shopify.com/s/files/1/0769/3602/0283/files/Navy_Mini_Stripe-PSH.jpg?v=1719418938",
      widthInInches: 48,
      heightInInches: 96,
      alignment: "center"
    }
  },
  {
    name: "MSFR-H",
    presentation: "Forest Mini Stripe - Horizontal",
    style: "pattern",
    thickness: defaultMatThickness,
    coreColors: whiteCore,
    surfaceColor: false,
    internalOnly: false,
    disabled: false,
    surfaceTexture: {
      url: "https://cdn.shopify.com/s/files/1/0769/3602/0283/files/Forest_Mini_Stripe-PSH.jpg?v=1719418938",
      widthInInches: 48,
      heightInInches: 96,
      alignment: "center"
    }
  },
  {
    name: "MSBG-H",
    presentation: "Burgundy Mini Stripe - Horizontal",
    style: "pattern",
    thickness: defaultMatThickness,
    coreColors: whiteCore,
    surfaceColor: false,
    internalOnly: false,
    disabled: false,
    surfaceTexture: {
      url: "https://cdn.shopify.com/s/files/1/0769/3602/0283/files/Burgundy_Mini_Stripe-PSH.jpg?v=1719418938",
      widthInInches: 48,
      heightInInches: 96,
      alignment: "center"
    }
  },
  {
    name: accentMatName,
    surfaceColor: "#ffffff",
    coreColors: whiteCore,
    presentation: "Accent Mat",
    thickness: defaultMatThickness,
    fake: true
  },
  {
    name: "E4692P",
    surfaceColor: "#f6c453",
    coreColors: whiteCore,
    presentation: "Marigold",
    thickness: defaultMatThickness,
    captionCategory: "light",
    baseMat: true
  },
  {
    name: "E4836P",
    surfaceColor: "#1e4387",
    coreColors: whiteCore,
    presentation: "Royal Blue",
    thickness: defaultMatThickness,
    captionCategory: "dark",
    baseMat: true
  },
  {
    name: "E4713P",
    surfaceColor: "#ba2930",
    coreColors: whiteCore,
    presentation: "Red",
    thickness: defaultMatThickness,
    captionCategory: "dark",
    baseMat: true
  },
  {
    name: "BW120",
    surfaceColor: "#6d65a1",
    coreColors: whiteCore,
    presentation: "Purple",
    thickness: defaultMatThickness,
    captionCategory: "dark",
    baseMat: true
  },
  {
    name: "E4586P",
    surfaceColor: "#374a5b",
    coreColors: whiteCore,
    presentation: "Navy",
    thickness: defaultMatThickness,
    captionCategory: "dark",
    baseMat: true
  },
  {
    name: "E4819P",
    surfaceColor: "#74a8d7",
    coreColors: whiteCore,
    presentation: "Sky Blue",
    thickness: defaultMatThickness,
    captionCategory: "light",
    baseMat: true
  },
  {
    name: "E4213P",
    surfaceColor: "#c09b57",
    coreColors: whiteCore,
    presentation: "Metallic Gold",
    thickness: defaultMatThickness,
    captionCategory: "light",
    baseMat: true
  },
  {
    name: "E4084",
    surfaceColor: "#4d674a",
    coreColors: whiteCore,
    presentation: "Forest Green",
    thickness: defaultMatThickness,
    internalOnly: false,
    captionCategory: "dark",
    baseMat: true
  },
  {
    name: "BW038",
    surfaceColor: "#7a3842",
    coreColors: whiteCore,
    presentation: "Burgundy",
    thickness: defaultMatThickness,
    captionCategory: "dark",
    baseMat: true
  },
  {
    name: "11-056",
    surfaceColor: "#BF5700",
    coreColors: whiteCore,
    presentation: "Rust",
    thickness: defaultMatThickness,
    captionCategory: "dark",
    baseMat: true
  },
  {
    name: "11-055",
    surfaceColor: "#EC6A37",
    coreColors: whiteCore,
    presentation: "Mandarin",
    thickness: defaultMatThickness,
    captionCategory: "dark",
    baseMat: true
  },
  {
    name: "11-054",
    surfaceColor: "#9883CB",
    coreColors: whiteCore,
    presentation: "Violet",
    thickness: defaultMatThickness,
    captionCategory: "dark",
    baseMat: true
  }
];

export const matCaption = {
  fonts: {
    "apercu-monospace": {
      fontSize: 0.108,
      largeFontSize: 0.233,
      lineSpacing: 0.056,
      largeLineSpacing: 0.106,
      letterSpacing: 0.0025,
      largeLetterSpacing: 0.0025,
      fontColor: "#000000",
      darkMatFontColor: "#FFFFFF",
      strokeWidth: "0.002",
      fontWeight: 500
    },
    "cheltenham": {
      fontSize: 0.142,
      largeFontSize: 0.25,
      lineSpacing: 0.05,
      largeLineSpacing: 0.10,
      letterSpacing: 0.004,
      largeLetterSpacing: 0.004,
      fontColor: "#000000",
      darkMatFontColor: "#FFFFFF",
      strokeWidth: "0.002",
      fontWeight: 500
    },
    "apercu": {
      fontSize: 0.125,
      largeFontSize: 0.233,
      lineSpacing: 0.06,
      largeLineSpacing: 0.12,
      letterSpacing: 0.004,
      largeLetterSpacing: 0.004,
      fontColor: "#000000",
      darkMatFontColor: "#FFFFFF",
      strokeWidth: "0.002",
      fontWeight: 500
    },
    "bickham-script-pro-3": {
      fontSize: 0.122,
      largeFontSize: 0.483,
      lineSpacing: 0.06,
      largeLineSpacing: 0.12,
      letterSpacing: 0.004,
      largeLetterSpacing: 0.004,
      fontColor: "#000000",
      darkMatFontColor: "#FFFFFF",
      strokeWidth: "0.002",
      fontWeight: 500
    }
  },
  offsetDown: 0.5,
  maxWidth: 5,
  maxHeight: 1
};

export const monogramFoilColors = [
  {
    display: "gold",
    backgroundColor: "linear-gradient(to top, rgba(253,195,96,1) 0%, rgba(255,255,255,1) 100%)",
    colors: ["#fdc360", "#ffe7a0"]
  },
  {
    display: "silver",
    backgroundColor: "linear-gradient(to top, rgba(204,204,204,1) 0%, rgba(255,255,255,1) 100%)",
    colors: ["#cccccc", "#e2dede"]
  }
];

export const monogramFontStyles = [
  {
    display: "lucida",
    fontName: "Lucida_Bright"
  },
  {
    display: "franklin gothic",
    fontName: "Franklin-Gothic-Medium-Cond-Regular"
  }
];

export const defaultMoulding = "mercer-slim-black-frame";

export const mouldingPlate = {
  brassPlate: {
    url: "https://cdn.framebridge.com/production/page_addition_assets/354/assets/original/Moulding_Plate_Brass.png?1585171094",
    width: 2.36,
    height: 0.51
  },
  fontColor: "#534531",
  fontFamily: "Apercu-Monospace",
  fontSize: "0.1",
  letterSpacing: "0.002",
  lineSpacing: 0.02
};

export const displayableOptionTypes = [
  "mat",
  "size_group",
  "moulding",
  "fixed_arts_width",
  "fixed_arts_height"
];

export const floatMountMatReveal = 1.5;

// default amount of mat shown for accent mats -- suface and core
export const defaultAccentMatReveal = 0.1875;

// bowery
export const defaultMouldingTileUrl = "https://d2yue3r1g68039.cloudfront.net/staging/moulding_tiles/161/images/original/bowery.jpg";
export const defaultMouldingWidth = 2.25;
// cairo
//defaultMouldingTileUrl = 'https://cdn.framebridge.com/production/moulding_tiles/39/images/original/cairo.jpg';
//defaultMouldingWidth = 1.5;

export const defaultArtworkUrl = "https://cdn.framebridge.com/production/artwork/7a812d19565f609fb9b7baafcae84ab7e1b32b2b6936f4b1377cf05ff1f318a9182fc9b64f6627209a44a08ae968ce01e07b0c7e18d224ac0c80ba1447816ef6.jpg?1518732182";

export const defaultArtworkWidthInPixels = 1279;

export const defaultArtworkHeightInPixels = 853;

/**
 * Minimum value in any direction for the reveal of a top/primary mat
 * @static
 * @type {number}
 */
export const minimumMatReveal = 0.5;

export const maximumMatReveal = 12;

export const matRevealIncrement = 0.25;

export const uploadedArtworkMatOverlap = 0.25;

export const frameWidthIncrement = 1;

/**
 * Maximum size limit for the interior dimensions of a frame (often used
 * as the limit for artwork)
 * @static
 * @type {SizeGroupLimit}
 */
export const maxArtworkDimensions = [32, 40];
export const maxOversizedArtworkDimensions = [40, 60];

/**
 * Minimum size limit for the interior dimensions of a frame using
 * non-floating moulding. Often used as the limit for non-canvas/wood artwork.
 * @static
 * @type {SizeGroupLimit}
 */
export const minArtworkDimensions = [4, 4];

/**
 * Minimum size limit for the interior dimensions of a frame using floating
 * moulding. Often used as the limit for non-canvas/wood artwork.
 * @static
 * @type {SizeGroupLimit}
 */
export const minCanvasArtworkDimensions = [7, 7];

/**
 * Limits for artwork when using a floating moulding (usually for
 * non-canvas/wood artwork)
 * @static
 * @type {MinMaxLimit}
 */
export const artworkLimits = {
  min: 0.125,
  max: maxArtworkDimensions
};

/**
 * Limits for artwork when using a floating moulding (for canvas/wood
 * artwork)
 * @static
 * @type {MinMaxLimit}
 */
export const canvasArtworkLimits = {
  min: minCanvasArtworkDimensions,
  max: maxArtworkDimensions
};

/**
 * Minimum pixels per inch for printing non-instagram artwork
 * @static
 * @type {number}
 */
export const minimumPixelsPerInchForPrinting = 150;

/**
 * Minimum pixels per inch for printing instagram artwork
 * @static
 * @type {number}
 */
export const minimumPixelsPerInchForPrintingInstagram = 120;

/**
 * The minimum mat & artwork overlap when not float framing.
 * @static
 * @type {number}
 */
export const minimumArtworkMatOverlap = 0.125;

/**
 * Limits for the reveal of top/primary mats (not accent mats)
 * @static
 * @type {MinMaxLimit}
 */
export const matLimits = { min: 1, max: 20 };

/**
 * Limits for the reveal of accent mats (not top/primary mats)
 * @static
 * @type {MinMaxLimit}
 */
export const accentMatLimits = {
  min: defaultAccentMatReveal,
  max: 20
};

/**
 * Limits for the reveal of mounting around artwork
 * @static
 * @type {MinMaxLimit}
 */
export const mountLimits = {
  min: 0.25,
  max: 20
};

/**
 * Conveyance options for getting artwork to a studio, i.e. is it shipped or
 * uploaded
 * @static
 * @type {object}
 * @property {string} shipped - The string used to represent conveyance for
 * artwork that is shipped to the factory.
 * @property {string} uploaded - The string used to represent conveyance for
 * artwork that is uploaded and printed in the factory.
 */
export const conveyanceMethods = {
  shipped: "shipped", // DEPRECATED
  uploaded: "uploaded", // DEPRECATED
  mailin: "shipped", // DEPRECATED
  upload: "uploaded", // DEPRECATED

  physical: "shipped",
  digital: "uploaded"
};

/**
 * Medium options for getting artwork - this can effect the type of moulding as
 * floating moulding are only available for canvas/wood, though we occationally
 * also frame canvas & wood with a mat in a non-floating moulding.
 * @static
 * @type {object}
 * @property {string} paper - The string used to represent non-canvas/wood
 * artwork
 * @property {string} canvas - The string used to represent canvas/wood artwork
 */
export const mediums = {
  paper: "paper",
  canvas: "canvas"
};

/**
 * Limits for a height and width. This is most used for uploads as they have
 * specific limits based on resolution for width and height.
 * @typedef {Object} HangingHardwareTypeObject
 * @property {Object} value
 * @property {string} value.sku - The sku for the hardware item
 * @property {string} text - Display text
 * @property {string} [threshold] - The upper limit for a frame's internal width
 * after which hardware will not be used by default.
 */

/**
 * An object containing all the options for hanging hardware on a frame.
 * @static
 * @type {object}
 * @property {HangingHardwareTypeObject} default
 * @property {HangingHardwareTypeObject} sawtooth
 * @property {HangingHardwareTypeObject} wire
 * @property {HangingHardwareTypeObject} cornerBrackets
 * @property {HangingHardwareTypeObject} securityHangingHardware
 * @property {HangingHardwareTypeObject} noHangingHardware
 */
export const hangingHardware = {
  default: {
    value: { sku: null } ,
    text: "Default"
  },
  sawtooth: {
    value: { sku: "sawtooth" },
    text: "Sawtooth Mount",
    threshold: 11.875
  },
  wire: {
    value: { sku: "wire" },
    text: "Wire Mount",
    threshold: 31.875
  },
  cornerBrackets: {
    value: { sku: "corner_brackets" },
    text: "Corner Brackets",
    threshold: 999.875
  },
  securityHangingHardware: {
    value: { sku: "security_hanging_hardware" },
    text: "Security Hanging Hardware",
    threshold: 999.875
  },
  noHangingHardware: {
    value: { sku: "no_hanging_hardware" },
    text: "No Hanging Hardware",
    threshold: 999.875
  }
};

export const depthOptions = [
  {
    depth: 0.1,
    description: "No Depth"
  },
  {
    depth: 0.25,
    description: "1/4\" or less"
  },
  {
    depth: 0.5,
    description: "1/2\" or less"
  },
  {
    depth: 0.75,
    description: "3/4\" or less"
  },
  {
    depth: 1.5,
    description: "1 1/2\" or less"
  }
];

export default {
  sizeGroups,
  fixedArtProductTypes,
  whiteCore,
  blackCore,
  defaultMatThickness,
  noMatName,
  canvasMatName,
  whiteMatName,
  offWhiteMatName,
  whiteWithBlackCoreMatName,
  blackMatName,
  heartstagramMatName,
  theHorizontalDuet,
  theVerticalDuet,
  theHorizontalTrio,
  theVerticalTrio,
  theQuartet,
  theUnevenQuartet,
  thePhotoStrip,
  theTwoGenerationHorizontalImage,
  theTwoGenerationVerticalImage,
  theThreeGenerationHorizontalImage,
  theThreeGenerationVerticalImage,
  theTwelveGridImage,
  theThirteenGridImage,
  designersChoiceMatName,
  acrylicMatName,
  readymadeBrassMatName,
  readymadeBlackenedBrassMatName,
  floatMountMatName,
  accentMatName,
  jerseyMatName,
  framedGardenPortrait,
  framedGardenLandscape,
  cornerGardenPortrait,
  cornerGardenLandscape,
  base64MatOptions,
  mafMatOptions,
  smallMatOpenings,
  primaryMatColors,
  matCaption,
  monogramFoilColors,
  monogramFontStyles,
  defaultMoulding,
  mouldingPlate,
  displayableOptionTypes,
  floatMountMatReveal,
  defaultAccentMatReveal,
  defaultMouldingTileUrl,
  defaultMouldingWidth,
  defaultArtworkUrl,
  defaultArtworkWidthInPixels,
  defaultArtworkHeightInPixels,
  minimumMatReveal,
  maximumMatReveal,
  matRevealIncrement,
  uploadedArtworkMatOverlap,
  frameWidthIncrement,
  maxArtworkDimensions,
  minArtworkDimensions,
  minCanvasArtworkDimensions,
  artworkLimits,
  canvasArtworkLimits,
  minimumPixelsPerInchForPrinting,
  minimumPixelsPerInchForPrintingInstagram,
  minimumArtworkMatOverlap,
  matLimits,
  accentMatLimits,
  mountLimits,
  conveyanceMethods,
  mediums,
  hangingHardware,
  depthOptions,
  CONVEYANCE_PHYSICAL,
  CONVEYANCE_DIGITAL,
  CONVEYANCE_SHIPPED,
  CONVEYANCE_UPLOADED,
  CONVEYANCE_MAILIN,
  CONVEYANCE_UPLOAD,
  PHYSICAL_CONVEYANCES,
  DIGITAL_CONVEYANCES,
  ALL_CONVEYANCES
};
