import noop from "lodash/noop";
import isNil from "lodash/isNil";

// Ducked-typed interface that is a subset of
// what toolbox cares about wrt to Sentry.
//
// This exist only in the case that window doesn't exist (test env)
// or it just isn't set.
export const ErrorReporterFake = {
  captureMessage: () => noop
};

export const setErrorReporter = errorReporter => {
  if (
    typeof window !== "undefined" &&
    !isNil(window) &&
    isNil(window.ErrorReporter)
  ) {
    window.ErrorReporter = errorReporter;
  }
};

export const getErrorReporter = () => {
  if (typeof window !== "undefined" && !isNil(window.ErrorReporter)) {
    return window.ErrorReporter;
  } else {
    return ErrorReporterFake;
  }
};

export default {
  ErrorReporterFake,
  setErrorReporter,
  getErrorReporter
};
