import generalUtilities from "./generalUtilities";
import svgUtilities from "./svgUtilities";

export const setActiveFrame = function(
  galleryWallEl,
  frameKey,
  artworkKey,
  activeArtworkChange
) {
  const currentFrame = `g.frame[data-frame-key="${frameKey}"]`;
  const currentArtwork = `.artwork[data-artwork-key="${artworkKey}"]`;
  const combined = `${currentFrame} ${currentArtwork}`;
  const activeArtwork = ".artwork.active";

  // get all active art in this gallery wall
  const artworkEls = galleryWallEl.querySelectorAll(activeArtwork);

  // remove their active classes & set the aria attribute to not pressed
  Array.prototype.forEach.call(artworkEls, function(artworkEl) {
    svgUtilities.removeClasses(artworkEl, "active");
    svgUtilities.removeClasses(artworkEl.parentElement.parentElement, "active");
    artworkEl.setAttribute("aria-pressed", "false");
  });

  // get the selected artwork
  const activeArtworkEl = galleryWallEl.querySelector(combined);

  if (activeArtworkEl && activeArtworkEl.setAttribute) {
    // add an active class to this element & set aria attribute to pressed
    svgUtilities.addClasses(activeArtworkEl, "active");
    svgUtilities.addClasses(
      activeArtworkEl.parentElement.parentElement,
      "active"
    );
    activeArtworkEl.setAttribute("aria-pressed", "true");
    svgUtilities.addClasses(galleryWallEl, "has-active-child");
  } else {
    svgUtilities.removeClasses(galleryWallEl, "has-active-child");
  }

  // trigger the callback for active frame change
  if (typeof activeArtworkChange === "function") {
    activeArtworkChange(frameKey, artworkKey);
  }
};

export const createEventsForArtwork = function(
  artworkEl,
  frameEl,
  activeArtworkChange,
  setAsActiveMiddleware
) {
  const setAsActive = function(event) {
    const galleryWallEl = frameEl.parentElement;
    const frameKey = parseInt(frameEl.getAttribute("data-frame-key"));
    const artworkKey = parseInt(artworkEl.getAttribute("data-artwork-key"));

    if (isNaN(frameKey) || isNaN(artworkKey)) {
      throw ("Element does not have expected data attributes", event.target);
    }

    setActiveFrame(
      galleryWallEl,
      frameKey,
      artworkKey,
      activeArtworkChange
    );
  };
  if (!setAsActiveMiddleware) {
    setAsActiveMiddleware = (setAsActiveFn, event) => setAsActiveFn(event);
  }

  const setAsActiveOnEnter = function(event) {
    // if the key pressed is the enter key (13)
    if (event.keyCode === 13) {
      // set this art as active
      setAsActiveMiddleware(setAsActive, event);
    }
  };

  const focusCallback = function() {
    // watch for key presses
    artworkEl.addEventListener("keyup", setAsActiveOnEnter);
  };

  const blurCallback = function() {
    // remove watch for key presses
    artworkEl.removeEventListener("keyup", setAsActiveOnEnter);
  };

  const addEventsToChangeFocus = function(el, callback) {
    el.addEventListener("mousedown", function(event) {
      if (event.button === 0) {
        callback(event);
      }
    });
  };

  artworkEl.setAttribute("tabindex", 0);
  artworkEl.setAttribute("focusable", "true");
  artworkEl.setAttribute("role", "button");
  artworkEl.setAttribute("aria-pressed", "false");
  svgUtilities.addClasses(artworkEl, "focusable");

  // add callbacks on focus in and out/blur
  artworkEl.addEventListener("focusin", focusCallback);
  artworkEl.addEventListener("focusout", blurCallback);

  // add event handlers for click or touch
  if (frameEl.getAttribute("data-artworks-count") === "1") {
    // if this is the only art in this frame, the we should add
    // listeners to the entire frame for we have a larger tap/click area
    addEventsToChangeFocus(
      frameEl,
      (event) => setAsActiveMiddleware(setAsActive, event)
    );
  } else {
    // if this frame has more than one piece or art (multiple mat openings)
    // the user must click on the desired opening
    // to do make this work -- right now the click event is being picked up
    // by the mount element
    addEventsToChangeFocus(
      artworkEl,
      (event) => setAsActiveMiddleware(setAsActive, event)
    );
  }
};

export const isThisAnArtworkSpecificEl = function(el) {
  const selectors = [
    "g.artwork",
    "rect.artwork",
    "path.math-core",
    "g.frame[data-artwork-key]"
  ];

  return el && el.matches && generalUtilities.elMatchesOnOf(el, selectors);
};

export const isThisAFrameEl = function(el) {
  const selector = "g.frame";
  return el && el.matches && el.matches(selector);
};

export const findArtworkKey = function(el) {
  const artworkSpecificEl = generalUtilities.traverseParentNodesToFindMatch(
    el,
    isThisAnArtworkSpecificEl
  );
  if (artworkSpecificEl) {
    return parseInt(artworkSpecificEl.getAttribute("data-artwork-key"));
  }
};

export const findFrameKey = function(el) {
  const frameSpecificEl = generalUtilities.traverseParentNodesToFindMatch(
    el,
    isThisAFrameEl
  );
  if (frameSpecificEl) {
    return parseInt(frameSpecificEl.getAttribute("data-frame-key"));
  }
};

export default {
  setActiveFrame,
  createEventsForArtwork,
  isThisAnArtworkSpecificEl,
  isThisAFrameEl,
  findArtworkKey,
  findFrameKey
};
