export const getEditingLineItemNumber = () => {
  if (typeof window === "undefined" || !window.localStorage) {
    return;
  }

  const lineItemNumber = window.localStorage.getItem("editingLineItemNumber");
  if (lineItemNumber) {
    return lineItemNumber;
  }
};

export const setEditingLineItemNumber = (lineItemNumber) => {
  if (typeof window === "undefined") {
    return;
  }

  window.localStorage.setItem("editingLineItemNumber", lineItemNumber);
  return window.localStorage.getItem("editingLineItemNumber");
};

export const setEditingLineItemStartedAt = (editStartDate) => {
  if (typeof window === "undefined") {
    return;
  }

  window.localStorage.setItem("editingLineItemStartedAt", editStartDate);
  return window.localStorage.getItem("editingLineItemStartedAt");
};

export const getEditingLineItemStartedAt = () => {
  if (typeof window === "undefined") {
    return;
  }

  const editStartDate = window.localStorage.getItem("editingLineItemStartedAt");
  if (editStartDate) {
    return editStartDate;
  }
};

export const activeEditingOfLineItem = () => {
  const currentTime = new Date();
  const SIXTY_MINUTES = 60 * 60 * 1000;
  const lineItemNumber = getEditingLineItemNumber();
  const editStartDate = getEditingLineItemStartedAt();
  return (
    lineItemNumber &&
    currentTime - new Date(editStartDate) < SIXTY_MINUTES
  );
};

export const clearEditingLineItemNumber = () => {
  if (typeof window === "undefined") {
    return;
  }

  window.localStorage.removeItem("editingLineItemNumber");
};

export default {
  getEditingLineItemNumber,
  setEditingLineItemNumber,
  setEditingLineItemStartedAt,
  getEditingLineItemStartedAt,
  activeEditingOfLineItem,
  clearEditingLineItemNumber
};

