import hardCodedValues from "./hardCodedValues";
import { getErrorReporter } from "./telemetry/error-reporter-container";
import get from "lodash/get";

const ErrorReporter = getErrorReporter();

export const topMatSku = function(topMatSku, accentMatSku) {
  if (topMatSku === hardCodedValues.accentMatName) {
    if (accentMatSku === hardCodedValues.offWhiteMatName) {
      return hardCodedValues.offWhiteMatName;
    } else {
      return hardCodedValues.whiteMatName;
    }
  } else {
    return topMatSku || hardCodedValues.whiteMatName;
  }
};

export const getSizeGroups = function() {
  return hardCodedValues.sizeGroups.slice();
};

export const getPrimaryMatColors = function() {
  return hardCodedValues.primaryMatColors.slice();
};

export const findMatColor = function(matName) {
  const mat = getPrimaryMatColors().find(function(mat) {
    return mat.name === matName;
  });

  return mat;
};

export const determineSizeGroup = function(side1, side2, method) {
  const side1Rounded = roundNearest(side1, 1/8);
  const side2Rounded = roundNearest(side2, 1/8);

  let output = null;
  const sizeType = "exactsize";

  const exact = hardCodedValues.sizeGroups.filter(function(sizeGroup) {
    return sizeGroup.type === sizeType;
  });

  exact.forEach(function(sizeGroup) {
    const supportedSize = sizeGroup.conveyanceMethods.includes(method);

    if (!output && supportedSize && side1 === sizeGroup.width && side2 === sizeGroup.height) {
      output = sizeGroup;
    }
    if (!output && supportedSize && side2 === sizeGroup.width && side1 === sizeGroup.height) {
      output = sizeGroup;
    }
  });

  if (!output) {
    const notExact = hardCodedValues.sizeGroups.filter(function(sizeGroup) {
      return sizeGroup.type !== sizeType;
    });

    notExact.forEach(function(sizeGroup) {
      if (!output && side1Rounded <= sizeGroup.width && side2Rounded <= sizeGroup.height) {
        output = sizeGroup;
      }
      if (!output && side2Rounded <= sizeGroup.width && side1Rounded <= sizeGroup.height) {
        output = sizeGroup;
      }
    });
  }

  return output;
};

export const determineDefaultMatWidth = function(artSide1, artSide2) {
  const sizeGroup = determineSizeGroup(artSide1, artSide2);
  if (sizeGroup && typeof sizeGroup.matWidth === "number") {
    return sizeGroup.matWidth;
  }
  return 0;
};

export const sumArtworkDimensions = function(minimalistArtworksSpec) {
  const sum = {
    width: 0,
    height: 0
  };

  minimalistArtworksSpec.forEach(function(minimalistArtworkSpec) {
    sum.width += minimalistArtworkSpec.exterior.widthInInches;
    sum.height += minimalistArtworkSpec.exterior.heightInInches;
  });

  return sum;
};

export const determineSizeGroupForArtworks = function(
  minimalistArtworksSpec
) {
  const dimensions = sumArtworkDimensions(
    minimalistArtworksSpec
  );
  const sizeGroup = determineSizeGroup(
    dimensions.width,
    dimensions.height
  );
  return sizeGroup;
};

// make arrays of values from 4' to 41+'' for mail in size drop downs
export const mailInArtworkSizeOptions = function() {
  const selectOptions = [];
  const bigSideMax = Math.max.apply(null, hardCodedValues.maxArtworkDimensions);
  const minSize = Math.min.apply(null, hardCodedValues.minArtworkDimensions);
  const overSized = bigSideMax + 1;

  for (let iterator = minSize; iterator <= bigSideMax; iterator++) {
    const option = {
      name: iterator,
      presentation: `${iterator}"`
    };
    selectOptions.push(option);
    iterator++;
  }

  const option = {
    name: overSized,
    presentation: `${overSized}+"`
  };
  selectOptions.push(option);

  return selectOptions;
};

// make arrays of values from .5" to 12" in quarter inch increments
export const customMatSizes = function() {
  const sizes = [];
  let currentSize = hardCodedValues.minimumMatReveal;

  while (currentSize <= hardCodedValues.maximumMatReveal) {
    sizes.push(currentSize);
    currentSize += hardCodedValues.matRevealIncrement;
  }

  return sizes;
};

export const customMatOptions = function() {
  const selectOptions = [];
  const sizes = customMatSizes();
  sizes.forEach(function(size) {
    const option = {
      name: size,
      presentation: `${size.toFixed(2)}"`
    };

    selectOptions.push(option);
  });

  return selectOptions;
};

export const isBase64MatOption = function(mat) {
  const isBase64 = hardCodedValues.base64MatOptions.includes(mat);
  return isBase64;
};

export const isOversized = function(side1, side2) {
  const bigSideMax = Math.max.apply(null, hardCodedValues.maxArtworkDimensions);
  const smallSideMax = Math.min.apply(
    null,
    hardCodedValues.maxArtworkDimensions
  );
  const bigSideMaxString = (bigSideMax + 1) | "+";

  if (side1 === bigSideMaxString || side2 === bigSideMaxString) {
    return true;
  }

  if (side1 > smallSideMax && side2 > smallSideMax) {
    return true;
  }

  return false;
};

const determineMaxSizeForPixels = (dimensionsInPixels) => {
  const {height, width} = dimensionsInPixels;
  const aspectRatio = height / width;
  const maxWidthInInches = Math.floor(width / hardCodedValues.minimumPixelsPerInchForPrinting);
  const maxHeightInInches = Math.floor(maxWidthInInches * aspectRatio * 100) / 100;

  return {
      widthInInches: maxWidthInInches,
      heightInInches: maxHeightInInches
    };
};

export const uploadArtworkSizeOptions = function(
  widthInPixels,
  heightInPixels
) {

  const maxDimsForImage = determineMaxSizeForPixels({width: widthInPixels, height: heightInPixels});

  const ppiRule = (w,h) => {
    return w <= maxDimsForImage.widthInInches && h <= maxDimsForImage.heightInInches;
  };

  const selectOptions = [];
  const ratio = heightInPixels / widthInPixels;
  const max = Math.max.apply(null, hardCodedValues.maxArtworkDimensions);
  let iterator = Math.min.apply(null, hardCodedValues.minArtworkDimensions);

  while (iterator <= max) {
    const width = iterator;
    const height = iterator * ratio;
    let formattedHeight;
    formattedHeight = height.toFixed(2);
    if (formattedHeight.substr(-2) === "00") {
      formattedHeight = height.toFixed(0);
    } else if (formattedHeight.substr(-1) === "0") {
      formattedHeight = height.toFixed(1);
    }
    const presentation = `${width}" x ${formattedHeight}"`;
    const sizeGroup = determineSizeGroup(width, height);

    if (!isOversized(width, height) && ppiRule(width, height)) {
      const option = {
        widthInInches: width,
        heightInInches: height,
        presentation: presentation,
        sizeGroup: sizeGroup
      };
      selectOptions.push(option);
    }
    iterator++;
  }

  return selectOptions;
};

export const findVariant = function(
  variants,
  sizeGroup,
  possibleSelections,
  minimalistFrameSpec
) {
  const filteredResults = variants.filter(function(variant) {
    let match = true;

    for (const key in possibleSelections) {
      if (!Object.prototype.hasOwnProperty.call(possibleSelections, key)) {
        continue;
      }
      const optionTypeName = key;
      const optionTypeInfo = possibleSelections[key];

      // if this property has less than 2 options, always match
      if (optionTypeInfo.values.length > 2) {
        match = true;
        continue;
      }

      //intentionally falling thru in each case
      switch (optionTypeName) {
        case "mat":
          switch (variant.mat_name) {
            case hardCodedValues.floatMountMatName:
              if (minimalistFrameSpec.mount.elevated === false) {
                match = false;
              }
              if (minimalistFrameSpec.artworks[0].mount.elevated) {
                match = false;
              }
              break;
            case hardCodedValues.noMatName:
              if (minimalistFrameSpec.mats.length > 0) {
                match = false;
              }
              break;
            default:
              if (
                minimalistFrameSpec.mats.length === 0 ||
                minimalistFrameSpec.mats[0].name !== variant.mat_name
              ) {
                match = false;
              }
          }
          break;
        case "size_group":
          if (
            sizeGroup === null ||
            sizeGroup.presentation !== variant.size_group_presentation
          ) {
            match = false;
          }
          break;
        case "fixed_art_height":
          if (
            minimalistFrameSpec.artworks[0].exterior.height !==
            variant.fixed_art_height
          ) {
            match = false;
          }
          break;
        case "fixed_art_width":
          if (
            minimalistFrameSpec.artworks[0].exterior.width !==
            variant.fixed_art_width
          ) {
            match = false;
          }
          break;
        // moulding ?
      }
    }

    return match;
  });

  if (filteredResults.length > 0) {
    return filteredResults[0];
  } else {
    return null;
  }
};

export const getMatSurfaceColor = function(matName) {
  const mat = findMatColor(matName);
  if (mat) {
    return mat.surfaceColor;
  }
};

export const getMatSurfaceTexture = function(matName) {
  const mat = findMatColor(matName);
  if (mat) {
    return mat.surfaceTexture;
  }
};

export const getMatCoreColors = function(matName) {
  const mat = findMatColor(matName);
  if (mat) {
    return mat.coreColors;
  }
};

export const getMatThickness = function(matSku) {
  const mat = findMatColor(matSku);
  if (mat && typeof mat.thickness !== "undefined") {
    return mat.thickness;
  } else {
    ErrorReporter.captureMessage("No mat thickness defined for sku", {
      extra: {
        matSku: matSku
      }
    });
  }
};

export const calculateOuterFrameDimensions = function(
  artworkDimensions,
  reveal,
  mouldingWidth,
  hasMat
) {
  let finalWidth = Number(artworkDimensions.width);
  finalWidth += mouldingWidth * 2;

  let finalHeight = Number(artworkDimensions.height);
  finalHeight += mouldingWidth * 2;

  if (hasMat) {
    finalHeight += reveal.top;
    finalWidth += reveal.right;
    finalHeight += reveal.bottom;
    finalWidth += reveal.left;
  }

  return {
    height: finalHeight,
    width: finalWidth
  };
};

export const getMouldingInfo = function(permalink, spree) {
  const promise = spree.mouldings().then(function(response) {
    const mouldingInfo = response.find(function(moulding) {
      if (!moulding || !moulding.permalink) {
        return false;
      } else {
        return moulding.permalink === permalink;
      }
    });

    if (!mouldingInfo) {
      ErrorReporter.captureMessage(
        "Missing moulding information for moulding permalink",
        {
          extra: {
            mouldingPermalink: permalink
          }
        }
      );
    }

    return mouldingInfo;
  });
  return promise;
};

export const findUsableMouldingTile = function(tiles) {
  const usableTile = tiles.find(function(tile) {
    const noMat = tile.mat_sku === hardCodedValues.noMatName;
    const canvasMat = tile.mat_sku === hardCodedValues.canvasMatName;
    return noMat || canvasMat;
  });
  return usableTile;
};

export const getMouldingWidth = function(permalink, spree) {
  const promise = getMouldingInfo(permalink, spree)
    .then(function(moulding) {
      if (moulding && moulding.length_in_inches !== 0) {
        return parseFloat(moulding.length_in_inches);
      } else {
        ErrorReporter.captureMessage(
          "Don't have moulding width for permalink",
          {
            extra: {
              mouldingPermalink: permalink
            }
          }
        );
      }
    });
  return promise;
};

export const lookupMouldingWidthIfNotPresent = function(
  mouldingSpec,
  spree
) {
  let mouldingWidthPromise;
  if (mouldingSpec.mouldingWidth) {
    // if the moulding width was passed to us, let's use it
    mouldingWidthPromise = new Promise(function(resolve) {
      resolve(mouldingSpec.mouldingWidth);
    });
  } else {
    // otherwise look it up
    mouldingWidthPromise = getMouldingWidth(
      mouldingSpec.permalink,
      spree
    );
  }
  return mouldingWidthPromise;
};

export const getMouldingTileInfo = function(permalink, spree) {
  const promise = getMouldingInfo(permalink, spree)
    .then(function(moulding) {
      const usableTile = moulding && findUsableMouldingTile(
        moulding.tiles
      );
      if (!usableTile || !usableTile.image_url) {
        ErrorReporter.captureMessage(
          "No usable moulding tile for moulding permalink is unavailable",
          {
            extra: {
              mouldingPermalink: permalink
            }
          }
        );
        return undefined;
      }

      let width;
      let height;
      if (usableTile.image_metadata && usableTile.image_metadata.original) {
        width = usableTile.image_metadata.original.width;
        height = usableTile.image_metadata.original.height;
      }
      return [usableTile.image_url, width, height];
    });
  return promise;
};

export const lookupMouldingTileIfNotPresent = function(
  mouldingSpec,
  spree
) {
  let mouldingTilePromise;
  if (mouldingSpec.url) {
    // if the moulding tile was passed to us, let's use it
    mouldingTilePromise = new Promise(function(resolve) {
      resolve([
        mouldingSpec.url,
        mouldingSpec.widthInPixels,
        mouldingSpec.heightInPixels
      ]);
    });
  } else {
    // otherwise look it up
    mouldingTilePromise = getMouldingTileInfo(
      mouldingSpec.permalink,
      spree
    );
  }
  return mouldingTilePromise;
};

export const normalizedArtworkData = function(artwork) {
  const crop_geometry = artwork.crop_geometry || {};
  return {
    id: artwork.id,
    artworkId: artwork.id,
    imageFileName: artwork.image_file_name,
    token: artwork.token,
    square: {
      url: artwork.square_thumbnail_url
    },
    thumbnail: {
      url: artwork.thumbnail_url
    },
    croppedAndNormalized: {
      url: artwork.cropped_and_normalized_url,
      dimensionsInPixels: {
        width: get(artwork, ["cropped_and_normalized_dimensions", 0]),
        height: get(artwork, ["cropped_and_normalized_dimensions", 1])
      }
    },
    customerSpecified: {
      url: artwork.customer_specified_url,
      dimensionsInPixels: {
        width: get(artwork, ["customer_specified_dimensions", 0]),
        height: get(artwork, ["customer_specified_dimensions", 1])
      }
    },
    normalized: {
      url: artwork.normalized_url,
      dimensionsInPixels: {
        width: get(artwork, ["normalized_dimensions", 0]),
        height: get(artwork, ["normalized_dimensions", 1])
      }
    },
    original: {
      url: artwork.image_url,
      dimensionsInPixels: {
        width: get(artwork, ["image_dimensions", "0"]),
        height: get(artwork, ["image_dimensions", "1"])
      }
    },
    originalUnedited: {
      url: artwork.original_unedited_url,
      dimensionsInPixels: {
        width: get(artwork, ["original_unedited_dimensions", "0"]),
        height: get(artwork, ["original_unedited_dimensions", "1"])
      }
    },
    imageContentType: artwork.image_content_type,
    rotate: artwork.rotate,
    // crop_geometry is to determine if a crop has been applied server side
    crop_geometry: crop_geometry,
    mount: {}
  };
};

export const getUserEditsFromUploadData = function(artwork) {
  const userEdits = {
    x: artwork.crop_geometry.x_offset,
    y: artwork.crop_geometry.y_offset,
    width: artwork.crop_geometry.width,
    height: artwork.crop_geometry.height,
    rotate: artwork.rotate
  };

  return userEdits;
};

export const reformatLineItemUploadFromServer = function(serverUpload) {
  const reformatedUpload = {
    percent_complete: 100,
    state: "done",
    artwork: {
      crop_geometry: {
        crop_ui_size: {
          height: serverUpload.artwork.crop_geometry.crop_ui_size.height,
          width: serverUpload.artwork.crop_geometry.crop_ui_size.width
        },
        height: serverUpload.artwork.crop_geometry.height,
        width: serverUpload.artwork.crop_geometry.width,
        x_offset: serverUpload.artwork.crop_geometry.x_offset,
        y_offset: serverUpload.artwork.crop_geometry.y_offset
      },
      cropping: false,
      customer_specified: {
        url: serverUpload.artwork.customer_specified_url,
        dimensionsInPixels: {
          width: serverUpload.artwork.customer_specified_dimensions[0],
          height: serverUpload.artwork.customer_specified_dimensions[1]
        }
      },
      croppedAndNormalized: {
        url: serverUpload.artwork.cropped_and_normalized_url,
        dimensionsInPixels: {
          width: serverUpload.artwork.cropped_and_normalized_dimensions[0],
          height: serverUpload.artwork.cropped_and_normalized_dimensions[1]
        }
      },
      id: serverUpload.artwork.id,
      normalized: {
        url: serverUpload.artwork.normalized_url,
        dimensionsInPixels: {
          width: serverUpload.artwork.normalized_dimensions[0],
          height: serverUpload.artwork.normalized_dimensions[1]
        }
      },
      original: {
        url: serverUpload.artwork.image_remote_url,
        dimensionsInPixels: {
          width: serverUpload.artwork.image_dimensions[0],
          height: serverUpload.artwork.image_dimensions[1]
        }
      },
      rotate: serverUpload.artwork.rotate,
      square: {
        url: serverUpload.artwork.square_thumbnail_url
      },
      token: serverUpload.artwork.token,
      userEdits: getUserEditsFromUploadData(
        serverUpload.artwork
      )
    },
    inUseBy: {
      artworkIndex: serverUpload.in_use_by.opening_index,
      frameIndex: serverUpload.in_use_by.frame_index,
      productId: serverUpload.in_use_by.product_id,
      variantId: serverUpload.in_use_by.variant_id,
      assemblyPartId: serverUpload.in_use_by.assembly_part_id
    }
  };
  return reformatedUpload;
};

export const reformatLineItemUploadsFromServer = function(serverUploads) {
  const outputUploads = serverUploads.map(function(upload) {
    return reformatLineItemUploadFromServer(upload);
  });

  return outputUploads;
};

/**
 * Determines the maximum size for thisSide based on thatSide, and a limit
 * described by an array of two numbers, such as Framebridge size groups. For
 * example, if our upper limit for artwork is 32 x 40, we represent this as
 * [32, 40]. If this side is 30 & that side is 35, the maximum for this side
 * will be 32 because we can't have 2 sides that go over 32.
 * @function
 * @param {SizeGroupLimit} limits - The spot to put the new item in.
 * @param {number} thisSide - The current size of this side.
 * @param {number} thatSide - The current size of the other size.
 * @returns {number}
 */
export const maxSize = function(limits, thisSide, thatSide) {
  const smallSideLimit = Math.min(...limits);
  const largeSideLimit = Math.max(...limits);
  if (thatSide <= smallSideLimit) {
    return largeSideLimit;
  } else {
    // if that side is bigger than the larger limit, then this side must stay
    // under the smaller limit
    return smallSideLimit;
  }
};

/**
 * Determines the minimum size for thisSide based on thatSide, and a limit
 * described by an array of two numbers, such as Framebridge size groups. For
 * example, if our lower limit for artwork is 4 x 6, we represent this as
 * [4, 6]. If this side is 7 & that side is 5, the minimum for this side will be
 * 6 because we can't have 2 sides that go under 6.
 * @function
 * @param {SizeGroupLimit} limits - The spot to put the new item in.
 * @param {number} thisSide - The current size of this side.
 * @param {number} thatSide - The current size of the other size.
 * @returns {number}
 */
export const minSize = function(limits, thisSide, thatSide) {
  const smallSideLimit = Math.min(...limits);
  const largeSideLimit = Math.max(...limits);
  if (thatSide >= largeSideLimit) {
    return smallSideLimit;
  } else {
    // if that side is bigger than the larger limit, then this side must stay
    // under the smaller limit
    return largeSideLimit;
  }
};

/**
 * Determines the minimum size for thisSide based on thatSide, limits and
 * whether thisSide is the width or height. See documention on the Limits object
 * for examples.
 * @param {Limits} limits - The spot to put the new item in.
 * @param {number} thisSide - The current size of this side.
 * @param {number} thatSide - The current size of the other size.
 * @param {boolean} width - The value true if thisSide is the width, false if it
 * is the height.
 * @returns {number}
 */
export const evaluateLimitsForMinimum = function(
  limits,
  thisSide,
  thatSide,
  width
) {
  const defaultResult = 0;
  if (typeof limits === "number") {
    // if this is a number, that's our limit, we're done
    return limits;
  } else if (typeof limits === "object") {
    if (Array.isArray(limits)) {
      // if this is an array of 2 numbers then use minSize
      const numbers = limits.filter((limit) => typeof limit === "number");
      if (numbers.length === 2) {
        return minSize(limits, thisSide, thatSide);
      } else {
        // otherwise treat this array as an array of more limits, recurse & collect
        const evaluatedLimits = limits.map((limit) => {
          return evaluateLimitsForMinimum(
            limit,
            thisSide,
            thatSide,
            width
          );
        });
        if (evaluatedLimits.length) {
          // return the largest of them
          return Math.max(...evaluatedLimits);
        }
      }
    } else if (limits.min) {
      return evaluateLimitsForMinimum(
        limits.min,
        thisSide,
        thatSide,
        width
      );
    } else if (width && limits.width) {
      return evaluateLimitsForMinimum(
        limits.width,
        thisSide,
        thatSide,
        width
      );
    } else if (!width && limits.height) {
      return evaluateLimitsForMinimum(
        limits.height,
        thisSide,
        thatSide,
        width
      );
    }
  }

  return defaultResult;
};

/**
 * Determines the maximum size for thisSide based on thatSide, limits and
 * whether thisSide is the width or height. See documention on the Limits object
 * for examples.
 * @param {Limits} limits - The spot to put the new item in.
 * @param {number} thisSide - The current size of this side.
 * @param {number} thatSide - The current size of the other size.
 * @param {boolean} width - The value true if thisSide is the width, false if it
 * is the height.
 * @returns {number}
 */
export const evaluateLimitsForMaximum = function(
  limits,
  thisSide,
  thatSide,
  width
) {
  const defaultResult = 1000;

  if (typeof limits === "number") {
    // if this is a number, that's our limit, we're done
    return limits;
  } else if (typeof limits === "object") {
    if (Array.isArray(limits)) {
      // if this is an array of 2 numbers then use minSize
      const numbers = limits.filter((limit) => typeof limit === "number");
      if (numbers.length === 2 && limits.length === 2) {
        return maxSize(limits, thisSide, thatSide);
      } else {
        // otherwise treat this array as an array of more limits, recurse & collect
        const evaluatedLimits = limits.map((limit) => {
          return evaluateLimitsForMaximum(
            limit,
            thisSide,
            thatSide,
            width
          );
        });

        if (evaluatedLimits.length) {
          // return the smallest of them
          return Math.min(...evaluatedLimits);
        }
      }
    } else if (limits.max) {
      return evaluateLimitsForMaximum(
        limits.max,
        thisSide,
        thatSide,
        width
      );
    } else if (width && limits.width) {
      return evaluateLimitsForMaximum(
        limits.width,
        thisSide,
        thatSide,
        width
      );
    } else if (!width && limits.height) {
      return evaluateLimitsForMaximum(
        limits.height,
        thisSide,
        thatSide,
        width
      );
    }
  }

  // a big number that wouldn't come up naturally, as to not impose a
  // meaningful limit
  return defaultResult;
};

export const roundUp = function(value, increment) {
  return Math.ceil(value / increment) * increment;
};

export const roundDown = function(value, increment) {
  return Math.floor(value / increment) * increment;
};

export const roundNearest = function(value, increment) {
  return Math.round(value / increment) * increment;
};

export const roundTowardsZero = function(value, increment) {
  if (value > 0) {
    return roundDown(value, increment);
  } else {
    return roundUp(value, increment);
  }
};

export const closeTo = function(num1, num2, delta) {
  if (typeof delta === "undefined") {
    delta = 0.00001;
  }
  return Math.abs(num1 - num2) <= delta;
};

export const doAspectRatiosMatch = function(
  dimensionsA,
  dimensionsB,
  bidirectionalCheck
) {
  let dimensionA1 = dimensionsA.pop();
  let dimensionA2 = dimensionsA.pop();
  let dimensionB1 = dimensionsB.pop();
  let dimensionB2 = dimensionsB.pop();

  if (!dimensionA1 || !dimensionA2 || !dimensionB1 || !dimensionB2) {
    return false;
  }

  dimensionA1 = Number(dimensionA1);
  dimensionA2 = Number(dimensionA2);
  dimensionB1 = Number(dimensionB1);
  dimensionB2 = Number(dimensionB2);

  const dimensionARatio1 = dimensionA1 / dimensionA2;
  const dimensionARatio2 = dimensionA2 / dimensionA1;
  const dimensionBRatio1 = dimensionB1 / dimensionB2;

  const ratio1Matches = closeTo(
    dimensionARatio1,
    dimensionBRatio1
  );
  const ratio2Matches = closeTo(
    dimensionARatio2,
    dimensionBRatio1
  );

  if (bidirectionalCheck) {
    return ratio1Matches || ratio2Matches;
  } else {
    return ratio1Matches;
  }
};

export const determineMinSizeForSizeGroup = function (dimensionsInPixels, args) {
  const limitSizeGroup = getSizeGroups().find((group) => {
    return group.name == args.sizeGroup;
  });
  const validDimensionOptions =
    uploadArtworkSizeOptions(...Object.values(dimensionsInPixels))
    .filter((sizeOption) => {
      return sizeOption.sizeGroup && sizeOption.sizeGroup.sequence <= limitSizeGroup.sequence;
    });

  // Attempt to find the first of the limiting size group, otherwise falling back to the last option
  const firstLimitingSizeGroup = validDimensionOptions.find((sizeOption) => {
    return sizeOption.sizeGroup.name == limitSizeGroup.name;
  });
  const lastOption = validDimensionOptions[validDimensionOptions.length - 1];

  return firstLimitingSizeGroup || lastOption;
};

export const determineSizeDefaults = function (dimensionsInPixels, args) {
  let sizeDefault = "max";
  if (args) {
    sizeDefault = args.sizeDefault || "max";
  }
  if (sizeDefault === "max") {
    return determineMinSizeForSizeGroup(dimensionsInPixels, {sizeDefault: "minForSizeGroup", sizeGroup: "GR"});
  } else if (sizeDefault === "minForSizeGroup") {
    return determineMinSizeForSizeGroup(dimensionsInPixels, args);
  }
};

export default {
  topMatSku,
  getSizeGroups,
  getPrimaryMatColors,
  findMatColor,
  determineSizeGroup,
  determineDefaultMatWidth,
  sumArtworkDimensions,
  determineSizeGroupForArtworks,
  mailInArtworkSizeOptions,
  customMatSizes,
  customMatOptions,
  isBase64MatOption,
  isOversized,
  uploadArtworkSizeOptions,
  findVariant,
  getMatSurfaceColor,
  getMatSurfaceTexture,
  getMatCoreColors,
  getMatThickness,
  calculateOuterFrameDimensions,
  getMouldingInfo,
  findUsableMouldingTile,
  getMouldingWidth,
  lookupMouldingWidthIfNotPresent,
  getMouldingTileInfo,
  lookupMouldingTileIfNotPresent,
  normalizedArtworkData,
  getUserEditsFromUploadData,
  reformatLineItemUploadFromServer,
  reformatLineItemUploadsFromServer,
  maxSize,
  minSize,
  evaluateLimitsForMinimum,
  evaluateLimitsForMaximum,
  roundUp,
  roundDown,
  roundNearest,
  roundTowardsZero,
  closeTo,
  doAspectRatiosMatch,
  determineMinSizeForSizeGroup,
  determineSizeDefaults
};
