export const sizes = {
  regular: {
    "INSTAGRAM": {
      standard: {
        topInInches: 2.5,
        rightInInches: 2.5,
        bottomInInches: 2.5,
        leftInInches: 2.5
      }
    },
    "XS": {
      standard: {
        topInInches: 2.5,
        rightInInches: 2.5,
        bottomInInches: 2.5,
        leftInInches: 2.5
      },
      thin: {
        topInInches: 1.5,
        rightInInches: 1.5,
        bottomInInches: 1.5,
        leftInInches: 1.5
      },
      wide: {
        topInInches: 4,
        rightInInches: 4,
        bottomInInches: 4,
        leftInInches: 4
      },
      weighted: {
        topInInches: 2.5,
        rightInInches: 2.5,
        bottomInInches: 4.5,
        leftInInches: 2.5
      }
    },
    "S": {
      standard: {
        topInInches: 2.5,
        rightInInches: 2.5,
        bottomInInches: 2.5,
        leftInInches: 2.5
      },
      thin: {
        topInInches: 1.5,
        rightInInches: 1.5,
        bottomInInches: 1.5,
        leftInInches: 1.5
      },
      wide: {
        topInInches: 4,
        rightInInches: 4,
        bottomInInches: 4,
        leftInInches: 4
      },
      weighted: {
        topInInches: 2.5,
        rightInInches: 2.5,
        bottomInInches: 4.5,
        leftInInches: 2.5
      }
    },
    "M": {
      standard: {
        topInInches: 3,
        rightInInches: 3,
        bottomInInches: 3,
        leftInInches: 3
      },
      thin: {
        topInInches: 1.5,
        rightInInches: 1.5,
        bottomInInches: 1.5,
        leftInInches: 1.5
      },
      wide: {
        topInInches: 5,
        rightInInches: 5,
        bottomInInches: 5,
        leftInInches: 5
      },
      weighted: {
        topInInches: 3,
        rightInInches: 3,
        bottomInInches: 5,
        leftInInches: 3
      }
    },
    "L": {
      standard: {
        topInInches: 3,
        rightInInches: 3,
        bottomInInches: 3,
        leftInInches: 3
      },
      thin: {
        topInInches: 1.5,
        rightInInches: 1.5,
        bottomInInches: 1.5,
        leftInInches: 1.5
      },
      wide: {
        topInInches: 5,
        rightInInches: 5,
        bottomInInches: 5,
        leftInInches: 5
      },
      weighted: {
        topInInches: 3,
        rightInInches: 3,
        bottomInInches: 5,
        leftInInches: 3
      }
    },
    "XL": {
      standard: {
        topInInches: 3,
        rightInInches: 3,
        bottomInInches: 3,
        leftInInches: 3
      },
      thin: {
        topInInches: 1.5,
        rightInInches: 1.5,
        bottomInInches: 1.5,
        leftInInches: 1.5
      }
    },
    "GR": {
      standard: {
        topInInches: 3,
        rightInInches: 3,
        bottomInInches: 3,
        leftInInches: 3
      },
      thin: {
        topInInches: 1.5,
        rightInInches: 1.5,
        bottomInInches: 1.5,
        leftInInches: 1.5
      }
    },
    "XG": {
      standard: {
        topInInches: 4,
        rightInInches: 4,
        bottomInInches: 4,
        leftInInches: 4
      },
      thin: {
        topInInches: 2,
        rightInInches: 2,
        bottomInInches: 2,
        leftInInches: 2
      }
    },
    "MG": {
      standard: {
        topInInches: 4,
        rightInInches: 4,
        bottomInInches: 4,
        leftInInches: 4
      },
      thin: {
        topInInches: 2.5,
        rightInInches: 2.5,
        bottomInInches: 2.5,
        leftInInches: 2.5
      }
    }
  },
  float: {
    "XS": {
      standard: {
        topInInches: 1.5,
        rightInInches: 1.5,
        bottomInInches: 1.5,
        leftInInches: 1.5
      },
      thin: {
        topInInches: 0.75,
        rightInInches: 0.75,
        bottomInInches: 0.75,
        leftInInches: 0.75
      },
      wide: {
        topInInches: 4,
        rightInInches: 4,
        bottomInInches: 4,
        leftInInches: 4
      }
    },
    "S": {
      standard: {
        topInInches: 1.5,
        rightInInches: 1.5,
        bottomInInches: 1.5,
        leftInInches: 1.5
      },
      thin: {
        topInInches: 0.75,
        rightInInches: 0.75,
        bottomInInches: 0.75,
        leftInInches: 0.75
      },
      wide: {
        topInInches: 4,
        rightInInches: 4,
        bottomInInches: 4,
        leftInInches: 4
      }
    },
    "M": {
      standard: {
        topInInches: 1.5,
        rightInInches: 1.5,
        bottomInInches: 1.5,
        leftInInches: 1.5
      },
      thin: {
        topInInches: 0.75,
        rightInInches: 0.75,
        bottomInInches: 0.75,
        leftInInches: 0.75
      },
      wide: {
        topInInches: 5,
        rightInInches: 5,
        bottomInInches: 5,
        leftInInches: 5
      }
    },
    "L": {
      standard: {
        topInInches: 1.5,
        rightInInches: 1.5,
        bottomInInches: 1.5,
        leftInInches: 1.5
      },
      thin: {
        topInInches: 0.75,
        rightInInches: 0.75,
        bottomInInches: 0.75,
        leftInInches: 0.75
      },
      wide: {
        topInInches: 5,
        rightInInches: 5,
        bottomInInches: 5,
        leftInInches: 5
      }
    },
    "XL": {
      standard: {
        topInInches: 1.5,
        rightInInches: 1.5,
        bottomInInches: 1.5,
        leftInInches: 1.5
      },
      thin: {
        topInInches: 0.75,
        rightInInches: 0.75,
        bottomInInches: 0.75,
        leftInInches: 0.75
      }
    },
    "GR": {
      standard: {
        topInInches: 1.5,
        rightInInches: 1.5,
        bottomInInches: 1.5,
        leftInInches: 1.5
      },
      thin: {
        topInInches: 0.75,
        rightInInches: 0.75,
        bottomInInches: 0.75,
        leftInInches: 0.75
      }
    },
    "XG": {
      standard: {
        topInInches: 4,
        rightInInches: 4,
        bottomInInches: 4,
        leftInInches: 4
      },
      thin: {
        topInInches: 2,
        rightInInches: 2,
        bottomInInches: 2,
        leftInInches: 2
      }
    },
    "MG": {
      standard: {
        topInInches: 4,
        rightInInches: 4,
        bottomInInches: 4,
        leftInInches: 4
      },
      thin: {
        topInInches: 2.5,
        rightInInches: 2.5,
        bottomInInches: 2.5,
        leftInInches: 2.5
      }
    }
  }
};

export const presetMatWidthExists = function(mount, size, presetMatType) {
  return (mount in sizes) &&
    (size in sizes[mount]) &&
      (presetMatType in sizes[mount][size]);
};

export const getPresetMatWidth = function(mount, size, presetMatType) {
  const promise = new Promise(function(resolve) {
    if (presetMatWidthExists(mount, size, presetMatType)) {
      resolve(sizes[mount][size][presetMatType]);
    }
    else {
      resolve({
        topInInches: 0,
        rightInInches: 0,
        bottomInInches: 0,
        leftInInches: 0
      });
    }
  });

  return promise;
};

export default {
  sizes,
  presetMatWidthExists,
  getPresetMatWidth
};
