import framingUtilities from "./framingUtilities";
import { whiteMatName } from "./hardCodedValues";
import { createEqualReveal } from "./frameSpecMacros";
import hardCodedValues from "./hardCodedValues";

const maybeSetArtworkOpeningDimensions = function(minimalistFrameSpec) {
  minimalistFrameSpec.artworks.forEach(function(minimalistArtworkSpec) {
    if(typeof minimalistArtworkSpec.exterior.artworkOpeningWidthInInches === "undefined") {
      minimalistArtworkSpec.exterior.artworkOpeningWidthInInches
              = minimalistArtworkSpec.exterior.widthInInches;
      if(minimalistFrameSpec.mats.length > 0) {
         minimalistArtworkSpec.exterior.artworkOpeningWidthInInches
              -= hardCodedValues.uploadedArtworkMatOverlap;
       }
    }
    if(typeof minimalistArtworkSpec.exterior.artworkOpeningHeightInInches === "undefined") {
      minimalistArtworkSpec.exterior.artworkOpeningHeightInInches
              = minimalistArtworkSpec.exterior.heightInInches;
      if(minimalistFrameSpec.mats.length > 0) {
         minimalistArtworkSpec.exterior.artworkOpeningHeightInInches
              -= hardCodedValues.uploadedArtworkMatOverlap;
       }
    }
  });
};

export const findLowestArtworkPlacement = function(
  extendedArtworksSpec
) {
  let offsetTop = null;
  extendedArtworksSpec.forEach(function(extendedArtworkSpec) {
    if (
      offsetTop <
      extendedArtworkSpec.offset.topInInches +
        extendedArtworkSpec.exterior.artworkOpeningHeightInInches
    ) {
      offsetTop =
        extendedArtworkSpec.offset.topInInches +
        extendedArtworkSpec.exterior.artworkOpeningHeightInInches;
    }
  });

  return offsetTop;
};

export const findRightMostArtworkPlacement = function(
  extendedArtworksSpec
) {
  let offsetLeft = null;
  extendedArtworksSpec.forEach(function(extendedArtworkSpec) {
    if (
      offsetLeft <
      extendedArtworkSpec.offset.leftInInches +
        extendedArtworkSpec.exterior.artworkOpeningWidthInInches
    ) {
      offsetLeft =
        extendedArtworkSpec.offset.leftInInches +
        extendedArtworkSpec.exterior.artworkOpeningWidthInInches;
    }
  });

  return offsetLeft;
};

export const findLowestFramePlacement = function(
  extendedFrameSpecs
) {
  let offsetTop = null;
  extendedFrameSpecs.forEach(function(extendedFrameSpec) {
    if (
      offsetTop <
      extendedFrameSpec.offsetInBundle.topInInches +
        extendedFrameSpec.moulding.exterior.heightInInches
    ) {
      offsetTop =
        extendedFrameSpec.offsetInBundle.topInInches +
        extendedFrameSpec.moulding.exterior.heightInInches;
    }
  });

  return offsetTop;
};

export const findRightMostFramePlacement = function(
  extendedFrameSpecs
) {
  let offsetLeft = null;
  extendedFrameSpecs.forEach(function(extendedFrameSpec) {
    if (
      offsetLeft <
      extendedFrameSpec.offsetInBundle.leftInInches +
        extendedFrameSpec.moulding.exterior.widthInInches
    ) {
      offsetLeft =
        extendedFrameSpec.offsetInBundle.leftInInches +
        extendedFrameSpec.moulding.exterior.widthInInches;
    }
  });

  return offsetLeft;
};

export const findMaxSizeDimensions = function(arrayOfdimensions) {
  const maxDimensions = {
    widthInInches: null,
    heightInInches: null
  };
  arrayOfdimensions.forEach(function(dimensions) {
    if (maxDimensions.widthInInches < dimensions.exterior.artworkOpeningWidthInInches) {
      maxDimensions.widthInInches = dimensions.exterior.artworkOpeningWidthInInches;
    }
    if (maxDimensions.heightInInches < dimensions.exterior.artworkOpeningHeightInInches) {
      maxDimensions.heightInInches = dimensions.exterior.artworkOpeningHeightInInches;
    }
  });

  return maxDimensions;
};

export const forArtworks = function(
  minimalistArtworksSpec,
  autoPlacementType,
  maxMatWidth
) {
  const extendedArtworksSpec = [];
  // track how much far from the top and left the next artwork piece should be
  // placed -- if offset is being handled automatically
  let offsetTop = 0;
  let offsetLeft = 0;
  const maxDimensions = findMaxSizeDimensions(
    minimalistArtworksSpec
  );

  // when horizontal is true, if offset isn't specified for artwork, it will
  // automatically be stacked horizontally
  // when horizontal is false, if offset isn't specified for artwork, it will
  // automatically be stacked vertically
  const horizontal = autoPlacementType === "horizontal";

  minimalistArtworksSpec.forEach(function(minimalistArtworkSpec, artworkKey) {
    const extendedArtworkSpec = {
      artworkKey: artworkKey,
      exterior: {
        widthInInches: parseFloat(minimalistArtworkSpec.exterior.widthInInches),
        heightInInches: parseFloat(minimalistArtworkSpec.exterior.heightInInches),
        artworkOpeningWidthInInches:
                parseFloat(minimalistArtworkSpec.exterior.artworkOpeningWidthInInches),
        artworkOpeningHeightInInches:
                parseFloat(minimalistArtworkSpec.exterior.artworkOpeningHeightInInches)
      },
      offset: {
        topInInches: minimalistArtworkSpec.offset.topInInches,
        leftInInches: minimalistArtworkSpec.offset.leftInInches
      },
      cropping: minimalistArtworkSpec.cropping,
      completedMafMatName: minimalistArtworkSpec.completedMafMatName
    };
    
    let imageSpec = minimalistArtworkSpec.previewImage || minimalistArtworkSpec.croppedAndNormalized;

    if (minimalistArtworkSpec.maxQuality && imageSpec && imageSpec.url && !imageSpec.url.includes(".png")) {
      imageSpec = minimalistArtworkSpec.customerSpecified;
    }
  
    if (
      typeof imageSpec !== "undefined" &&
      typeof imageSpec.url !== "undefined"
    ) {
      extendedArtworkSpec.url = imageSpec.url;
      if (
        typeof imageSpec.dimensionsInPixels !==
          "undefined" &&
        typeof imageSpec.dimensionsInPixels
          .width !== "undefined" &&
        typeof imageSpec.dimensionsInPixels
          .height !== "undefined"
      ) {
        extendedArtworkSpec.width =
          imageSpec.dimensionsInPixels.width;
        extendedArtworkSpec.height =
          imageSpec.dimensionsInPixels.height;
      }
    }

    const autoPlacement =
      minimalistArtworkSpec.offset.topInInches === "auto" ||
      minimalistArtworkSpec.offset.leftInInches === "auto";

    if (autoPlacement) {
      // logic for automatically placing artwork
      let centeringOffset;

      extendedArtworkSpec.offset.topInInches = maxMatWidth.topInInches;
      extendedArtworkSpec.offset.leftInInches = maxMatWidth.leftInInches;

      if (horizontal) {
        extendedArtworkSpec.offset.leftInInches += offsetLeft;
        // adjust top to center vertically
        const difference =
          maxDimensions.heightInInches -
          extendedArtworkSpec.exterior.artworkOpeningHeightInInches;
        centeringOffset = difference / 2;
        extendedArtworkSpec.offset.topInInches += centeringOffset;
      } else {
        extendedArtworkSpec.offset.topInInches += offsetTop;
        // adjust left to center vertically
        const difference =
          maxDimensions.widthInInches -
          extendedArtworkSpec.exterior.artworkOpeningWidthInInches;
        centeringOffset = difference / 2;
        extendedArtworkSpec.offset.leftInInches += centeringOffset;
      }
    } else {
      extendedArtworkSpec.offset.topInInches += maxMatWidth.topInInches;
      extendedArtworkSpec.offset.leftInInches += maxMatWidth.leftInInches;
    }

    if (
      offsetLeft <
      extendedArtworkSpec.offset.leftInInches +
        extendedArtworkSpec.exterior.artworkOpeningWidthInInches
    ) {
      offsetLeft =
        extendedArtworkSpec.offset.leftInInches +
        extendedArtworkSpec.exterior.artworkOpeningWidthInInches;
    }
    if (
      offsetTop <
      extendedArtworkSpec.offset.topInInches +
        extendedArtworkSpec.exterior.artworkOpeningHeightInInches
    ) {
      offsetTop =
        extendedArtworkSpec.offset.topInInches +
        extendedArtworkSpec.exterior.artworkOpeningHeightInInches;
    }

    extendedArtworksSpec.push(extendedArtworkSpec);
  });

  return extendedArtworksSpec;
};

export const forMats = function(
  minimalistMatsSpec,
  extendedArtworksSpec,
  mouldingInterior,
  mountReveal
) {
  const extendedMatsSpec = [];

  // figure out the mats and their opennings
  const openingOffsets = {
    topInInches: mountReveal.topInInches,
    leftInInches: mountReveal.leftInInches,
    widthInInches: mountReveal.leftInInches + mountReveal.rightInInches,
    heightInInches: mountReveal.topInInches + mountReveal.bottomInInches
  };

  // using slice to make a copy of the array rather than change the one passed
  minimalistMatsSpec
    .slice()
    .reverse()
    .forEach(function(minimalistMatSpec, matKey) {
      const extendedMatSpec = {
        matKey: matKey,
        id: minimalistMatsSpec.length - 1 - matKey,
        exterior: Object.assign({}, mouldingInterior),
        openings: [],
        name: minimalistMatSpec.name
      };

      extendedMatSpec.reveal = Object.assign({}, minimalistMatSpec.reveal);

      if (minimalistMatSpec.monogram) {
        extendedMatSpec.monogram = Object.assign(
          {},
          minimalistMatSpec.monogram
        );
      }

      if (minimalistMatSpec.matCaption) {
        extendedMatSpec.matCaption = Object.assign(
          {},
          minimalistMatSpec.matCaption
        );
      }

      const isMultiArtworkFrame =
        hardCodedValues.mafMatOptions.some((m) => m === minimalistMatSpec.name);
      if (isMultiArtworkFrame) {
        const multiArtworkMat =
          hardCodedValues.primaryMatColors.find((m) => m.name === minimalistMatSpec.name);
        multiArtworkMat.openings.forEach((opening) => {
          extendedMatSpec.openings.push({
            matKey: matKey,
            artworkKey: opening.artworkKey,
            topInInches: opening.topInInches + openingOffsets.topInInches,
            leftInInches: opening.leftInInches + openingOffsets.leftInInches,
            widthInInches: opening.widthInInches + openingOffsets.widthInInches,
            heightInInches: opening.heightInInches + openingOffsets.heightInInches
          });
        });
      } else {
        extendedArtworksSpec.forEach(function(extendedArtworkSpec, artworkKey) {
          extendedMatSpec.openings.push({
            matKey: matKey,
            artworkKey: artworkKey,
            topInInches: extendedArtworkSpec.offset.topInInches - openingOffsets.topInInches,
            leftInInches: extendedArtworkSpec.offset.leftInInches - openingOffsets.leftInInches,
            widthInInches: extendedArtworkSpec.exterior.artworkOpeningWidthInInches
                  + openingOffsets.widthInInches,
            heightInInches: extendedArtworkSpec.exterior.artworkOpeningHeightInInches
                  + openingOffsets.widthInInches
          });
        });
      }

      openingOffsets.topInInches += minimalistMatSpec.reveal.topInInches;
      openingOffsets.leftInInches += minimalistMatSpec.reveal.leftInInches;
      openingOffsets.heightInInches += minimalistMatSpec.reveal.topInInches;
      openingOffsets.widthInInches += minimalistMatSpec.reveal.rightInInches;
      openingOffsets.heightInInches += minimalistMatSpec.reveal.bottomInInches;
      openingOffsets.widthInInches += minimalistMatSpec.reveal.leftInInches;
      extendedMatsSpec.push(extendedMatSpec);
    });

  return extendedMatsSpec;
};

export const forMoulding = function(
  minimalistMouldingSpec,
  extendedArtworksSpec,
  maxMatWidth,
  spree
) {
  const mouldingWidthPromise = framingUtilities.lookupMouldingWidthIfNotPresent(
    minimalistMouldingSpec,
    spree
  );

  const extendedMouldingSpecPromise = mouldingWidthPromise.then(function(
    mouldingWidth
  ) {
    const extendedMouldingSpec = {
      mouldingWidth: mouldingWidth,
      permalink: minimalistMouldingSpec.permalink,
      url: minimalistMouldingSpec.url,
      widthInPixels: minimalistMouldingSpec.widthInPixels,
      heightInPixels: minimalistMouldingSpec.heightInPixels,
      exterior: {},
      opening: {}
    };

    if (minimalistMouldingSpec.mouldingPlate) {
      extendedMouldingSpec.mouldingPlate = Object.assign(
        {},
        minimalistMouldingSpec.mouldingPlate
      );
    }

    const lowestPoint = findLowestArtworkPlacement(
      extendedArtworksSpec
    );
    const rightmostPoint = findRightMostArtworkPlacement(
      extendedArtworksSpec
    );
    extendedMouldingSpec.mouldingWidth = mouldingWidth;
    extendedMouldingSpec.permalink = minimalistMouldingSpec.permalink;
    extendedMouldingSpec.opening.heightInInches =
      lowestPoint +
    maxMatWidth.bottomInInches;
    extendedMouldingSpec.opening.widthInInches =
      rightmostPoint +
      maxMatWidth.rightInInches;
    extendedMouldingSpec.exterior.widthInInches =
      extendedMouldingSpec.opening.widthInInches + mouldingWidth * 2;
    extendedMouldingSpec.exterior.heightInInches =
      extendedMouldingSpec.opening.heightInInches + mouldingWidth * 2;

    return extendedMouldingSpec;
  });

  return extendedMouldingSpecPromise;
};

export const calculateMaxMatWidth = function(
  minimalistMatsSpec,
  mountReveal
) {
  const sum = {
    topInInches: 0,
    rightInInches: 0,
    bottomInInches: 0,
    leftInInches: 0
  };

  sum.topInInches += mountReveal.topInInches;
  sum.rightInInches += mountReveal.rightInInches;
  sum.bottomInInches += mountReveal.bottomInInches;
  sum.leftInInches += mountReveal.leftInInches;

  minimalistMatsSpec.forEach(function(minimalistMatSpec) {
    sum.topInInches += minimalistMatSpec.reveal.topInInches;
    sum.rightInInches += minimalistMatSpec.reveal.rightInInches;
    sum.bottomInInches += minimalistMatSpec.reveal.bottomInInches;
    sum.leftInInches += minimalistMatSpec.reveal.leftInInches;
  });

  return sum;
};

export const forMount = function(
  minimalistMountSpec,
  mouldingInterior
) {
  const extendedMountSpec = {
    exterior: Object.assign({}, mouldingInterior),
    name: minimalistMountSpec.name,
    elevated: minimalistMountSpec.elevated,
    reveal: Object.assign({}, minimalistMountSpec.reveal)
  };
  return extendedMountSpec;
};

export const forFrame = function(
  minimalistFrameSpec,
  autoPlacementType,
  spree
) {
  maybeSetArtworkOpeningDimensions(minimalistFrameSpec);

  let minimalistMountSpec;
  if (minimalistFrameSpec.mount) {
    minimalistMountSpec = Object.assign({}, minimalistFrameSpec.mount);
  } else {
    minimalistMountSpec = {
      elevated: false,
      name: whiteMatName
    };
  }


  if (minimalistFrameSpec.mount && minimalistFrameSpec.mount.reveal) {
    minimalistMountSpec.reveal = Object.assign(
      {},
      minimalistFrameSpec.mount.reveal
    );
  } else {
    minimalistMountSpec.reveal = createEqualReveal(0);
  }

  const maxMatWidth = calculateMaxMatWidth(
    minimalistFrameSpec.mats,
    minimalistMountSpec.reveal
  );


  const extendedFrameSpec = {
    adornments: minimalistFrameSpec.adornments
  };

  if (typeof minimalistFrameSpec.offsetInBundle !== "undefined") {
    extendedFrameSpec.offsetInBundle = {};

    if (
      typeof minimalistFrameSpec.offsetInBundle.leftInInches !== "undefined"
    ) {
      extendedFrameSpec.offsetInBundle.leftInInches =
        minimalistFrameSpec.offsetInBundle.leftInInches;
    }

    if (typeof minimalistFrameSpec.offsetInBundle.topInInches !== "undefined") {
      extendedFrameSpec.offsetInBundle.topInInches =
        minimalistFrameSpec.offsetInBundle.topInInches;
    }
  }

  extendedFrameSpec.artworks = forArtworks(
    minimalistFrameSpec.artworks,
    autoPlacementType,
    maxMatWidth
  );

  const promise = forMoulding(
      minimalistFrameSpec.moulding,
      extendedFrameSpec.artworks,
      maxMatWidth,
      spree
    )
    .then(function(mouldingSpec) {
      extendedFrameSpec.moulding = mouldingSpec;

      extendedFrameSpec.mount = forMount(
        minimalistMountSpec,
        mouldingSpec.opening
      );

      extendedFrameSpec.mats = forMats(
        minimalistFrameSpec.mats,
        extendedFrameSpec.artworks,
        extendedFrameSpec.moulding.opening,
        minimalistMountSpec.reveal
      );

      return extendedFrameSpec;
    });

  return promise;
};

export const calculateArtworkSize = function(
  extendedArtworksSpec
) {
  let topMost = 1000;
  let rightMost = 0;
  let bottomMost = 0;
  let leftMost = 1000;

  extendedArtworksSpec.forEach(function(extendedArtworkSpec) {
    const top = extendedArtworkSpec.offset.topInInches;
    const right =
      extendedArtworkSpec.exterior.artworkOpeningWidthInInches +
      extendedArtworkSpec.offset.leftInInches;
    const bottom =
      extendedArtworkSpec.exterior.artworkOpeningHeightInInches +
      extendedArtworkSpec.offset.topInInches;
    const left = extendedArtworkSpec.offset.leftInInches;

    if (top < topMost) {
      topMost = top;
    }
    if (right > rightMost) {
      rightMost = right;
    }
    if (bottom > bottomMost) {
      bottomMost = bottom;
    }
    if (left < leftMost) {
      leftMost = left;
    }
  });

  return {
    heightInInches: bottomMost - topMost,
    widthInInches: rightMost - leftMost
  };
};

export default {
  findLowestArtworkPlacement,
  findRightMostArtworkPlacement,
  findLowestFramePlacement,
  findRightMostFramePlacement,
  findMaxSizeDimensions,
  forArtworks,
  forMats,
  forMoulding,
  calculateMaxMatWidth,
  forMount,
  forFrame,
  calculateArtworkSize
};
