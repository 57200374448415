import generalUtilities from "./generalUtilities";

const svgNameSpace = "http://www.w3.org/2000/svg";
const xlinkNameSpace = "http://www.w3.org/1999/xlink";

let localDocument;

if (typeof window !== "undefined" && window.document) {
  localDocument = window.document;
}

export const document = localDocument;

export const domToString = function(dom) {
  return new XMLSerializer().serializeToString(dom);
};

export const stringToDom = function(domString) {
  return new DOMParser().parseFromString(domString, "image/svg+xml");
};

export const createElement = function(qualifiedName, options) {
  // when creating XML (like SVG), some browsers (read: IE 11) need XML
  // elements to be created referencing their name space like below
  return document.createElementNS(svgNameSpace, qualifiedName, options);
};

export const setHref = function(el, url) {
  // when creating namespaced XML attributes (like xlink:href in SVG), some
  // browsers (read: Safari) need XML element's attribute to be created
  // referencing its name space like below. Safari appears to be the only
  // major browser still using xlink:href instead of href
  if (generalUtilities.isReactNative()) {
    el.setAttributeNS(xlinkNameSpace, "xlink:href", url);
  } else {
    el.setAttributeNS(xlinkNameSpace, "href", url);
  }
  return el;
};

export const addClasses = function(el, newClasses) {
  const cleanClassString = el.getAttribute("class");
  let classArray = [];
  if (cleanClassString !== null) {
    classArray = cleanClassString.split(" ");
  }
  if (Array.isArray(newClasses)) {
    classArray = classArray.concat(newClasses);
  } else if (classArray.indexOf(newClasses) === -1) {
    classArray.push(newClasses);
  }

  const newClassString = classArray.join(" ");
  el.setAttribute("class", newClassString);
  return el;
};

export const removeClasses = function(el, removeClasses) {
  const cleanClassString = el.getAttribute("class");
  let classArray = [];
  if (cleanClassString !== null) {
    classArray = cleanClassString.split(" ");
  }
  if (Array.isArray(removeClasses)) {
    removeClasses.forEach(function(removeClass) {
      classArray = generalUtilities.removeFromArray(classArray, removeClass);
    });
  } else {
    classArray = generalUtilities.removeFromArray(classArray, removeClasses);
  }

  const newClassString = classArray.join(" ");
  el.setAttribute("class", newClassString);
  return el;
};

export const squarePathD = function(
  offsetRight,
  offsetDown,
  overallWidth,
  overallHeight
) {
  const top = `${offsetRight} ,${offsetDown}`;
  const right = `${offsetRight + overallWidth},${offsetDown}`;
  const bottom = `${offsetRight + overallWidth}, ${offsetDown + overallHeight}`;
  const left = `${offsetRight},${offsetDown + overallHeight}`;
  return `M${top} L${left} L${bottom} L${right} z`;
};

const isTouchEventWithTouchCoordinates = function(event) {
  return (
    typeof event.targetTouches !== "undefined" && event.targetTouches.length > 0
  );
};

export const getCoordinatesFromEvent = function(event, svgEl) {
  const translationMatrix = svgEl.getScreenCTM().inverse();
  const cursorPoint = svgEl.createSVGPoint();

  if (
    typeof event.clientX !== "undefined" &&
    typeof event.clientY !== "undefined"
  ) {
    cursorPoint.x = event.clientX;
    cursorPoint.y = event.clientY;
  } else if (isTouchEventWithTouchCoordinates(event)) {
    cursorPoint.x = event.targetTouches[0].clientX;
    cursorPoint.y = event.targetTouches[0].clientY;
  } else {
    return undefined;
  }

  const svgPoint = cursorPoint.matrixTransform(translationMatrix);
  return svgPoint;
};

export const createStretchingImagePattern = function(url) {
  const patternEl = createElement("pattern");
  patternEl.setAttribute("x", 0);
  patternEl.setAttribute("y", 0);
  patternEl.setAttribute("width", 1);
  patternEl.setAttribute("height", 1);
  patternEl.setAttribute("patternContentUnits", "objectBoundingBox");
  const imageEl = createElement("image");
  imageEl.setAttribute("width", 1);
  imageEl.setAttribute("height", 1);
  imageEl.setAttribute("preserveAspectRatio", "none");
  setHref(imageEl, url);
  patternEl.appendChild(imageEl);
  return patternEl;
};


export const createCoverImagePattern = function(artwork) {
  const {pattern} = artwork;
  const url = pattern.uri || pattern.url;
  const patternEl = createElement("pattern");
  patternEl.setAttribute("x", 0);
  patternEl.setAttribute("y", 0);
  patternEl.setAttribute("width", 1);
  patternEl.setAttribute("height", 1);
  patternEl.setAttribute("patternContentUnits", "userSpaceOnUse");
  const imageEl = createElement("image");
  imageEl.setAttribute("width", artwork.box.width);
  imageEl.setAttribute("height", artwork.box.height);
  imageEl.setAttribute("preserveAspectRatio", "xMidYMid slice");
  setHref(imageEl, url);
  patternEl.appendChild(imageEl);
  return patternEl;
};

export const createAdornmentImagePattern = function (adornment) {
  const url = adornment.imageUrl;
  const patternEl = createElement("pattern");
  patternEl.setAttribute("x", 0);
  patternEl.setAttribute("y", 0);
  patternEl.setAttribute("width", 1);
  patternEl.setAttribute("height", 1);
  patternEl.setAttribute("patternContentUnits", "userSpaceOnUse");
  const imageEl = createElement("image");
  imageEl.setAttribute("width", adornment.supplementalData.adornmentWidthInInches);
  imageEl.setAttribute("height", adornment.supplementalData.adornmentHeightInInches);
  imageEl.setAttribute("preserveAspectRatio", "xMidYMid slice");
  setHref(imageEl, url);
  patternEl.appendChild(imageEl);
  return patternEl;
};

export const createGlareImage = function (mount) {
  const width = mount.exterior.widthInInches;
  const height = mount.exterior.heightInInches;
  const offsetX = mount.offsetRight;
  const offsetY = mount.offsetDown;
  const glareImageEl = createElement("image");
  let orientedImageUrl;

  if (width === height) {
    orientedImageUrl = "https://cdn.framebridge.com/production/page_addition_assets/421/assets/original/clear-float-overlay-042524.png";
  } else {
    orientedImageUrl = width > height
      ? "https://cdn.framebridge.com/production/page_addition_assets/421/assets/original/clear-float-overlay-042524.png"
      : "https://cdn.framebridge.com/production/page_addition_assets/421/assets/original/clear-float-overlay-042524.png";
  }
  
  glareImageEl.setAttribute("width", width);
  glareImageEl.setAttribute("height", height);
  glareImageEl.setAttribute("x", offsetX);
  glareImageEl.setAttribute("y", offsetY);
  glareImageEl.setAttribute("preserveAspectRatio", "none");
  glareImageEl.setAttribute("style", "pointer-events: none;");
  setHref(glareImageEl, orientedImageUrl);
  return glareImageEl;
}

export const createTilingImagePattern = function(url, scale, offsets) {
  const patternEl = createElement("pattern");
  patternEl.setAttribute("x", offsets.x || 0);
  patternEl.setAttribute("y", offsets.y || 0);
  patternEl.setAttribute("width", scale.width);
  patternEl.setAttribute("height", scale.height);
  patternEl.setAttribute("patternUnits", "userSpaceOnUse");
  const imageEl = createElement("image");
  imageEl.setAttribute("x", 0);
  imageEl.setAttribute("y", 0);
  imageEl.setAttribute("width", scale.width);
  imageEl.setAttribute("height", scale.height);
  imageEl.setAttribute("preserveAspectRatio", "xMaxYMin");
  setHref(imageEl, url);
  patternEl.appendChild(imageEl);
  return patternEl;
};

export const calculateCenterRect = function(el) {
  const x = Number(el.getAttribute("x")) + Number(el.getAttribute("width")) / 2;
  const y =
    Number(el.getAttribute("y")) + Number(el.getAttribute("height")) / 2;
  const result = {
    x: x,
    y: y
  };
  return result;
};

export const rotationString = function(degrees, x, y) {
  const rotationString = `rotate(${degrees}, ${x}, ${y})`;
  return rotationString;
};

export const createSpinAnimateTransform = function(x, y, duration) {
  const spinTransformEl = createElement("animateTransform");
  spinTransformEl.setAttribute("attributeType", "xml");
  spinTransformEl.setAttribute("attributeName", "transform");
  spinTransformEl.setAttribute("type", "rotate");
  spinTransformEl.setAttribute("from", `0 ${x} ${y}`);
  spinTransformEl.setAttribute("to", `360 ${x} ${y}`);
  spinTransformEl.setAttribute("dur", duration);
  spinTransformEl.setAttribute("repeatCount", "indefinite");
  spinTransformEl.setAttribute("additive", "sum");
  return spinTransformEl;
};

export const download = function(svgEl, mouseClickEvent) {
  // setup canvas element
  const canvasEl = document.createElement("canvas");
  const ctx = canvasEl.getContext("2d");
  // match size of svg
  const frameEl = svgEl.querySelector(".frame");
  const svgDimensions = (frameEl || svgEl).getBoundingClientRect();
  ctx.canvas.width = svgDimensions.width;
  ctx.canvas.height = svgDimensions.height;

  // create a URL object for the svg image
  const serializer = new XMLSerializer();
  const data = serializer.serializeToString(svgEl);
  const svgBlob = new Blob([data], { type: "image/svg+xml;charset=utf-8" });
  const DOMURL = window.URL || window.webkitURL || window;
  const url = DOMURL.createObjectURL(svgBlob);

  // create a normal image
  const imgEl = new Image();
  imgEl.crossOrigin = "Anonymous";

  imgEl.onload = function() {
    ctx.drawImage(imgEl, 0, 0);
    DOMURL.revokeObjectURL(url);

    const imgURI = canvasEl
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");

    if (!mouseClickEvent) {
      mouseClickEvent = new MouseEvent("click", {
        view: window,
        bubbles: false,
        cancelable: true
      });
    }

    const clickEl = document.createElement("a");
    clickEl.setAttribute("download", "frame_preview.png");
    clickEl.setAttribute("href", imgURI);
    clickEl.setAttribute("target", "_blank");
    clickEl.dispatchEvent(mouseClickEvent);
  };

  // put svg image blob's object URL in the src of the image
  imgEl.src = url;
};

export const addArtworkIconPoints =
  "10.2325582 4.6899225 5.5426351 4.6899225 5.5426357 0 4.6899225 0 4.6899225 4.6899225 0 4.6899225 0 5.5426357 4.6899225 5.5426357 4.6899225 10.2325582 5.5426357 10.2325582 5.5426357 5.5426357 10.2325582 5.5426357";

export default {
  document,
  domToString,
  stringToDom,
  createElement,
  setHref,
  addClasses,
  removeClasses,
  squarePathD,
  getCoordinatesFromEvent,
  createStretchingImagePattern,
  createCoverImagePattern,
  createAdornmentImagePattern,
  createGlareImage,
  createTilingImagePattern,
  calculateCenterRect,
  rotationString,
  createSpinAnimateTransform,
  download,
  addArtworkIconPoints
};
