import camelCase from "lodash/camelCase";
import snakeCase from "lodash/snakeCase";

/**
 * @description Add getters and setters with key that throw a deprecation warning
 * @param {object} object
 * @param {string} key Property name
 * @param {function} fun function to format property name
 */
const addDeprecationProperties = (object, key, fun) => {
  // Only define a setter if the key is not present
  // This handles a case where an object returns mixed casing
  if (!object[key]) {
    Object.defineProperty(object, key, {
      get: function () {
        // eslint-disable-next-line no-console
        console.warn(`Please use CamelCased property name "${fun(key)}"`);

        return this[fun(key)];
      },
      set: function (value) {
        // eslint-disable-next-line no-console
        console.warn(`Please use CamelCased property name "${fun(key)}"`);

        this[fun(key)] = value;
      }
    });
  }
  return object;
};

/**
 * @description Recursivly convert property names from one key format to another
 * passing a function to format the property name
 * @param {*} obj object to copy property and values from
 * @param {*} fun function to convert property names
 * @param {*} [{compabilityMode}={}]
 * @returns {*}
 */
function deepRekey (obj, fun, {compabilityMode} = {}) {
  let accumulator = {};

  if (Array.isArray(obj)) {
    accumulator = [];
  }

  for (const key in obj) {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      accumulator[fun(key)] = deepRekey(obj[key], fun);
    } else {
      accumulator[fun(key)] = obj[key];
    }

    if (compabilityMode) {
      accumulator = addDeprecationProperties(accumulator, key, fun);
    }
  }

  return accumulator;
}

const snackCaseToCamelCase  = function (obj, args) {
  return deepRekey(obj, camelCase, args);
};

const camelCaseToSnackCase  = function (obj, args) {
  return deepRekey(obj, snakeCase, args);
};

export {
  snackCaseToCamelCase,
  camelCaseToSnackCase
};
