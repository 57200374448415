import framingUtilities from "./framingUtilities";
import generalUtilities from "./generalUtilities";
import svgUtilities from "./svgUtilities";
import noop from "lodash/noop";
import multiArtworkFramingUtility from "./multiArtworkFramingUtility";

import hardCodedValues from "./hardCodedValues";

export const trapazoidPathString = function(data) {
  const output = `M${data[0].join(",")} L${data[1].join(",")} L${data[2].join(
    ","
  )} L${data[3].join(",")}  z`;
  return output;
};

export const matCorePath = function(
  offsetRight,
  offsetDown,
  overallWidth,
  overallHeight,
  sideWidths
) {
  const outerEdge = svgUtilities.squarePathD(
    offsetRight,
    offsetDown,
    overallWidth,
    overallHeight
  );

  const innerEdge = svgUtilities.squarePathD(
    offsetRight + sideWidths.leftInInches,
    offsetDown + sideWidths.topInInches,
    overallWidth - sideWidths.leftInInches - sideWidths.rightInInches,
    overallHeight - sideWidths.topInInches - sideWidths.bottomInInches
  );

  return {
    outerEdge: outerEdge,
    innerEdge: innerEdge
  };
};

export const pathObject = function(fillPatternId, dataPoints) {
  return {
    dataPoints: dataPoints,
    fill: `url(#${fillPatternId})`,
    fillId: fillPatternId,
    transform: {
      rotate: {
        degrees: 0,
        x: 0,
        y: 0
      },
      translate: {
        x: 0,
        y: 0
      }
    }
  };
};

export const forArtworks = async function(
  artworksSpec,
  offsetRight,
  offsetDown,
  uri,
  imageHandlingStrategies,
  matName = null,
  isMafIncomplete
) {

  const output = [];
  const promises = [];
  for (const artworkSpec of artworksSpec) {
    const fillPatternId = `artwork-fill-${artworkSpec.artworkKey}`;
    const artworkBox = {
      artworkKey: artworkSpec.artworkKey,
      fillId: fillPatternId,
      x: artworkSpec.offset.leftInInches + offsetRight,
      y: artworkSpec.offset.topInInches + offsetDown,
      width: artworkSpec.exterior.widthInInches,
      height: artworkSpec.exterior.heightInInches
    };

    const isMultiArtworkFrame =
        hardCodedValues.mafMatOptions.some((m) => m === matName);

    if (isMultiArtworkFrame && isMafIncomplete) {
      const mafImage = await multiArtworkFramingUtility.generateMultiImageCanvasPreview(
        artworksSpec,
        matName,
        { renderPlaceholder: true }
      );
      artworkSpec.url = mafImage;
    }

    if (typeof artworkSpec.url !== "undefined") {
      const artworkPattern = {
        id: fillPatternId,
        url: artworkSpec.url
      };

      if (
        typeof artworkSpec.width !== "undefined" &&
        typeof artworkSpec.height !== "undefined"
      ) {
        // woot! we have trusted width/height data for this image!
        artworkPattern.naturalWidth = artworkSpec.width;
        artworkPattern.naturalHeight = artworkSpec.height;
      } else {
        // don't have trusted width/height data for this image -- preload in
        // detached img tag and get width from there
        promises.push(
          imageHandlingStrategies.dimensions(artworkSpec.url, artworkPattern)
        );
      }

      if (uri) {
        // we need a base64 version of this image, likely for server side
        // preview rendering -- preload image in detached img tag and then
        // base64 encode it
        promises.push(
          imageHandlingStrategies.base64(artworkSpec.url, artworkPattern)
        );
      }

      output.push({
        spec: artworkSpec,
        box: artworkBox,
        pattern: artworkPattern,
        cropping: artworkSpec.cropping
      });
    } else {
      // todo put in + icon here
      output.push({
        box: artworkBox,
        cropping: artworkSpec.cropping
      });
    }
  }

  // when add the above promises have resolved, resolve this promise with output
  return Promise.all(promises).then(function() {
    return output;
  });
};

// cached for use on this page load, so that the frame doesn't change on redraws
const matOffsets = [Math.random(), Math.random()];

export const forMats = function(
  matsSpec,
  offsetRight,
  offsetDown,
  uri,
  mouldingWidth
) {
  const mats = [];
  if (!matsSpec) {
    return mats;
  }

  const matPatternPromises = [];
  matsSpec.forEach(function(matSpec) {
    ////////
    //////// Set up values for mat surface
    ////////
    const dMat = [
      svgUtilities.squarePathD(
        offsetRight,
        offsetDown,
        matSpec.exterior.widthInInches,
        matSpec.exterior.heightInInches
      )
    ];



    const mat = {
      id: matSpec.id,
      matKey: matSpec.matKey,
      dataPoints: dMat,
      coreColors: framingUtilities.getMatCoreColors(matSpec.name),
      thickness: framingUtilities.getMatThickness(matSpec.name),
      cores: [],
      coreShade: "white"
    };
    // if mat thickness isn't set, then this isn't a real mat that should be
    // rendered and we can skip over writing a spec for it
    if (!mat.thickness) {
      return undefined;
    }

    const surfaceColor = framingUtilities.getMatSurfaceColor(matSpec.name);

    if (surfaceColor) {
      mat.fill = surfaceColor;
    } else {
      const promise = forMatTexturePattern(matSpec, uri, false, mouldingWidth)
        .then(function(matPattern) {
          mat.pattern = matPattern;
        });

      matPatternPromises.push(promise);
    }

    if (typeof mat.coreColors === "undefined") {
      ErrorReporter.captureMessage(
        `Incorrect provided ${matSpec.name}, falling back to ${hardCodedValues.whiteMatName}`,
        {
          extra: {
            matSku: matSpec.name
          }
        }
      );
      mat.coreColors = framingUtilities.getMatCoreColors(
        hardCodedValues.whiteMatName
      );
    }

    if (mat.coreColors.indexOf("#000000") > -1 ) {
      mat.coreShade = "black";
    }

    ////////
    //////// Set up values for mat core for each opening
    ////////
    matSpec.openings.forEach(function(matOpening) {
      let dMatCoreSegments = {};
      const isBase64MatOption = framingUtilities.isBase64MatOption(matSpec.name);
      if (isBase64MatOption) {
        mat.base64MatName = matSpec.name;
        mat.offsetRight = offsetRight - 0.125;
        mat.offsetDown = offsetDown - 0.125;
      } else {
        dMatCoreSegments = matCorePath(
          matOpening.leftInInches - mat.thickness + offsetRight,
          matOpening.topInInches - mat.thickness + offsetDown,
          matOpening.widthInInches + mat.thickness * 2,
          matOpening.heightInInches + mat.thickness * 2,
          {
            topInInches: mat.thickness,
            rightInInches: mat.thickness,
            bottomInInches: mat.thickness,
            leftInInches: mat.thickness
          }
        );
      }
      if (matSpec.matCaption) {
        let textAlign;
        let horizontalAlign;
        let boxAlign;
        let fontFamily;
        let line1;
        let line2;
        let line3;
        let line4;
        // defaults set for captions to protect against any missing data between v1 and v2 captions
        const defaultFontStyle = "apercu";
        const defaultFontSize = "small";
        const defaultLayoutName = "four-small-lines";
        const matCaptionLines = Boolean(matSpec.matCaption.lines);

        if (matCaptionLines) {
          fontFamily = matSpec.matCaption.lines.line1.fontStyle || defaultFontStyle;
          line1 = matSpec.matCaption.lines.line1.message;
          line2 = matSpec.matCaption.lines.line2.message;
          line3 = matSpec.matCaption.lines.line3.message;
          line4 = matSpec.matCaption.lines.line4.message;
          for (let line in matSpec.matCaption.lines) {
            if (!matSpec.matCaption.lines[line].fontSize) {
              matSpec.matCaption.lines[line].fontSize = defaultFontSize;
            }
          }
        } else {
          fontFamily = matSpec.matCaption.fontStyle || defaultFontStyle;
          if (matSpec.matCaption.message) {
            line1 = matSpec.matCaption.message.line1;
            line2 = matSpec.matCaption.message.line2;
            line3 = matSpec.matCaption.message.line3;
            line4 = matSpec.matCaption.message.line4;
          }
        }
        const horizontalLeft = matSpec.reveal.leftInInches + offsetRight;
        const horizontalRight =
          matSpec.exterior.widthInInches +
          offsetRight -
          matSpec.reveal.rightInInches;
        const horizontalCenter =
          matSpec.exterior.widthInInches / 2 + offsetRight;
        const offsetDownForMatCaption = hardCodedValues.matCaption.offsetDown;
        const fontSize = hardCodedValues.matCaption.fonts[fontFamily].fontSize;
        const lineSpace = hardCodedValues.matCaption.fonts[fontFamily].lineSpacing;
        const letterSpacing = hardCodedValues.matCaption.fonts[fontFamily].letterSpacing;
        const fontWeight = hardCodedValues.matCaption.fonts[fontFamily].fontWeight;
        const width = hardCodedValues.matCaption.maxWidth;
        const height = hardCodedValues.matCaption.maxHeight;
        const stroke = hardCodedValues.matCaption.fonts[fontFamily].fontColor;
        const strokeWidth = hardCodedValues.matCaption.fonts[fontFamily].strokeWidth;
        let fontColor = hardCodedValues.matCaption.fonts[fontFamily].fontColor;

        const matColor = hardCodedValues.primaryMatColors.find(
          color => color.name === matSpec.name
        );
        if (matColor && matColor.captionCategory == "dark") {
          fontColor = hardCodedValues.matCaption.fonts[fontFamily].darkMatFontColor;
        }
        // determines alignment properties based on user text align selection
        if (matSpec.matCaption.fontAlignment === "left") {
          textAlign = "start";
          horizontalAlign = horizontalLeft;
          boxAlign = horizontalLeft;
        } else if (matSpec.matCaption.fontAlignment === "right") {
          textAlign = "end";
          horizontalAlign = horizontalRight;
          boxAlign = horizontalRight - width;
        } else {
          textAlign = "middle";
          horizontalAlign = horizontalCenter;
          boxAlign = horizontalCenter - width / 2;
        }

        mat.matCaption = {
          fontFamily: fontFamily,
          fontSize: fontSize,
          fontColor: fontColor,
          stroke: stroke,
          strokeWidth: strokeWidth,
          lineSpace: lineSpace,
          letterSpacing: letterSpacing,
          fontWeight: fontWeight,
          line1: line1,
          line2: line2,
          line3: line3,
          line4: line4,
          layoutName: matSpec.matCaption.layoutName || defaultLayoutName,
          lines: matSpec.matCaption.lines,
          textAlign: textAlign,
          horizontalAlign: horizontalAlign,
          verticalTop:
            matSpec.exterior.heightInInches +
            offsetDown -
            matSpec.reveal.bottomInInches +
            offsetDownForMatCaption,
          width: width,
          height: height,
          boxAlign: boxAlign
        };
      }
      if (matSpec.monogram) {
        const fontStyle = hardCodedValues.monogramFontStyles.find(function(
          fontStyle
        ) {
          return fontStyle.display === matSpec.monogram.fontStyle;
        });

        const foilColor = hardCodedValues.monogramFoilColors.find(function(
          foilColor
        ) {
          return foilColor.display === matSpec.monogram.foilColor;
        });

        // the number of inches from the bottom for the top monogram line
        const offsetUpForMonogram = 1.5;
        mat.monogram = {
          horizontalCenter: matSpec.exterior.widthInInches / 2 + offsetRight,
          offsetTop:
            matSpec.exterior.heightInInches + offsetDown - offsetUpForMonogram,
          foilColorName: foilColor.display,
          foilColors: foilColor.colors,
          fontFamily: fontStyle.fontName,
          line1: matSpec.monogram.line1,
          line2: matSpec.monogram.line2
        };
      }

      // use the outer edge path from the first core in dMatSegments to cut
      // a hole in the mat
      mat.dataPoints += ` ${dMatCoreSegments.outerEdge}`;

      mat.cores.push({
        matKey: matOpening.matKey,
        artworkKey: matOpening.artworkKey,
        dataPoints: `${dMatCoreSegments.outerEdge} ${dMatCoreSegments.innerEdge}`
      });
    });

    mats.push(mat);
  });

  return Promise.all(matPatternPromises).then(function() {
    return mats;
  });
};

export const forMatTexturePattern = function(
  matSpec,
  uri,
  imageHandlingStrategies,
  mouldingWidth
) {
  const matTexturePromise = new Promise(function(resolve) {
    const fillPatternId = `mat-fill-${matSpec.matKey}`;
    const surfaceTexture = framingUtilities.getMatSurfaceTexture(matSpec.name);
    const matWidthInInches = matSpec.exterior.widthInInches;
    const matHeightInInches = matSpec.exterior.heightInInches;

    if (!surfaceTexture) {
      resolve();
    }

    const matPattern = {
      id: fillPatternId,
      url: surfaceTexture.url,
      widthInInches: surfaceTexture.widthInInches,
      heightInInches: surfaceTexture.heightInInches,
      offsets: {},
      alignment: surfaceTexture.alignment
    };

    let uriPromise;
    if (uri) {
      uriPromise = imageHandlingStrategies.base64(matPattern.url, matPattern);
    }

    if (matPattern.alignment === "center") {
      if (matWidthInInches >= matPattern.widthInInches) {
        matPattern.offsets.x = (matWidthInInches - matPattern.widthInInches) / 2 + mouldingWidth;
      } else {
        matPattern.offsets.x = - (matPattern.widthInInches - matWidthInInches) / 2 + mouldingWidth;
      }
      if (matHeightInInches >= matPattern.heightInInches) {
        matPattern.offsets.y = (matHeightInInches - matPattern.heightInInches) / 2 + mouldingWidth;
      } else {
        matPattern.offsets.y = - (matPattern.heightInInches - matHeightInInches) / 2 + mouldingWidth;
      }
    } else {
      matPattern.offsets.x = matOffsets[0] * matPattern.widthInInches;
      matPattern.offsets.y = matOffsets[1] * matPattern.heightInInches;
    }

    if (uriPromise) {
      resolve(uriPromise);
    } else {
      resolve(matPattern);
    }
  });

  return matTexturePromise;
};

export const forMount = function(mountSpec, offsetRight, offsetDown, mouldingWidth) {
  const dataPoints = [
    svgUtilities.squarePathD(
      offsetRight,
      offsetDown,
      mountSpec.exterior.widthInInches,
      mountSpec.exterior.heightInInches
    )
  ];

  const matForMount = mountSpec.name || hardCodedValues.whiteMatName;
  const mountingMat = {
    dataPoints: dataPoints,
    elevated: mountSpec.elevated,
    name: matForMount,
    exterior: mountSpec.exterior,
    offsetRight: offsetRight,
    offsetDown: offsetDown
  };

  const surfaceColor = framingUtilities.getMatSurfaceColor(mountSpec.name);

  const mountPatternPromises = [];

  if (surfaceColor) {
    mountingMat.fill = surfaceColor;
  } else {
    const promise = forMatTexturePattern(mountSpec, false, false, mouldingWidth)
      .then(function(matPattern) {
        mountingMat.pattern = matPattern;
      });

    mountPatternPromises.push(promise);
  }

  return Promise.all(mountPatternPromises).then(function() {
    return mountingMat;
  });
};

const mouldingPatternMapping = {
  top: "moulding-top-piece-pattern",
  right: "moulding-right-piece-pattern",
  bottom: "moulding-bottom-piece-pattern",
  left: "moulding-left-piece-pattern"
};

export const forMoulding = function(
  mouldingSpec,
  offsetRight,
  offsetDown,
  uri,
  spree,
  imageHandlingStrategies
) {
  const moulding = {};
  const mouldingWidthPromise = framingUtilities.lookupMouldingWidthIfNotPresent(
    mouldingSpec,
    spree
  );

  if (mouldingSpec.mouldingPlate) {
    moulding.mouldingPlate = {
      line1: mouldingSpec.mouldingPlate.line1,
      line2: mouldingSpec.mouldingPlate.line2,
      fontColor: hardCodedValues.mouldingPlate.fontColor,
      fontFamily: hardCodedValues.mouldingPlate.fontFamily,
      fontSize: hardCodedValues.mouldingPlate.fontSize,
      letterSpacing: hardCodedValues.mouldingPlate.letterSpacing,
      frameWidth: mouldingSpec.exterior.widthInInches,
      frameHeight: mouldingSpec.exterior.heightInInches,
      offsetRight,
      offsetDown
    };
  }

  const mouldingSvgPropertiesPromise = mouldingWidthPromise.then(function(
    mouldingWidth
  ) {
    moulding.struts = forMouldingPaths(
      mouldingSpec.exterior.widthInInches,
      mouldingSpec.exterior.heightInInches,
      mouldingWidth,
      offsetRight,
      offsetDown
    );

    const mouldingPatternsPromise = forMouldingPatterns(mouldingSpec, uri, spree, imageHandlingStrategies)
      .then(function(patterns) {
        moulding.patterns = patterns;
        return moulding;
      });

    return mouldingPatternsPromise;
  });

  return mouldingSvgPropertiesPromise;
};

// cached for use on this page load, so that the frame doesn't change on redraws
const moldingOffsets = [
  Math.random(),
  Math.random(),
  Math.random(),
  Math.random()
];

export const forMouldingPatterns = function(
  mouldingSpec,
  uri,
  spree,
  imageHandlingStrategies
) {
  const mouldingWidthPromise = framingUtilities.lookupMouldingWidthIfNotPresent(
    mouldingSpec,
    spree
  );
  const mouldingTilePromise = framingUtilities.lookupMouldingTileIfNotPresent(
    mouldingSpec,
    spree
  );

  const mouldingInfoPromise = Promise.all([
    mouldingWidthPromise,
    mouldingTilePromise
  ]).then(function(values) {
    const [mouldingWidth, tileInfo] = values;
    const [tileUrl, tileWidth, tileHeight] = tileInfo;
    let imageDimensionsPromise;

    if (tileWidth && tileHeight && !uri) {
      imageDimensionsPromise = new Promise(function(resolve) {
        resolve({
          width: tileWidth,
          height: tileHeight,
          ratio: tileWidth / tileHeight
        });
      });
    } else {
      const imageLoadPromise = imageHandlingStrategies.preLoader(tileUrl);
      imageDimensionsPromise = imageLoadPromise.then(function(mouldingImageEl) {
        const imageDimensions = generalUtilities.getImageNaturalDimensions(
          mouldingImageEl
        );

        if (uri) {
          return imageHandlingStrategies.base64FromImageElement(
            mouldingImageEl,
            imageDimensions
          );
        }

        return imageDimensions;
      });
    }

    const patternsPromise = imageDimensionsPromise.then(function(
      imageDimensions
    ) {
      const patterns = [];
      const tileLength = imageDimensions.ratio * mouldingWidth;

      let iterator = 0;
      for (const key in mouldingPatternMapping) {
        if (
          !Object.prototype.hasOwnProperty.call(mouldingPatternMapping, key)
        ) {
          continue;
        }

        const mouldingPattern = {
          id: mouldingPatternMapping[key],
          length: tileLength,
          mouldingWidth: mouldingWidth,
          offset: moldingOffsets[iterator] * tileLength,
          url: tileUrl
        };

        if (uri) {
          mouldingPattern.uri = imageDimensions.uri;
        }

        patterns.push(mouldingPattern);
        iterator++;
      }

      return patterns;
    });

    return patternsPromise;
  });

  return mouldingInfoPromise;
};

export const forMouldingPaths = function(
  frameWidth,
  frameHeight,
  mouldingWidth,
  offsetRight,
  offsetDown
) {
  // sets up the inner and outer points of the moulding
  const outerTopLeft = [0, 0];
  const innerTopLeft = [mouldingWidth, mouldingWidth];

  const widthOuterTopRight = [frameWidth, 0];
  const widthInnerTopRight = [frameWidth - mouldingWidth, mouldingWidth];

  const heightOuterTopRight = [frameHeight, 0];
  const heightInnerTopRight = [frameHeight - mouldingWidth, mouldingWidth];

  // add in offsets

  // creates trapazoids to fill with moulding tiles
  const widthWiseStrut = trapazoidPathString([
    outerTopLeft,
    innerTopLeft,
    widthInnerTopRight,
    widthOuterTopRight
  ]);

  const heightWiseStrut = trapazoidPathString([
    outerTopLeft,
    innerTopLeft,
    heightInnerTopRight,
    heightOuterTopRight
  ]);

  const struts = {
    top: pathObject(
      mouldingPatternMapping.top,
      widthWiseStrut
    ),
    right: pathObject(
      mouldingPatternMapping.right,
      heightWiseStrut
    ),
    bottom: pathObject(
      mouldingPatternMapping.bottom,
      widthWiseStrut
    ),
    left: pathObject(
      mouldingPatternMapping.left,
      heightWiseStrut
    )
  };

  // rotates and moves trapazoids as appropriate
  struts.top.transform.translate.x = offsetRight;
  struts.top.transform.translate.y = offsetDown;

  struts.right.transform.rotate.degrees = 90;
  // flipped on its side, so use offsetDown for x instead of offsetRight
  struts.right.transform.translate.x = offsetDown;
  // flipped on its side, so use offsetRight for y instead of offsetDown
  // flipped counter clock wise, so subtrack offsetRight rather than adding
  struts.right.transform.translate.y = 0 - frameWidth - offsetRight;

  struts.bottom.transform.rotate.degrees = 180;
  struts.bottom.transform.rotate.x = frameWidth / 2;
  struts.bottom.transform.rotate.x = (frameWidth + offsetRight) / 2;
  struts.bottom.transform.rotate.y = frameHeight / 2;
  struts.bottom.transform.rotate.y = (frameHeight + offsetDown) / 2;

  struts.left.transform.rotate.degrees = 270;
  // flipped on its side, so use offsetDown for x instead of offsetRight
  struts.left.transform.translate.x = 0 - frameHeight - offsetDown;
  // flipped on its side, so use offsetRight for y instead of offsetDown
  struts.left.transform.translate.y = offsetRight;

  return struts;
};

export const preloadImages = function(
  extendedFrameSpec,
  spree,
  imageHandlingStrategies
) {
  const mouldingTilePromise = framingUtilities.lookupMouldingTileIfNotPresent(
    extendedFrameSpec.moulding,
    spree
  );

  const imagePreloadPromise = mouldingTilePromise.then(function(tileInfo) {
    const images = [];
    images.push(tileInfo[0]);

    extendedFrameSpec.artworks.forEach(function(artworkSpec) {
      if (typeof artworkSpec.url !== "undefined") {
        images.push(artworkSpec.url);
      }
    });

    return Promise.all(
      images.map(function(url) {
        return imageHandlingStrategies.preLoader(url);
      })
    );
  });

  return imagePreloadPromise;
};

export const svgPathCalculations = function(
  extendedFrameSpec,
  offsetRight,
  offsetDown,
  uri,
  spree,
  imageHandlingStrategies = false,
  isMafIncomplete = false
) {
  const svgFramePreview = {};

  if (extendedFrameSpec.adornments) {
    svgFramePreview.adornments = extendedFrameSpec.adornments;
  }
  if (!imageHandlingStrategies) {
    imageHandlingStrategies = {
      preLoader: generalUtilities.preloadImage,
      dimensions: function(url, pattern) {
        return generalUtilities
          .preloadImage(url)
          .then(function(imageEl) {
            pattern.naturalWidth = imageEl.naturalWidth;
            pattern.naturalHeight = imageEl.naturalHeight;
            return pattern;
          })
          .catch(noop);
      },
      base64: function(url, pattern) {
        return generalUtilities
          .preloadImage(url)
          .then(function(imageEl) {
            pattern.uri = generalUtilities.base64encodeImage(imageEl);
            return pattern;
          })
          .catch(noop);
      },
      base64FromImageElement: function(imageEl, pattern) {
        pattern.uri = generalUtilities.base64encodeImage(imageEl);
        return pattern;
      }
    };
  }

  // start all images loads now -- forMoulding and forArtworks do this too, but
  // they're chained promises -- this should speed things up a tad
  const preloadPromise = preloadImages(extendedFrameSpec, spree, imageHandlingStrategies)
    .catch(noop);

  const mouldingPromise = forMoulding(
    extendedFrameSpec.moulding,
    offsetRight,
    offsetDown,
    uri,
    spree,
    imageHandlingStrategies
  );

  const framePropertiesPromise = mouldingPromise.then(function(moulding) {
    svgFramePreview.moulding = moulding;

    const mouldingWidthPromise = framingUtilities.lookupMouldingWidthIfNotPresent(
      extendedFrameSpec.moulding,
      spree
    );

    const artworksPromise1 = mouldingWidthPromise.then(function(mouldingWidth) {
      let mountPromise;
      if (extendedFrameSpec.mount) {
        mountPromise = forMount(
            extendedFrameSpec.mount,
            mouldingWidth + offsetRight,
            mouldingWidth + offsetDown,
            mouldingWidth
          )
          .then(mountingMat => {
            svgFramePreview.mount = mountingMat;
          });
      } else {
        mountPromise = Promise.resolve();
      }

      const matsPromise = forMats(
          extendedFrameSpec.mats,
          mouldingWidth + offsetRight,
          mouldingWidth + offsetDown,
          uri,
          mouldingWidth
        )
        .then(function(mats) {
          svgFramePreview.mats = mats;
        });
      const matName = extendedFrameSpec.mats[0] && extendedFrameSpec.mats[0].name;
      const artworksPromise2 = forArtworks(
          extendedFrameSpec.artworks,
          mouldingWidth + offsetRight,
          mouldingWidth + offsetDown,
          uri,
          imageHandlingStrategies,
          matName,
          isMafIncomplete
        )
        .then(function(artworks) {
          svgFramePreview.artworks = artworks;
        });

      return Promise.all([artworksPromise2, matsPromise, mountPromise]).then(
        function() {
          return svgFramePreview;
        }
      );
    });
    return artworksPromise1;
  });

  return Promise.all([preloadPromise, framePropertiesPromise]).then(
    results => results[1]
  );
};

export default {
  trapazoidPathString,
  matCorePath,
  pathObject,
  forArtworks,
  forMats,
  forMatTexturePattern,
  forMount,
  forMoulding,
  forMouldingPatterns,
  forMouldingPaths,
  preloadImages,
  svgPathCalculations
};
