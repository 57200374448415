import axios from "axios";

/**
 * The following values represent the cached responses
 * as Promises to various server - side data.
 *
 * The general pattern is that each module method has a corresponding
 * cache value. If the Promise isn't available at runtime, then the request is made
 * and the returned and cached. This leverages the 'available eventually'
 * characteristic of promises.
 *
 */

const productPromises = {};

let productsPromise;
let sizeGroupsPromise;
let variantsPromise;
let framesPromise;
let mouldingsPromise;

const parseData = function(response) {
  return response.data;
};

export default function(baseUrl) {
  this.transport = axios.create({ baseURL: baseUrl || "" });

  this.product = function(permalink, doNotCache) {
    if (!productPromises[permalink] || doNotCache) {
      productPromises[permalink] = this.transport
        .get(`/api/products/${permalink}.json`)
        .then(parseData);
    }

    return productPromises[permalink];
  };

  this.products = function(doNotCache) {
    if (!productsPromise || doNotCache) {
      productsPromise = this.transport
        .get("/api/products.json")
        .then(parseData);
    }

    return productsPromise;
  };

  this.sizeGroups = function(doNotCache) {
    if (!sizeGroupsPromise || doNotCache) {
      sizeGroupsPromise = this.transport
        .get("/api/sizing_information.json")
        .then(parseData);
    }

    return sizeGroupsPromise;
  };

  this.variants = function(doNotCache) {
    if (!variantsPromise || doNotCache) {
      variantsPromise = this.transport
        .get("/api/variants.json")
        .then(parseData);
    }

    return variantsPromise;
  };

  this.frames = function(doNotCache) {
    if (!framesPromise || doNotCache) {
      framesPromise = this.transport.get("/api/frames.json").then(parseData);
    }

    return framesPromise;
  };

  this.mouldings = function(doNotCache) {
    if (!mouldingsPromise || doNotCache) {
      mouldingsPromise = this.transport
        .get("/api/v1/mouldings.json")
        .then(parseData);
    }

    return mouldingsPromise;
  };

  this.lineItem = function(orderNumber, lineItemNumber) {
    const url = `/api/orders/${orderNumber}/line_items/${lineItemNumber}/project`;
    const headers = {};

    if (window.Spree && window.Spree.order_token) {
      headers["X-Spree-Order-Token"] = window.Spree.order_token;
    }

    return this.transport
      .get(url, { headers: headers })
      .then(parseData);
  };

  return this;
};
