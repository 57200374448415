import { ref } from 'vue';
import FrameSpec from '@framebridge/toolbox/FrameSpec';
/**
 * useFrameSpecData
 * @description
 * A composable function to manage frame specification data.
 * @param {Object} product - The product object containing details about the product
 * @param {string|null} [variantTitle=null] - The title of the product variant.
 * @param {string|null} [variantSpec=null] - The specification of the product variant.
 * @param {string|null} [variantId=null] - The ID of the product variant.
 */
function useFrameSpecData(product, variantTitle = null, variantSpec = null, variantId = null) {
  const frameSpec = ref(null);
  const frameSpecUrl = ref(null);
  const frameSpecPrice = ref(null);
  const isValid = ref(null);
  const canPrintArtwork = ref(null);
  const customerUploadActive = ref(null);
  const uploadWidgetEnabled = !!document.getElementById('uploadApp');
  const isUploadProcessing = ref(false);
  const showMaskRenderer = ref(false);

  const { handle, type } = product || {};
  const eligibleForFrameSpec = type?.toLowerCase() === 'custom frame'
    || type?.toLowerCase() === 'art print frame'
    || type?.toLowerCase() === 'designers choice'
    || type?.toLowerCase() === 'curated design frame';
  const currentSpec = window.localStorage.getItem('currentFrameSpec');
  const hasMoldings = window.sessionStorage.getItem('mouldingOptions');

  const getUploadedArtwork = () => {
    // check if uploaded artwork exists and is upload widget is active
    const userUpload = uploadWidgetEnabled && localStorage.getItem('uploadedArtwork');
    // update the customerUploadActive value
    customerUploadActive.value = userUpload;

    if (userUpload && typeof userUpload === 'string') {
      try {
        const uploadedArtworkJSON = JSON.parse(userUpload);

        return uploadedArtworkJSON;
      } catch (error) {
        console.error({ message: 'Error parsing upload artwork JSON', error });
        return false;
      }
    }

    return false;
  };

  /**
   * Check FrameSpec Data
   * @description
   * Check if there is a currentFrameSpec in localStorage and initialize
   * @param {string|Object} [updatedTitle=null] The updated variantTitle from variant swatch clicks.
   * @returns {void}
   */
  function checkFrameSpecData() {
    if (!eligibleForFrameSpec) return;
    const userUpload = getUploadedArtwork();

    if (userUpload) {
      initUploadFrameSpec(userUpload, variantId);
      return;
    }

    if (variantSpec && !uploadWidgetEnabled) {
      getFrameSpecById(variantSpec)
        .then((fs) => {
          if (fs) {
            frameSpec.value = fs;
            isValid.value = true;
          }
        })
        .catch((err) => {
          console.error(err);
        });
      return;
    }

    if (currentSpec && typeof currentSpec === 'string') {
      try {
        const parsedSpec = JSON.parse(currentSpec) || {};

        if (parsedSpec && !uploadWidgetEnabled) {
          initFrameSpec(parsedSpec);
        }
      } catch (error) {
        console.error({ message: 'Error parsing upload image JSON', error });
      }
    }
  }

  async function initUploadFrameSpec(upload, vId) {
    // init loading state
    isUploadProcessing.value = true;
    const artTypeVals = {
      artTypeSlug: 'digital_upload',
      productCategory: 'digital_photo',
      productCategoryName: 'Digital Photo',
      conveyance: 'uploaded',
      medium: 'paper',
    };
    upload.artwork = {
      ...upload.artwork,
      ...artTypeVals,
    };

    try {
      const spec = await FrameSpec.build({
        joineryHost: SDG?.Data?.joineryHost || 'https://staging.framebridge.com',
        shopifyHost: SDG?.Data?.shopifyHost || 'https://staging.framebridge.dev',
        query: { product: `${product.id}`, variant: `${vId}` },
        artwork: upload.artwork,
      });
      const fsURL = await spec.urlParams({ stringify: true });
      const hasMask = spec?.variant?.maskedRenderer || false;
      frameSpec.value = spec;
      isValid.value = true;
      frameSpecUrl.value = fsURL;
      canPrintArtwork.value = spec?.canPrintArtwork();
      showMaskRenderer.value = hasMask;

      if (spec) {
        setPrice(spec);
      }
    } catch (error) {
      console.error({ message: 'Error building FrameSpec', error });
    } finally {
      isUploadProcessing.value = false;
    }
  }

  /**
   * Init FrameSpec
   * @description
   * Initialize the FrameSpec and updates state
   * @param {Object} - currentFrameSpec
   * @returns {void}
   * @async
   * @throws {Error}
   */
  async function initFrameSpec(currentFrameSpec) {
    try {
      const frameSpecBuild = await FrameSpec.build({
        joineryHost: SDG?.Data?.joineryHost || 'https://staging.framebridge.com',
        shopifyHost: SDG?.Data?.shopifyHost || 'https://staging.framebridge.dev',
        query: { product: product.id, variant: product?.variants?.[0]?.id },
        frameSpecData: currentFrameSpec,
      });

      const mouldingOptions = await getMouldings(frameSpecBuild);
      const activeMoulding = mouldingOptions.find(
        (moulding) => {
          // default by product handle
          const matchByHandle = moulding?.permalink === handle;
          // fallback to variant name
          const matchByName = moulding?.name === variantTitle;

          if (!matchByHandle && !matchByName) {
            isValid.value = false;
          }

          return matchByHandle || matchByName;
        },
      );

      if (activeMoulding) {
        const cloned = clone(activeMoulding, frameSpecBuild);

        if (cloned) {
          setPrice(cloned);
        }
      }
    } catch (error) {
      console.error({ message: 'Error building FrameSpec', error });
    }
  }

  /**
   * Get Mouldings
   * @description
   * Get the moulding options from the FrameSpec
   * @param {Object} - frameSpecBuild
   * @returns {Array} - mouldings || []
   * @async
   * @throws {Error}
   */
  async function getMouldings(frameSpecBuild) {
    if (hasMoldings && !variantSpec) {
      return JSON.parse(hasMoldings);
    }

    try {
      const mouldings = await frameSpecBuild?.getMouldingOptions('all');
      const { mouldings: mouldingsArray } = mouldings || [];

      if (mouldingsArray?.length > 0) {
        // set the moulding options in sessionStorage to avoid multiple calls
        window.sessionStorage.setItem('mouldingOptions', JSON.stringify(mouldingsArray));
        return mouldingsArray;
      }
      isValid.value = false;

      return [];
    } catch (error) {
      console.error({ message: 'Error getting moulding options', error });
      isValid.value = false;

      return [];
    }
  }

  /**
   * Update on Swatch Click
   * @description
   * updates the frameSpec values with the new moulding/variant
   * @param {string} - id
   */
  async function updateSpecOnSwatchClick(id) {
    const userUpload = getUploadedArtwork();
    try {
      if (userUpload) {
        initUploadFrameSpec(userUpload, id);
      }
    } catch (error) {
      console.error({ message: 'Error updating FrameSpec on swatch click', error });
    }
  }

  /**
   * clone
   * @description
   * Clones the object and updates the moulding
   * @param {Object} - moulding
   * @param {Object} - frameSpecBuild
   * @returns {Object} - cloned object with new moulding
   */
  async function clone(moulding, frameSpecBuild) {
    try {
      // clone the frameSpec
    // eslint-disable-next-line no-nested-ternary
      const clonedObj = frameSpecBuild?.duplicate
        ? frameSpecBuild.duplicate()
        : frameSpecBuild?.clone ? frameSpecBuild.clone() : null;

      if (!clonedObj || !moulding?.permalink) {
        return null;
      }

      clonedObj.moulding = {
        permalink: moulding.permalink,
      };
      clonedObj.catalog = {
        ...frameSpecBuild.catalog,
        shopifyProductId: moulding?.shopifyProductId || '',
      };

      const fsURL = await clonedObj.urlParams({ stringify: true });
      frameSpecUrl.value = fsURL;

      frameSpec.value = clonedObj;
      isValid.value = true;
      canPrintArtwork.value = frameSpecBuild?.canPrintArtwork();

      return clonedObj;
    } catch (error) {
      console.error({ message: 'Error cloning FrameSpec', error });
      isValid.value = false;

      return null;
    }
  }

  /**
   * Set Price
   * @description
   * Set the price in the FrameSpec
   * @param {Object} - clone
   * @returns {void}
   */
  function setPrice(clonedFrameSpec) {
    const { price } = clonedFrameSpec;
    // set the price with chosen size
    if (price && price?.total_price) {
      const money = price.total_price;

      frameSpecPrice.value = money;
    }
  }

  async function cloneFrameSpec(frameSpecItem) {
    try {
      const newFrameSpec = frameSpecItem.duplicate();
      if (!newFrameSpec) return null;

      await newFrameSpec?.updateVariantAndConstraints();

      if (newFrameSpec?.catalog?.shopifyVariantId) {
        return newFrameSpec;
      }
      console.error({ message: 'Error cloning FrameSpec', error: 'No shopify Variant Id' });

      return null;
    } catch (error) {
      console.error({ message: 'Error cloning FrameSpec', error });

      return null;
    }
  }

  async function getFrameSpecById(id, authorizationToken) {
    if (!id || !authorizationToken) return null;

    try {
      const frameSpecItem = await FrameSpec.build({
        joineryHost: SDG?.Data?.joineryHost || 'https://staging.framebridge.com',
        shopifyHost: SDG?.Data?.shopifyHost || 'https://staging.framebridge.dev',
        number: id,
        token: SDG.Data.joineryToken || 'f16fb23e225e68ba0cb41dbad9a95b3abbedc3f1fa461155',
        authorizationToken,
      });

      frameSpec.value = frameSpecItem;
      return frameSpecItem;
    } catch (error) {
      console.error({ message: 'Error getting frameSpec by id', error });

      return null;
    }
  }

  const clearFrameSpec = () => {
    frameSpec.value = null;
    frameSpecUrl.value = null;
    frameSpecPrice.value = null;
    isValid.value = null;
    canPrintArtwork.value = null;
    customerUploadActive.value = null;
    showMaskRenderer.value = false;
  };

  return {
    frameSpec,
    frameSpecPrice,
    frameSpecUrl,
    isValid,
    isUploadProcessing,
    canPrintArtwork,
    checkFrameSpecData,
    cloneFrameSpec,
    getFrameSpecById,
    updateSpecOnSwatchClick,
    customerUploadActive,
    clearFrameSpec,
    showMaskRenderer,
  };
}

export default useFrameSpecData;
