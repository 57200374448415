export const stringToSlug = function(str) {
  return `${str}`
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "");
};

export const closeTo = function closeTo(num1, num2, delta) {
  if (delta == null) {
    delta = 0.00001;
  }
  return Math.abs(num1 - num2) <= delta;
};

export const elementLoaded = function(el) {
  const promise = new Promise(function(resolve, reject) {
    el.onload = function() {
      resolve(el);
    };
    el.onerror = function(message, source, lineno, colno, error) {
      reject(message, source, lineno, colno, error);
    };
  });

  return promise;
};

export const preloadImage = function(url) {
  return new Promise(function(resolve, reject) {
    const el = new Image();
    el.onload = function() {
      resolve(el);
    };
    el.onerror = function(message, source, lineno, colno, error) {
      reject(message, source, lineno, colno, error);
    };
    el.src = url;
  });
};

export const getImageNaturalDimensions = function(el) {
  return {
    width: el.naturalWidth,
    height: el.naturalHeight,
    ratio: el.naturalWidth / el.naturalHeight
  };
};

export const base64encodeImage = function(imageEl) {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  canvas.height = imageEl.naturalHeight;
  canvas.width = imageEl.naturalWidth;
  ctx.drawImage(imageEl, 0, 0);
  return canvas.toDataURL("image/png");
};

const extractUserAgentString = function() {
  if (typeof navigator !== "undefined" && navigator.userAgent) {
    return navigator.userAgent;
  }

  if (typeof global !== "undefined" && global.USER_AGENT) {
    return global.USER_AGENT;
  } else if (typeof window !== "undefined" && window.USER_AGENT) {
    return window.USER_AGENT;
  }

  return "";
};

export const isReactNative = function() {
  return (
    typeof navigator !== "undefined" && navigator.product === "ReactNative"
  );
};

const safeUserAgentPredicate = function(predicate) {
  return function() {
    return predicate(extractUserAgentString());
  };
};

export const isOpera = safeUserAgentPredicate(function(userAgent) {
  return userAgent.toLowerCase().indexOf("op") !== -1;
});

export const isEdge = safeUserAgentPredicate(function(userAgent) {
  return userAgent.indexOf("Edge") !== -1;
});

export const isFirefox = safeUserAgentPredicate(function(userAgent) {
  return userAgent.indexOf("Firefox") !== -1;
});

export const isIE = safeUserAgentPredicate(function(userAgent) {
  return userAgent.indexOf("MSIE") !== -1;
});

export const isChrome = safeUserAgentPredicate(function(userAgent) {
  const isChrome = userAgent.indexOf("Chrome") !== -1;
  return isChrome && !isOpera() && !isEdge();
});

export const isSafari = safeUserAgentPredicate(function(userAgent) {
  const isSafari = userAgent.indexOf("Safari") !== -1;
  return isSafari && !isChrome();
});

export const isIOS = function() {
  const userAgent = navigator.userAgent;
  const iOS = Boolean(userAgent.match(/iPad/i)) || Boolean(userAgent.match(/iPhone/i));

  return isSafari() && iOS && !userAgent.match(/CriOS/i);
};

export const isTouchDevice = function() {
  return isReactNative()
    ? false
    : typeof document !== "undefined" &&
        "ontouchstart" in document.documentElement;
};

export const removeFromArray = function(input, item) {
  // while the item is still in the array, keep deleting it
  while (input.indexOf(item) !== -1) {
    input.splice(input.indexOf(item), 1);
  }
  return input;
};

/**
 * Takes an array, and creates a new one with one item changed out
 * @function
 * @param newItem - The new item
 * @param {number} key - The spot to put the new item in.
 * @param {Array} artworkSpec - The original array.
 * @returns {Array}
 */
export const replaceItemInArray = function(newItem, key, oldArray) {
  return [].concat(
    oldArray.slice(0, key),
    newItem,
    oldArray.slice(key + 1)
  );
};

export const hasNumberOfTouchPoints = function(event, numberOfTouchPoints) {
  return (
    typeof event.targetTouches !== "undefined" &&
    event.targetTouches.length === numberOfTouchPoints
  );
};

export const simplifyRotationDegrees = function(inputDegrees) {
  let outputDegrees = inputDegrees % 360;
  if (outputDegrees < 0) {
    outputDegrees = outputDegrees + 360;
  }
  if (!outputDegrees) {
    outputDegrees = 0;
  }
  return outputDegrees;
};

export const keyPressLeftArrow = function(event) {
  return event.keyCode == "37";
};

export const keyPressUpArrow = function(event) {
  return event.keyCode == "38";
};

export const keyPressRightArrow = function(event) {
  return event.keyCode == "39";
};

export const keyPressDownArrow = function(event) {
  return event.keyCode == "40";
};

export const keyPressEscape = function(event) {
  return event.keyCode == "27";
};

export const keyPressTab = function(event) {
  return event.keyCode == "9";
};

export const traverseParentNodesToFindMatch = function(startEl, match) {
  let currentEl = startEl;
  while (currentEl && !match(currentEl)) {
    currentEl = currentEl.parentNode;
  }
  return currentEl;
};

export const elMatchesOnOf = function elMatchesOnOf(el, inputSelectors) {
  const selectors = inputSelectors.slice();
  const selector = selectors.pop();
  const match = el.matches(selector);
  if (match || selectors.length === 0) {
    // if it matches or we have nothing else to check against, return boolean
    return match;
  } else {
    // if it doesn't match and we have more we can check, recurse
    return elMatchesOnOf(el, selectors);
  }
};

export default {
  stringToSlug,
  closeTo,
  elementLoaded,
  preloadImage,
  getImageNaturalDimensions,
  base64encodeImage,
  isReactNative,
  isOpera,
  isEdge,
  isFirefox,
  isIE,
  isChrome,
  isSafari,
  isIOS,
  isTouchDevice,
  removeFromArray,
  replaceItemInArray,
  hasNumberOfTouchPoints,
  simplifyRotationDegrees,
  keyPressLeftArrow,
  keyPressUpArrow,
  keyPressRightArrow,
  keyPressDownArrow,
  keyPressEscape,
  keyPressTab,
  traverseParentNodesToFindMatch,
  elMatchesOnOf
};
