import { maxBy } from "lodash";
import { contain } from "intrinsic-scale";
import artworkUploader from "./apihelpers/artworkUploader";
import { normalizedArtworkData } from "./framingUtilities.js";
import { snackCaseToCamelCase } from "./objectConverter";
import { getImageErrorsForFile } from "./validateImageFile.js";
import { proportionalArtworkDimensions } from "./proportionalArtworkDimensions.js";

class ArtworkEditor {
  // **********************
  // Setup and data loading
  // **********************

  static async create (params) {
    const artworkEditor = new ArtworkEditor(params);

    if (params.initialImageBlob) {
      // When we did an in-browser upload and still have the "file"
      await artworkEditor.updatePreviewFromBlob(params.initialImageBlob);
    } else {
      // Generally we want the original unedited to work with
      // We fall back to the customerSpecified out of fear
      const imageUrl = params.useNormalized
        ? artworkEditor.focusedArtwork().normalizedUnedited.url
        : artworkEditor.focusedArtwork().originalUnedited.url ||
          artworkEditor.focusedArtwork().customerSpecified.url;
      await artworkEditor.setPreviewImageSrc(imageUrl);
    }

    return artworkEditor;
  }

  constructor ({
    glazeClient,
    img,
    frameSpec,
    mafArtworks,
    selectedMafOpening,
    checkForQuality,
    fixedArtWidth,
    fixedArtHeight,
    useNormalized,
    edits,
    artworkKey,
    maskImageUrl
  }) {
    this.glazeClient = glazeClient;
    this.frameSpec = frameSpec;
    this.mafArtworks = mafArtworks || [];
    this.selectedMafOpening = selectedMafOpening;
    this.checkForQuality = checkForQuality;
    this.fixedArtWidth = fixedArtWidth;
    this.fixedArtHeight = fixedArtHeight;
    this.useNormalized = useNormalized;
    this.artworkKey = artworkKey || 0;
    this.maskImageUrl = maskImageUrl;

    this.img = img;
    if (!this.img) {
      this.img = document.createElement("img");
      this.img.crossOrigin = "anonymous";
    }
    
    if (edits) {
      this.edits = edits;
    } else if (!useNormalized && this.focusedArtwork().edits) {
      this.edits = this.focusedArtwork().edits;
    } else {
      this.edits = {
        brighten: false,
        grayscale: false,
        rotationCount: 0,
        cropZone: {}
      };
    }

    if (!(this.edits.cropZone && this.edits.cropZone.width)) {
      this.edits.cropZone = {
        left: 0,
        top: 0,
        width: this.focusedArtworkDimensions().width,
        height: this.focusedArtworkDimensions().height
      };
    }

    this.setEditedImageDimensions(this.edits.cropZone.width, this.edits.cropZone.height);
  }

  setPreviewImageSrc (src) {
    this.currentImageObjectURL = src;

    return new Promise((resolve, reject) => {
      this.img.onload = resolve;
      this.img.onerror = reject;
      this.img.src = src;
    });
  }

  async updatePreviewFromBlob (blob) {
    this.currentBlob = blob;
    if (this.currentImageObjectURL) {
      URL.revokeObjectURL(this.currentImageObjectURL);
    }
    const objectUrl =  await URL.createObjectURL(this.currentBlob);
    await this.setPreviewImageSrc(objectUrl);
  }

  setEditedImageDimensions (newWidth, newHeight) {
    this.editedImageDimensions = {
      width: newWidth,
      height: newHeight
    };
  }

  // *********************************************
  // Get some information about this artwork/image
  // *********************************************

  largestDimensions () {
    if (!this.sizeOptions().length) {
      return {};
    }

    if (this.isPortrait) {
      return maxBy(this.sizeOptions(), option => option.heightInInches);
    } else {
      return maxBy(this.sizeOptions(), option => option.widthInInches);
    }
  }

  focusedArtwork () {
    const mafArtwork = this.mafArtworks.find(
      artwork => artwork.openingKey === this.selectedMafOpening.key
    );

    return (mafArtwork && mafArtwork.artworkSpec) || this.frameSpec.artworks[this.artworkKey];
  }

  focusedArtworkDimensions () {
    if (this.frameSpec.artworks.length) {
      const focusedArtwork = this.focusedArtwork();
      let artworkFile = focusedArtwork.customerSpecified;

      if (this.useNormalized) {
        artworkFile = focusedArtwork.normalizedUnedited;
      } else if (
        focusedArtwork.originalUnedited &&
        focusedArtwork.originalUnedited.dimensionsInPixels
      ) {
        artworkFile = focusedArtwork.originalUnedited;
      }

      const { width, height } = artworkFile.dimensionsInPixels;
      return { width, height };
    } else {
      return {};
    }
  }

  widthInPixels () {
    return this.editedImageDimensions.width;
  }

  heightInPixels () {
    return this.editedImageDimensions.height;
  }

  isPortrait () {
    return this.heightInPixels() >= this.widthInPixels();
  }

  shortSide () {
    if (this.fixedArtWidth && this.fixedArtHeight) {
      return this.fixedArtHeight >= this.fixedArtWidth ? this.fixedArtWidth : this.fixedArtHeight;
    } else {
      return null;
    }
  }

  longSide () {
    if (this.fixedArtWidth && this.fixedArtHeight) {
      return this.fixedArtHeight >= this.fixedArtWidth ? this.fixedArtHeight : this.fixedArtWidth;
    } else {
      return null;
    }
  }

  sizeOptions () {
    return proportionalArtworkDimensions(
      this.widthInPixels(),
      this.heightInPixels(),
      this.shortSide(),
      this.longSide()
    );
  }

  rotatedSizeOptions () {
    return proportionalArtworkDimensions(
      this.heightInPixels(),
      this.widthInPixels()
    );
  }

  hasTransparency () {
    return Boolean(this.uneditedImage && this.uneditedImage.countAlphaPixels({ alpha: 0 }));
  }

  // **************************
  // Image/Artwork manipulation
  // **************************

  setRotationEdits (count) {
    const originalWidth = this.focusedArtworkDimensions().width;
    const originalHeight = this.focusedArtworkDimensions().height;
    const topOffset = this.edits.rotationCount % 2 ? originalHeight : originalWidth;

    this.edits.cropZone = {
      height: this.edits.cropZone.width,
      width: this.edits.cropZone.height,
      top: topOffset - this.edits.cropZone.left - this.edits.cropZone.width,
      left: this.edits.cropZone.top
    };
    this.edits.rotationCount = (count) % 4; // 0-3 times with wrap-around
  }
  
  rotateCounterClockwise () {
    this.setRotationEdits(this.edits.rotationCount + 1);
  }
  
  rotateClockwise () {
    this.setRotationEdits(this.edits.rotationCount - 1);
  }

  cropToExterior () {
    this.setupUneditedImage();

    const exteriorCrop = contain(
      this.edits.cropZone.width,
      this.edits.cropZone.height,
      this.focusedArtwork().exterior.widthInInches,
      this.focusedArtwork().exterior.heightInInches
    );

    this.setCropZone({
      left: exteriorCrop.x + this.edits.cropZone.left,
      top: exteriorCrop.y + this.edits.cropZone.top,
      width: exteriorCrop.width,
      height: exteriorCrop.height
    });

    return exteriorCrop;
  }

  toggleBrighten () {
    if (this.edits.brighten) {
      this.edits.brighten = false;
    } else {
      this.edits.brighten = true;
    }
  }

  toggleGrayscale () {
    if (this.edits.grayscale) {
      this.edits.grayscale = false;
    } else {
      this.edits.grayscale = true;
    }
  }

  setCropZone (coordinates) {
    this.edits.cropZone = coordinates;
  }

  clearArtwork () {
    this.frameSpec.artworks = [];
  }

  static isSafariOrChromeOnIOS () {
    return /^Mozilla\/5\.0 \(iP.*\) AppleWebKit\/.* \(KHTML, like Gecko\) (?:CriOS|Version)\/.* Mobile\/.* (?:Safari|Chrome)\/.*$/.test(navigator.userAgent);
  }

  static iPadBackupCheck () {
    return navigator.userAgent.includes("Mac") && "ontouchend" in document;
  }
  
  async setupUneditedImage () {
    if (!this.uneditedImage) {
      // We have to draw our original image on a canvas to provide to ImageJS
      // We are lazy and only set this up if we have to, and only once
      const img = this.img;
      const canvas = document.createElement("canvas");
      const artworkObject = this.useNormalized
        ? this.focusedArtwork().normalizedUnedited
        : this.focusedArtwork().originalUnedited || this.focusedArtwork().customerSpecified;

      this.targetWidth = artworkObject.dimensionsInPixels.width;
      this.targetHeight = artworkObject.dimensionsInPixels.height;


      if (ArtworkEditor.isSafariOrChromeOnIOS() || ArtworkEditor.iPadBackupCheck()) {
        const ratio = this.targetWidth / this.targetHeight;

        if (this.targetWidth > 4096) {
          canvas.width = 4096;
          this.targetWidth = 4096;
          this.targetHeight = Math.floor(this.targetWidth / ratio);
        }
  
        if (this.targetHeight > 4096) {
          canvas.height = 4096;
          this.targetHeight = 4096;
          this.targetWidth = Math.floor(this.targetHeight * ratio);
        }
      }
      
      canvas.width = this.targetWidth;
      canvas.height = this.targetHeight;
      artworkObject.dimensionsInPixels.width = this.targetWidth;
      artworkObject.dimensionsInPixels.height = this.targetHeight;

      // Ensure crop zone stays within the boundaries
      this.edits.cropZone.width = Math.min(this.edits.cropZone.width, this.targetWidth);
      this.edits.cropZone.height = Math.min(this.edits.cropZone.height, this.targetHeight);
      this.edits.cropZone.left = Math.min(this.edits.cropZone.left, this.targetWidth - this.edits.cropZone.width);
      this.edits.cropZone.top = Math.min(this.edits.cropZone.top, this.targetHeight - this.edits.cropZone.height);

      const ctx = canvas.getContext("2d");

      ctx.drawImage(img, 0, 0, this.targetWidth, this.targetHeight);

      const ImageJS = await import("./image-js.min.js");
      this.ImageJS = ImageJS.default.Image;

      if(!this.ImageJS) {
        this.ImageJS = ImageJS.default;
      }

      this.uneditedImage = this.ImageJS.fromCanvas(canvas);
    }
  }

  async applyEdits (applyCrop = true) {
    await this.setupUneditedImage();

    // Each time we start from the initial image, and then
    // apply the different edits in sequence
    let localImage = this.uneditedImage;

    if (this.edits.grayscale) {
      localImage = localImage.grey();
    }

    if (this.edits.brighten) {
      localImage = localImage.clone().add(10);
    }

    // Rotate before cropping
    // Assume the cropZone is after rotation
    if (this.edits.rotationCount !== 0) {
      localImage = localImage.rotate(-90 * this.edits.rotationCount);
    }

    // The only thing that doesn't want the crop already applied is the cropper
    if (applyCrop && this.edits.cropZone) {
      localImage = localImage.crop({
        x: this.edits.cropZone.left,
        y: this.edits.cropZone.top,
        width: this.edits.cropZone.width,
        height: this.edits.cropZone.height
      });
      this.setEditedImageDimensions(this.edits.cropZone.width, this.edits.cropZone.height);
    } else {
      this.setEditedImageDimensions(localImage.width, localImage.height);
    }

    // We should only try to load the mask if the product has a Cropping Mask.
    if (this.maskImageUrl) {
      const maskImage = await this.ImageJS.load(this.maskImageUrl);

      const maskImageRatio = maskImage.width / maskImage.height;
      const localImageRatio = localImage.width / localImage.height;
      const acceptableDifference = 0.01;
      const maskAndLocalMatchRatios = Math.abs(maskImageRatio - localImageRatio) < acceptableDifference;

      if (applyCrop && maskAndLocalMatchRatios) {
        localImage = await this.applyMaskToImage(localImage, maskImage);
      } else if (applyCrop) {
        console.error("Error applying Cropping Mask.");
        const values = {
          maskImageRatio,
          localImageRatio,
          acceptableDifference,
          maskAndLocalMatchRatios
        };
        console.error(values);
      }
    }

    // Now that we've applied the edits we update our image
    let blob;
    if (localImage.alpha && localImage.countAlphaPixels({ alpha: 0 })) {
      // Images set to b&w have an alpha value of 0, which errors on countAlphaPixels
      // If alpha is not 0, countAlphaPixels counts transparency, so we should save it as a png
      blob = await localImage.toBlob("image/png");
    } else {
      // Image doesn't have transparency so we should save it as a jpg
      blob = await localImage.toBlob("image/jpeg");
    }

    await this.updatePreviewFromBlob(blob);
  }

  async applyMaskToImage (localImage, maskImage) {    
    // Ensure both images have the same dimensions
    const resizedMaskImage = maskImage.resize({ width: localImage.width, height: localImage.height });

    const combinedImage = localImage.clone();

    for (let x = 0; x < localImage.width; x++) {
      for (let y = 0; y < localImage.height; y++) {
        const pixel = resizedMaskImage.getPixelXY(x, y);
    
        // Replace localImage pixel with maskImage pixel unless maskImage pixel is transparent
        if (pixel[3] !== 0) { // Check if alpha channel is not transparent
          combinedImage.setPixelXY(x, y, pixel);
        }
      }
    }

    return combinedImage;
  }

  async saveToServer () {
    let blob = this.currentBlob;
    if (!blob) {
      await this.applyEdits();
      blob = this.currentBlob;
    }
    if (!blob.name) {
      blob.name = this.focusedArtwork().imageFileName || "customerArtwork";
    }

    const reader = new FileReader();
    const value = await getImageErrorsForFile(
      blob, reader, this.checkForQuality, this.fixedArtWidth, this.fixedArtHeight
    );
    const result = await artworkUploader({
      file: blob,
      client: this.glazeClient,
      artwork: {
        convex_metadata: value,
        use_convex: true,

        // Base this new artwork off of the original+edits
        artwork_id: this.frameSpec.artworks[this.artworkKey].artworkId,
        artwork_token: this.frameSpec.artworks[this.artworkKey].token,
        edits: this.edits
      }
    });

    // formats uploader response to match frameSpec data
    // and keeps other values assigned in previous steps
    const updatedArtworkSpec = snackCaseToCamelCase(normalizedArtworkData(result.data));
    const artworkDataForTransform = this.frameSpec.artworks[this.artworkKey];
    artworkDataForTransform.edits = this.edits;
    this.frameSpec.artworks[this.artworkKey] = {
      ...artworkDataForTransform,
      ...updatedArtworkSpec,
      imageFileName: blob.name
    };
  }

  destroy () {
    // Clean up our memory holding the current edited image
    // This is especially important since we are an SPA (no page reload)
    if (this.currentImageObjectURL) {
      URL.revokeObjectURL(this.currentImageObjectURL);
    }
  }
}

export default ArtworkEditor;
