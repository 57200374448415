import {
  accentMatName,
  canvasMatName,
  defaultAccentMatReveal,
  floatMountMatName,
  floatMountMatReveal,
  jerseyMatName,
  noMatName,
  heartstagramMatName,
  acrylicMatName,
  whiteMatName,
  stripedMats,
  PHYSICAL_CONVEYANCES,
  DIGITAL_CONVEYANCES
} from "./hardCodedValues";
import { determineSizeGroup, topMatSku } from "./framingUtilities";
import { isMatMultiOpening } from "./multiArtworkFramingUtility";
import { populateFromUrlParams } from "./urlFrameSpec/reader";
import { proportionalArtworkDimensions } from "./proportionalArtworkDimensions.js";
import { snackCaseToCamelCase } from "./objectConverter";
import { getEditingLineItemNumber } from "./editingLineItem";
import ArtworkEditor from "./ArtworkEditor";
import axios from "axios";
import frameSpecValidators from "./validators/frameSpecValidators";
import JoineryClient from "./apihelpers/glazeClient";
import hardCodedFrameSpecGenerator from "./hardCodedFrameSpecGenerator";
import urlFrameSpecMap from "./urlFrameSpec/map";
import urlFrameSpecWriter from "./urlFrameSpec/writer";
import presetMatWidths from "./presetMatWidths";

import { uniqBy, cloneDeep, omit, omitBy, isEmpty, pick } from "lodash";
import { encode, decode } from "base-64";

export default class FrameSpec {
  constructor (frameSpecData, joineryToken, joineryHost, shopifyHost, internalCall) {
    if (!internalCall) {
      throw new Error("You must use the `build` constructor");
    }

    if (!frameSpecData) {
      throw new Error("Missing required build parameter frameSpecData");
    }

    if (shopifyHost && !joineryHost) {
      throw new Error("Missing required build parameter joineryHost when in Shopify Mode");
    }

    Object.assign(this, frameSpecData);
    this.joineryHost = joineryHost;
    this.joineryClient = new JoineryClient(joineryToken, { rejectOnNotOk: true, baseUrl: joineryHost });
    this.joineryToken = joineryToken;
    this.errors = {};
    this.shopifyHost = shopifyHost || (
      window && window.Shopify && window.Shopify.routes.root
    );
  }

  static async build ({
    frameSpecData,
    number,
    authorizationToken,
    orderToken,
    token,
    joineryHost,
    query = {},
    productSlug,
    templateOptions,
    artworksVersion,
    shopifyHost,
    artwork,
    snapshot
  }) {
    let localFrameSpecData;

    if (frameSpecData) {
      localFrameSpecData = frameSpecData;
    } else if (number) {
      try {
        const authHeaders = authorizationToken ? { "Framebridge-Resource-Authorization-Token": authorizationToken } :
                            orderToken ? { "X-Spree-Order-Token": orderToken } :
                            {};
        const additionalParams = artworksVersion ? `?artworks_version=${artworksVersion}` : "";
        const result = await axios.get(`${joineryHost}/api/v1/framing_specifications/${number}${additionalParams}`, { headers: authHeaders });

        if (result.data) {
          localFrameSpecData = result.data;
        } else {
          throw ({data: {error: "No data for this frameSpec"}});
        }
      } catch (error) {
        throw new Error(error);
      }
    } else {
      localFrameSpecData = populateFromUrlParams(query);

      if (Object.keys(localFrameSpecData).length === 0) {
        localFrameSpecData = hardCodedFrameSpecGenerator(templateOptions || { withArtwork: false });
      }
    }

    // if an orderToken is provided, store it in the framespec for further use
    if (orderToken) {
      localFrameSpecData.orderToken = orderToken;
    }

    // if an authorizationToken was provided, store it in the framespec for further use
    if (authorizationToken) {
      localFrameSpecData.authorizationToken = authorizationToken;
    }

    // If we are provided an explicit product/variant then override the frameSpec with those
    localFrameSpecData.catalog = localFrameSpecData.catalog || {};
    localFrameSpecData.catalog.shopifyProductId = query["product"] || localFrameSpecData.catalog.shopifyProductId;
    localFrameSpecData.catalog.shopifyVariantId = query["variant"] || localFrameSpecData.catalog.shopifyVariantId;

    if (artwork) {
      localFrameSpecData.artworks = [artwork];
    }

    if (localFrameSpecData.artworks && localFrameSpecData.artworks[0] && !localFrameSpecData.artworks[0].offset) {
      localFrameSpecData.artworks[0].offset = {
        topInInches: "auto",
        leftInInches: "auto"
      }
    }

    if (snapshot) {
      localFrameSpecData.snapshotMode = true;
    }

    const frameSpec = new FrameSpec(localFrameSpecData, token, joineryHost, shopifyHost, true);

    if (frameSpec.catalog.shopifyProductId) {
      await frameSpec.loadShopifyProduct();
    }

    return frameSpec;
  }


  hasShopifyProductAndVariant () {
    return Boolean(this.catalog && this.catalog.shopifyProductId && this.catalog.shopifyVariantId);
  }

  async loadShopifyProduct() {
    this.product = await this.retrieveProductById(this.catalog.shopifyProductId);

    // if it's a post purchase dc product, switch to load the framespec moulding product for snapshot.
    // ideally we will remove the snapshot mode, but currently DC still displays the product/pricing for that product
    if (this.isDesignersChoiceProduct() && this.isPostPurchase() && this.snapshotMode) {
      this.product = null;
      return this.setProductFromMouldingShopify();
    } else if (this.isDesignersChoiceProduct() && !this.isPostPurchase() && !this.designersChoiceRequested) {
      // this is the case when the framespec gets built after adding to cart but before order placed/line item created
      this.designersChoiceRequested = true;
    }

    let defaultVariant = this.product.variants[0];

    if (this.catalog.shopifyVariantId) {
      const onLoadDefault = this.product.variants.find((variant) => {
        return variant.shopifyVariantId === this.catalog.shopifyVariantId;
      });

      if (onLoadDefault) {
        defaultVariant = onLoadDefault;
      }
    }

    if (!this.hasArtwork() && !this.isCustomProduct()) {
      const shopifyCatalog = this.catalog;
      Object.assign(this, cloneDeep(defaultVariant.frameSpec));
      delete this.id;
      delete this.number;
      delete this.giftBox;
      delete this.templateIdentifier;

      this.catalog = shopifyCatalog;
    } else if (this.hasArtwork() && !this.isCustomProduct() && !this.isDesignersChoiceProduct()) {

      const catalogVariant = this.product.variants.find(variant => {
        return variant.shopifyVariantId === this.catalog.shopifyVariantId &&
              variant.frameSpec &&
              variant.frameSpec.artworks &&
              variant.frameSpec.artworks[0] &&
              variant.frameSpec.artworks[0].exterior;
      });

      // if a square is selected, don't try to match on artwork orientation, keep the square
      // else, look for a variant with the same orientation as the uploaded artwork
      if (catalogVariant && catalogVariant.frameSpec.artworks[0].exterior.widthInInches !== catalogVariant.frameSpec.artworks[0].exterior.heightInInches) {
        const orientation = this.getOrientation();
        const sameFrameStyleVariants = this.getSameFrameStyleVariants();

        if (sameFrameStyleVariants.length) {
          let orientedVariants = sameFrameStyleVariants.filter((variant) => {
            if (!variant.frameSpec.artworks[0] || !variant.frameSpec.artworks[0].exterior) {
              return false;
            }

            const width = variant.frameSpec.artworks[0].exterior.widthInInches;
            const height = variant.frameSpec.artworks[0].exterior.heightInInches;

            if (orientation === 'landscape') {
              return width > height;
            } else if (orientation === 'portrait') {
              return height > width;
            } else if (orientation === 'square') {
              return width === height;
            }
            return false;
          });

          if (orientedVariants.length) {
            defaultVariant = orientedVariants.find((variant) => {
              // Check if the catalog variant is in matching orientation options
              if (variant.shopifyVariantId === this.catalog.shopifyVariantId) {
                return true;
              }
              // Otherwise check if a matching orientation option has the same overall size as the catalog variant
              return variant.frameSpec.artworks[0].exterior.widthInInches === catalogVariant.frameSpec.artworks[0].exterior.heightInInches &&
                    variant.frameSpec.artworks[0].exterior.heightInInches === catalogVariant.frameSpec.artworks[0].exterior.widthInInches;
            }) || orientedVariants[0];
          }
        }
      }

      const shopifyCatalog = this.catalog;
      const variantSpecExterior = defaultVariant.frameSpec.artworks[0].exterior;
      const variantSpec = cloneDeep(defaultVariant.frameSpec);
      delete variantSpec.giftBox;
      delete variantSpec.artworks;
      delete variantSpec.previewImage;
      delete variantSpec.pricing;
      delete variantSpec.id;
      delete variantSpec.number;
      delete variantSpec.templateIdentifier;
      delete variantSpec.storyPocket;
      
      // Store the possibly-customized mat caption and mouldingPlate
      const matCaption = this.mats[0] ? this.mats[0].matCaption : false;
      const mouldingPlate = this.moulding.mouldingPlate ? this.moulding.mouldingPlate : false;

      Object.assign(this, variantSpec);

      // Add personalization back in
      if (matCaption) {
        this.setMatCaption(matCaption);
      }

      if (mouldingPlate) {
        this.setMouldingPlate(mouldingPlate);
      }  

      this.artworks[0].exterior = variantSpecExterior;
      this.catalog = shopifyCatalog;
    }

    if (this.isCustomProduct() && !this.isPostPurchaseDesignersChoiceProduct() && !this.isPostPurchaseJersey()) {
      this.moulding.permalink = this.product.handle;
    }

    await this.updateVariantAndConstraints();

    // Legacy shortcut
    this.fixedSize = this.isFixedSizeProduct();

    await this.calculatePrice();

    return this;
  }

  // Use this when you intend to use both the new and the old frameSpecs
  // separately, so each gets its own joinery FS-num and database id
  duplicate () {
    const clonedCopy = this.clone();
    delete clonedCopy.id;
    delete clonedCopy.number;
    return clonedCopy;
  }

  // Use this when you want a copy of the frameSpec for validation or
  // modification but you don't need to generate a new FS-number. For example,
  // you make a clone, make some changes, validate those changes, and then
  // start using the clone instead of the original, overwriting the original in
  // joinery when you call .saveToServer().
  clone () {
    return new FrameSpec(cloneDeep(this), this.joineryToken, this.joineryHost, this.shopifyHost, true);
  }

  // FrameSpec Generators ↑
  // FrameSpec Getters ↓

  isValid (options) {
    const defaultValidations = {
      mats: true,
      mount: true,
      artworks: true,
      moulding: true
    };

    const validations = { ...defaultValidations, ...options };

    const results = {
      mats: validations.mats ? frameSpecValidators.validMatsSpec(this.mats) : true,
      mount: validations.mount ? frameSpecValidators.validMountSpec(this.mount) : true,
      artworks: validations.artworks ? frameSpecValidators.validArtworksSpec(this.artworks) : true,
      moulding: validations.moulding ? frameSpecValidators.validMouldingSpec(this.moulding) : true
    };

    const valid = results.mats && results.mount && results.artworks && results.moulding;

    if (!valid) {
      this.errors.validity = results;
    } else {
      this.errors.validity = null;
    }

    return valid;
  }

  isInStoreFulfillable () {
    return Boolean(this.product && this.product.tags?.includes('In-Store Fulfillable'));
  }

  async isManufacturable () {
    try {
      const result = (await axios.post(
        `${this.joineryHost}/api/v1/framing_specification_pricings`,
        {
          framing_specification: this
        })).data;

      if (!result.valid) {
        this.errors.manufacturability = [result.errors];
      }

      return result.valid;
    } catch (error) {
      this.errors.manufacturability = [error];

      return false;
    }
  }

  isCustomProduct () {
    if (!this.product) {
      return true;
    }
    return this.product.productType === "Custom Frame" || this.isPostPurchaseDesignersChoiceProduct() || this.isPostPurchaseJersey();
  }

  isCuratedDesignFrame () {
    return !Boolean(this.isCustomProduct() || this.isGetThisLookProduct());
  }

  isFixedSizeProduct () {
    return !Boolean(this.isCustomProduct() || this.isGetThisLookProduct());
  }

  isGetThisLookProduct () {
    if (this.product) {
      return this.product.productType === "Get This Look";
    }
  }

  originatedAsGetThisLookProduct () {
    return Boolean(this.catalog.shopifyGetThisLookProductId);
  }

  async retrieveGetThisLookProduct () {
    if (this.catalog.shopifyGetThisLookProductId) {
      return await this.retrieveProductById(this.catalog.shopifyGetThisLookProductId);
    } else {
      return null;
    }
  }

  async retrieveGetThisLookVariant () {
    if (this.catalog.shopifyGetThisLookProductId && this.catalog.shopifyGetThisLookVariantId) {
      const product = await this.retrieveProductById(this.catalog.shopifyGetThisLookProductId);
      const variant = product.variants.find((variant) => variant.shopifyVariantId === this.catalog.shopifyGetThisLookVariantId);
      const frameSpecObj = await FrameSpec.build({
        frameSpecData: variant.frameSpec,
        joineryHost: this.joineryHost,
        shopifyHost: this.shopifyHost
      });
      variant.frameSpec = frameSpecObj;

      return variant;
    } else {
      return null;
    }
  }

  isPrintableArtProduct () {
    return this.product && this.product.productType === "Art Print Frame";
  }

  isReadymadeProduct () {
    return Boolean(this.product && this.product.readymade);
  }

  isReadymadeAcrylicProduct () {
    return Boolean(
      (this.variant && this.variant.sku && this.variant.sku.startsWith("RM")) ||
      (this.mount && this.mount.name === acrylicMatName && !this.mount.elevated)
    );
  }

  isQuickShipProduct () {
    return false;
  }

  isDesignersChoiceProduct () {
    return Boolean(
      (this.product && this.product.is_designers_choice) ||
      (this.product && this.product.productType === "Designers Choice")
    );
  }

  isPostPurchase() {
    return Boolean(this.orderNumber || this.lineItemNumber);
  }

  isPostPurchaseDesignersChoiceProduct () {
    return Boolean(this.designersChoiceRequested && this.isPostPurchase());
  }

  isPostPurchaseJersey () {
    return Boolean(this.isJersey() && this.isPostPurchase());
  }

  isHeartstagramMat () {
    if (this.mats[0]) {
      return Boolean(this.mats[0].name === heartstagramMatName);
    } else {
      return false;
    }
  }

  isTableTopFrame () {
    return this.hasProductTag("Tabletop Frames");
  }

  isOrnamentFrame () {
    return this.hasProductTag("Ornament") || this.moulding.permalink.includes("ornament");
  }

  hasProductTag (tag) {
    return Boolean(this.product && this.product.tags && this.product.tags.includes(tag));
  }

  productCategory () {
    const artwork = this.artworks[0];
    if (!artwork) return null;

    const { productCategory } = artwork;
    if (typeof productCategory === "string") {
      return productCategory;
    } else if (productCategory && productCategory.slug) {
      return productCategory.slug;
    } else {
      return null;
    }
  }

  conveyance () {
    return this.artworks[0] ? this.artworks[0].conveyance : null;
  }

  isDigital () {
    return DIGITAL_CONVEYANCES.includes(this.conveyance());
  }

  isPhysical () {
    return PHYSICAL_CONVEYANCES.includes(this.conveyance());
  }

  conveyanceType ({ fullName = false } = {}) {
    return this.isDigital()
      ? `Digital${fullName ? ' Photo' : ''}`
      : `Physical${fullName ? ' Art' : ''}`;
  }

  isJersey () {
    return this.productCategory() === "jersey";
  }

  isCanvas () {
    return this.productCategory() === "canvas";
  }

  isMultiArtwork () {
    return Boolean(
      this.variant &&
      this.variant.frameSpec &&
      this.variant.frameSpec.mats[0] &&
      isMatMultiOpening(this.variant.frameSpec.mats[0].name)
    );
  }

  async matPresentation () {
    const matOptions = await this._getAllMats();

    if (this.isFloated()) {
      if (this.isClearFloated()) {
        const acrylicMat = matOptions.find(mat => mat.name === acrylicMatName);
        return acrylicMat ? acrylicMat.presentation : "Clear Float";
      } else {
        const floatMountColor = matOptions.find(m => m.name == this.mount.name);
        return floatMountColor ? `Float Mount - ${floatMountColor.presentation}` : "Float Mount";
      }
    }
    if ((!this.isDesignersChoiceProduct() && this.hasMat()) ||
        (this.isDesignersChoiceProduct() && this.hasMat() && this.isPostPurchase())
    ) {
      let matPresentation = "";
      const matOption = matOptions.find(m => m.name == this.mats[0].name);
      if (matOption) {
        matPresentation = matOption.presentation;
      }

      let accentMatPresentation = "";
      if (this.hasAccentMat()) {
        const accentMatOption = matOptions.find(m => m.name == this.mats[1].name);
        if (accentMatOption) {
          accentMatPresentation = `${accentMatOption.presentation} Accent Mat`;
        }
      }

      return [matPresentation, accentMatPresentation].filter(Boolean).join(", ");
    }

    return "N/A";
  }

  frameStyle () {
    if (this.isCuratedDesignFrame()) {
      return this.variant.frameStyle;
    } else {
      return this.product.productSubtitle;
    }
  }

  hasMat () {
    return Boolean(this.mats[0]);
  }

  hasAccentMat () {
    return Boolean(this.mats[1]);
  }

  hasEightPlyMat () {
    return Boolean(this.mats[0] && this.mats[0].matStyle === "8-ply");
  }

  hasLinenMat () {
    return Boolean(this.mats[0] && this.mats[0].matStyle === "linen");
  }

  hasStripedMat () {
    return Boolean(this.mats[0] && stripedMats.includes(this.mats[0].name));
  }

  hasPremiumMat () {
    return Boolean(this.price.price_breakdown.find((item) => item.key === "premium_mat"));
  }

  hasSilkMat () {
    return Boolean(this.mats[0] && this.mats[0].matStyle === "silk");
  }

  hasMetallicMat () {
    // these may get moved to their own style, if so we can add a style and check for metallic style here
    return Boolean(this.mats[0] && (this.mats[0].name === "E4213P" || this.mats[0].name === "11-064"));
  }

  hasMatCaption () {
    return Boolean(this.hasMat() && this.mats[0].matCaption);
  }

  hasAsymmetricalMat () {
    if (!this.hasMat()) {
      return false;
    }

    return Boolean(new Set(Object.keys(this.mats[0].reveal).map((key) => this.mats[0].reveal[key])).size > 1); // Thanks Copilot
  }

  hasMatCustomizations () {
    if (!this.hasMat()) {
      return false;
    }

    return this.hasMatCaption() ||
      this.hasAccentMat() ||
      this.hasPremiumMat() ||
      this.hasAsymmetricalMat();
  }

  isFloated () {
    return this.mount.elevated;
  }

  isClearFloated () {
    return this.isFloated() && this.mount.name === acrylicMatName;
  }

  validFloatSize () {
    const exterior = this.getArtwork().exterior;
    const longSide = Math.max(exterior.widthInInches, exterior.heightInInches);
    const shortSide = Math.min(exterior.widthInInches, exterior.heightInInches);
    if (shortSide <= 29 && longSide <= 37) {
      return true;
    } else {
      return false;
    }
  }

  categoryRequiresFloat () {
    const productCategory = this.productCategory()
    const floatedCategories = ["textile", "object"];
    return Boolean(floatedCategories.includes(productCategory));
  }

  isOversized () {
    if (this.hasArtwork()) {
      const finalFrameSize = this.finalFrameSize;
      const minFFSDim = Math.min(finalFrameSize.width, finalFrameSize.height);
      const maxFFSDim = Math.max(finalFrameSize.width, finalFrameSize.height);

      return minFFSDim > 35.5 || maxFFSDim > 47.5;
    } else {
      return false;
    }
  }

  hasArtwork () {
    if (!this.artworks) {
      return false;
    }

    if (
      this.variant &&
      this.variant.mat &&
      this.variant.frameSpec &&
      isMatMultiOpening(this.variant.frameSpec.mats[0].name)
    ) {
      return Boolean(
        this.artworks[0] &&
        this.artworks[0].completedMafMatName &&
        this.variant.frameSpec.mats[0].name === this.artworks[0].completedMafMatName
      );
    } else {
      return Boolean(
        this.artworks[0] &&
        this.artworks[0].artworkId &&
        frameSpecValidators.validArtworkSpec(this.artworks[0])
      );
    }
  }

  hasTemplateArtwork () {
    const artwork = this.getArtwork();
    if (!artwork || !this.product) {
      return false;
    }

    let possibleVariants = this.product.variants.filter(v => v.frameSpec);
    if (!possibleVariants.length) {
      return false;
    } else {
      const matchingArtwork = possibleVariants.filter( v => v.frameSpec.artworks[0].artworkId === artwork.artworkId );
      return matchingArtwork.length > 0;
    }
  }

  needsCrop () {
    if (this.isPrintableArtProduct() || !this.hasArtwork()) {
      return false;
    }

    const firstArtwork = this.getArtwork();

    const expectedFixedSizeRatio =
      firstArtwork.exterior.widthInInches / firstArtwork.exterior.heightInInches;

    const pixelWidth = firstArtwork.customerSpecified.dimensionsInPixels
      ? firstArtwork.customerSpecified.dimensionsInPixels.width
      : firstArtwork.originalUnedited.dimensionsInPixels.width;
    const pixelHeight = firstArtwork.customerSpecified.dimensionsInPixels
      ? firstArtwork.customerSpecified.dimensionsInPixels.height
      : firstArtwork.originalUnedited.dimensionsInPixels.height;

    const artworkRatio = pixelWidth / pixelHeight;

    return Boolean(Math.abs(artworkRatio - expectedFixedSizeRatio) > 0.001);
  }

  canPrintArtwork () {
    if (!this.hasArtwork() || this.isCustomProduct() || this.isPrintableArtProduct()) {
      return true;
    }
    const firstArtwork = this.getArtwork();

    const artworkWidth = firstArtwork.exterior.widthInInches;
    const artworkHeight = firstArtwork.exterior.heightInInches;

    let longSide = artworkWidth;
    let shortSide = artworkHeight;

    if (artworkHeight >= artworkWidth) {
      longSide = artworkHeight;
      shortSide = artworkWidth;
    }

    const pixelWidth = firstArtwork.customerSpecified.dimensionsInPixels
      ? firstArtwork.customerSpecified.dimensionsInPixels.width
      : firstArtwork.originalUnedited.dimensionsInPixels.width;
    const pixelHeight = firstArtwork.customerSpecified.dimensionsInPixels
      ? firstArtwork.customerSpecified.dimensionsInPixels.height
      : firstArtwork.originalUnedited.dimensionsInPixels.height;

    const sizeMatch = proportionalArtworkDimensions(
      pixelWidth,
      pixelHeight,
      shortSide,
      longSide
    );

    return Boolean(sizeMatch.length);
  }

  getArtwork (index = 0) {
    return this.artworks ? this.artworks[index] : null;
  }

  getOrientation () {
    if (this.getArtwork()) {
      const width = this.getArtwork().exterior.widthInInches;
      const height = this.getArtwork().exterior.heightInInches;

      if (width > height) {
        return "landscape";
      } else if (height > width) {
        return "portrait";
      } else {
        return "square";
      }
    } else {
      return null;
    }
  }

  getCatalogVariant () {
    return this.product.variants.find(variant => variant.shopifyVariantId === this.catalog.shopifyVariantId);
  };

  getSameFrameStyleVariants () {
    const catalogVariant = this.getCatalogVariant();
    if (!catalogVariant) {
      return [];
    } else {
      return this.product.variants.filter(variant => variant.frameStyle === catalogVariant.frameStyle);
    }
  };

  sizeGroup () {
    const hasExterior = this.artworks && this.artworks[0] ? this.artworks[0].exterior : false;
    const widthInInches = hasExterior ? this.artworks[0].exterior.widthInInches : undefined;
    const heightInInches = hasExterior ? this.artworks[0].exterior.heightInInches : undefined;
    const conveyance = this.isCustomProduct() ? "uploaded" : this.artworks[0].conveyance;

    return determineSizeGroup(widthInInches, heightInInches, conveyance);
  }

  checkValidVariantForSizeGroup (moulding) {
    if (!this.sizeGroup()) {
      return null;
    };
    const sizeGroup = this.sizeGroup().name;
    let possibleVariants = [...moulding.variants];

    possibleVariants = possibleVariants.filter( v => v.selectedOptions[0].value === sizeGroup );
    const variant = possibleVariants[0];

    if (possibleVariants.length === 0) {
      return "This moulding is not available in this size";
    } else if (variant.inventoryQuantity < 1 && variant.inventoryPolicy !== "CONTINUE") {
      return "This moulding is currently out of stock";
    }

  }

  urlParams (options = {}) {
    if (options.stringify) {
      return urlFrameSpecWriter.frameSpecToQuery(this);
    } else {
      return urlFrameSpecWriter.buildParamsObject(this, urlFrameSpecMap.shortFrameSpecMap);
    }
  }

  compactFrameSpec () {
    // reduces size of frameSpec for api calls
    const compactedSpec = omit(this, ['_allMouldingOptions', 'price', 'product']);
    return compactedSpec;
  }

  async calculatePrice () {
    if (this.product) {
      if (
        this.isReadymadeProduct() &&
        !this.isValid({ artworks: false, mats: false })
      ) {
        this.price = null;
        return null;
      } else if (!this.isReadymadeProduct() && !this.isValid({ artworks: false })) {
        this.price = null;
        return null;
      }

      try {
        const variantId = !this.isCustomProduct() && this.variant ? this.variant.id : null;

        const pricingResponse = await axios.post(
          `${this.joineryHost}/api/v1/framing_specification_pricings`, {
            variant_id: variantId,
            framing_specification: this.compactFrameSpec()
          }
        );

        this.price = pricingResponse.data;
        return pricingResponse.data;
      } catch (error) {
        console.error("Error from setPriceFromFrameSpec");
        this.price = null;
        return error;
      }
    } else {
      try {
        const pricingResponse = await axios.post(
          `${this.joineryHost}/api/v1/framing_specification_pricings`,
          { framing_specification: this.compactFrameSpec() }
        );
        this.price = pricingResponse.data;
        return pricingResponse.data;
      } catch (error) {
        console.error("Error from setPriceFromFrameSpec", this.moulding.permalink);
        this.price = null;
        return error;
      }
    }
  }

  async calculateFinalFrameSize () {
    try {
      const frameSizeResponse = await axios.post(
        `${this.joineryHost}/api/v1/framing_specification_frame_size`, {
          framing_specification: this.compactFrameSpec()
        }
      );

      this.finalFrameSize = frameSizeResponse.data;
      return frameSizeResponse.data;
    } catch (error) {
      this.finalFrameSize = null;
      console.error("Error from calculateFinalFrameSize");
      console.error(error);
    }
    return;
  }

  // *************************
  // * Design Option Helpers *
  // *************************


  async _getAllMouldings(params = {}) {
    const mouldingsUrl = `${this.joineryHost}/api/v1/joinery_mouldings.json`;
    const allMouldingsResult = await axios.get(mouldingsUrl, { params });

    return allMouldingsResult.data
  }

  async getMouldingOptions (collection_handle, forceRefetch = false) {
    try {
      let allMouldingOptions = this._allMouldingOptions;
      let collectionData;
      if (!allMouldingOptions || forceRefetch) {
        const params = collection_handle ? { collection_handle } : {};
        const allMouldingsResult = await this._getAllMouldings(params);
        // Only mouldings that have both joinery and shopify data
        allMouldingOptions =
          allMouldingsResult
          .mouldings
          .filter((moulding) => moulding.shopifyProductId)
          .filter((moulding) => moulding.shopifyAssets)
          .filter((moulding) => !moulding.product?.variants[0]?.discontinued);

        this._allMouldingOptions = allMouldingOptions;

        collectionData = allMouldingsResult.collection;
      }

      if (this.isCustomProduct()) {
        return {
          mouldings: allMouldingOptions,
          collection: collectionData
        };
      } else {
        const variantsMouldings = this.product.variants.map(v => v.frameSpec.moulding);

        // TODO: Ideally this would use joinerySku instead of the legacy spree permalink
        // But too many things assume frameSpec.moulding.permalink is a thing
        const availableMouldings = allMouldingOptions.filter((moulding) => {
          return this.product.variants.find((variant) => {
            return !variant.discontinued && variant.frameSpec.moulding.permalink === moulding.permalink;
          });
        });

        return {
          mouldings: availableMouldings,
          collection: collectionData
        };
      }
    } catch(error) {
      console.error("Error when fetching mouldings", error);
      return [];
    }
  }

  async getJoineryMouldings() {
    const mouldingsUrl = `${this.joineryHost}/api/v1/mouldings.json`;
    const allMouldingsResult = await axios.get(mouldingsUrl);
    return allMouldingsResult.data;
  }

  async getArtworkSizeOptions () {
    if (this.isCustomProduct()) {
      // Calculate based on physics of artwork DPI, moulding
    } else {
      // Based on variants
      const artworkSizes = this.product.variants.map( (v) => v.frameSpec.artworks[0].exterior );
      return uniqBy(artworkSizes, size => `${size.widthInInches} x ${size.heightInInches}`)
    }
  }

  parseStripedMats (matOptions) {
    // this is temporary
    // we should make the backend handle this

    const stripedSwatchMap = {
      'MSBG': 'swatch-burgundy-mini-stripe',
      'MSFR': 'swatch-forest-mini-stripe',
      'CSLB': 'swatch-light-blue-cabana-stripe',
      'MSNV': 'swatch-navy-mini-stripe',
      'CSRB': 'swatch-royal-blue-cabana-stripe',
      'CSST': 'swatch-stone-cabana-stripe',
      'MSBG-H': 'swatch-burgundy-mini-stripe-horizontal',
      'MSFR-H': 'swatch-forest-mini-stripe-horizontal',
      'CSLB-H': 'swatch-light-blue-cabana-stripe-horizontal',
      'MSNV-H': 'swatch-navy-mini-stripe-horizontal',
      'CSRB-H': 'swatch-royal-blue-cabana-stripe-horizontal',
      'CSST-H': 'swatch-stone-cabana-stripe-horizontal'
    };

    return matOptions.map((mat) => {
      const isStriped = !!stripedSwatchMap[mat.name]

      return {
        ...mat,
        style: isStriped ? "striped" : mat.style,
        surfaceTextureUrl: stripedSwatchMap[mat.name]
          ? `${this.shopifyHost}/apps/designer/mats/swatches/${stripedSwatchMap[mat.name]}.jpg`
          : mat.surfaceTextureUrl
      };
    });
  }
  async _getAllMats () {
    // Returns all unfiltered mats data from joinery
    const allMatOptions = await axios.get(`${this.joineryHost}/api/v1/mats.json`);
    if (allMatOptions.data) {
      return this.parseStripedMats(snackCaseToCamelCase(allMatOptions.data));
    }
    return null;
  }

  async getMatOptions () {
    // Returns mat options appropriate for the given frame type
    if (this.isCustomProduct()) {
      try {
        // Note: these will include internalOnly, and other flags that may need to be filtered in ui
        const matOptionData = await this._getAllMats();
        if (matOptionData) {
          return {
            allMatOptions: matOptionData,
            primaryMatOptions: matOptionData.filter( mat => !mat.disabled ),
            accentMatOptions: matOptionData.filter( mat => !mat.fake && !mat.disabled && mat.canBeAccentMat ),
            baseMountOptions: matOptionData.filter( mat => !mat.fake && !mat.disabled && mat.canBeBaseMat )
          }
        } else {
          console.log("no mat data returned");
          return {
            allMatOptions: [],
            primaryMatOptions: [],
            accentMatOptions: [],
            baseMountOptions: []
          }
        }
      } catch (error) {
        console.error("Error when fetching mat options", error);
        return {
          allMatOptions: [],
          primaryMatOptions: [],
          accentMatOptions: [],
          baseMountOptions: []
        }
      }
    } else {
      // tbd, we may not actually offer these options anywhere
      const primaryMatOptions = this.product.variants.map( (v) => v.frameSpec.mats[0] );
      const accentMatOptions = this.product.variants.map( (v) => v.frameSpec.mats[1] );
      return {
        primaryMatOptions: uniqBy(primaryMatOptions, mat => mat.name),
        accentMatOptions: uniqBy(accentMatOptions, mat => mat.name)
      }
    }
  }

  allowsMat (allowOversize = false, allowJersey = false) {
   if (this.isCanvas()) {
     return {
      value: false,
      reason: "canvas category"
     };
   } else if (this.isJersey() && !allowJersey) {
     return {
      value: false,
      reason: "jersey category"
     }
   } else if (this.sizeGroup() && (!allowOversize && this.sizeGroup().name === "GR")) {
     return {
      value: false,
      reason: "size group"
     };
   } else {
     return {
      value: true
     }
   }
  }

  async getCurrentMouldingOption () {
    const mouldingOptions = await this.getMouldingOptions();
    const currentMouldingOption = mouldingOptions.mouldings
      .filter( mouldingOption => mouldingOption.shopifyProductId )
      .find( mouldingOption => mouldingOption.permalink == this.moulding.permalink );
    return currentMouldingOption;
  }

  async allowsFloat () {
    const currentMoulding = await this.getCurrentMouldingOption();
    return currentMoulding && currentMoulding.floatAllowed;
  }

  async allowsClearFloat () {
    const currentMoulding = await this.getCurrentMouldingOption();

    const productCategoriesThatAllowClearFloat = ["digital_photo", "original_art", "diplomas_and_certificates", "prints_and_posters", "photography", "newspaper_and_magazine"];
    const productCategoryAllowsClearFloat = productCategoriesThatAllowClearFloat.includes(this.productCategory());

    return currentMoulding && currentMoulding.clearFloatAllowed && productCategoryAllowsClearFloat;
  }

  async allowsEightPlyMat () {
    const currentMoulding = await this.getCurrentMouldingOption();
    return currentMoulding && currentMoulding.eightplyMatAllowed;
  }

  async allowsAccentMat () {
    const currentMoulding = await this.getCurrentMouldingOption();
    return currentMoulding && currentMoulding.accentMatAllowed;
  }

  async mouldingPlateAllowed () {
    const currentMoulding = await this.getCurrentMouldingOption();
    return currentMoulding && currentMoulding.mouldingPlateAllowed;
  }

  storyPocketAllowed () {
    return Boolean(!this.isReadymadeAcrylicProduct() && !this.isClearFloated() && !this.isTableTopFrame());
  }

  async giftBoxSupportsMouldingHeight () {
    const currentMoulding = await this.getCurrentMouldingOption();
    return currentMoulding && currentMoulding.mouldingHeight <= 1.5;
  }

  getPresetMatWidthOptions () {
    const size = this.sizeGroup() && this.sizeGroup().name;
    if (this.isFloated()) {
      const presetSizes = presetMatWidths.sizes.float[size];
      const disallowedPresets = ["thin"];

      let filteredPresets = {};
      Object.entries(presetSizes).filter(
        (preset) => {
          if (!disallowedPresets.includes(preset[0])) {
            filteredPresets[preset[0]] = preset[1];
            return;
          } else {
            return;
          }
        }
      );
      return filteredPresets;
    } else {
      return presetMatWidths.sizes.regular[size];
    }
  }

  clearConvexRenderUrl () {
    this.previewImage = null;
  }

  getConvexRenderUrl (maxQuality = false, refetch = false) {
    if (this.previewImage && this.previewImage.url && !refetch) {
      return this.previewImage.url;
    } else {
      const clone = this.clone();
      clone.getArtwork().maxQuality = maxQuality;
      return `${this.convexBaseUrl()}/frame_spec_preview_image?${clone.urlParams({ stringify: true })}`;
    }
  }

  async downloadPreview (maxQuality = false) {
    const url = this.getConvexRenderUrl(maxQuality);
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = blobUrl;
      a.download = 'frame-preview.jpg';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      window.open(url, '_blank');
    }
  }

  async getFloatPrice () {
    // fetch price from product variant.
    // fallback to standard $25, this should probably be removed after feb 2024 price changes
    const host = this.joineryHost.includes("joinery") ? "prod" : "staging";
    const floatProductId = host === "prod" ? "9056842613051" : "8149413364002";
    try {
      const resp = await axios.get(`${this.joineryHost}/api/v1/shopify_product_details/${floatProductId}`);
      if (resp.data) {
        const floatProduct = resp.data;
        const possibleVariants = floatProduct.variants;
        const sizeGroup = this.sizeGroup() && this.sizeGroup().name;
        const sizeGroupVariant = possibleVariants.find( v => v.selectedOptions[0].value === sizeGroup );
        if (!sizeGroupVariant) {
          return "25"
        } else {
          return `${parseFloat(sizeGroupVariant.price)}`;
        }
      } else {
        return "25";
      }
    } catch (error) {
      console.log("error fetching float price");
      return "25";
    }
  }
  // FrameSpec Getters ↑
  // FrameSpec Setters and Mutations ↓

  async fetchUpdatedAt () {
    const authHeaders = { "Framebridge-Resource-Authorization-Token": this.authorizationToken };
    try {
      const result = await axios.get(`${this.joineryHost}/api/v1/framing_specifications/${this.number}/updated_at`, { headers: authHeaders });
      const updatedAt = result.data.updated_at;
      return updatedAt;
    } catch (error) {
      console.error('Error fetching updated_at:', error);
      throw error;
    }
  }

  async updateFromServer () {
    const authHeaders = { "Framebridge-Resource-Authorization-Token": this.authorizationToken };
    const result = await axios.get(`${this.joineryHost}/api/v1/framing_specifications/${this.number}`, { headers: authHeaders });
    const data = result.data;
    Object.assign(this, data);
    return this;
  }

  async saveToServer({
    regenerateId = false,
    processResponse = (data) => data
  } = {}) {
    let response;

    if (this.id && !regenerateId) {
      const path = `${this.joineryHost}/api/v1/framing_specifications/${this.id}`;
      const authHeaders = { "Framebridge-Resource-Authorization-Token": this.authorizationToken };
      const data = { framingSpecification: this.compactFrameSpec()  };
      response = await axios.put(path, data, { headers: authHeaders });
    } else {
      const path = `${this.joineryHost}/api/v1/framing_specifications`;
      const data = {
        framingSpecification: this.compactFrameSpec() ,
        without_spree_order_populate: true, // This param is ignored in joinery, should be safe to remove soon.
        include_token: true
      };
      response = await axios.post(path, data);
    }
    if (response) {
      const data = processResponse({
        ...response.data,
        offsetInBundle: this.offsetInBundle
      }, this.joineryHost);
      Object.assign(this, data);
    }
  }

  async addToCart ({lineItemNumber, skipAutoCrop, shopifyCustomerId, extraProperties, cartHost} = {}) {

    const localShopifyCustomerId = shopifyCustomerId || (
      window && window.SDG && window.SDG.Data && decode(window.SDG.Data.cid)
    );

    if (this.giftBox?.explicitlyRemoved) {
      delete this.giftBox;
    }

    if (!skipAutoCrop) {
      await this.autoCrop({ saveToServer: true });
    }

    if (!this.shopifyHost) {

      const payload = {
        variant_id: this.variant.id,
        framingSpecification: this,
        line_item_number: lineItemNumber || getEditingLineItemNumber()
      };

      const response = await axios.post("/orders/populate.json", payload);
      return response;

    } else {
      await this.calculatePrice();

      if (this.templateIdentifier) {
        delete this.templateIdentifier;
      }

      await this.saveToServer({regenerateId: Boolean(lineItemNumber)});

      const extraItems = [];
      this.price.price_breakdown.forEach( (priceBreakdown) => {
        if (priceBreakdown.shopify_variant) {
          extraItems.push({
            id: priceBreakdown.shopify_variant,
            properties: {
              "_parent_design": this.number
            }
          });
        }
      });

      const cartUrl = cartHost || "https://framebridge-cart.netlify.app";

      if (lineItemNumber) {
        await fetch(`${cartUrl}/api/cart/remove?cid=${encode(localShopifyCustomerId)}`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            lineId: lineItemNumber
          })
        });
      }

      if (!this.finalFrameSize) {
        await this.calculateFinalFrameSize();
      }

      const includes = this.price.price_breakdown.filter((item) => {
        return !["fixed_variant_price", "float_mount"].includes(item.key);
      }).map( (item) => {
        return item.presentation;
      }).join(", ")

      const conveyance = this.variant.selectedOptions
        && this.variant.selectedOptions.find((option) => option.name === "Conveyance");
      const artType = conveyance && conveyance.value ? `${conveyance.value} Art` : undefined;

      const previewUrl = this.getConvexRenderUrl(1000);

      const sizeAttrKey = this.isPhysical() ? "Art Size" : "Photo Size";
      let lineProperties = omitBy({
        "_frame_spec_number": this.number,
        "_frame_spec_authorization_token": this.authorizationToken,
        "Art Type": artType,
        [sizeAttrKey]: `${this.artworks[0].exterior.widthInInches}" x ${this.artworks[0].exterior.heightInInches}"`,
        "Frame Style": this.frameStyle(),
        "Mat Style": await this.matPresentation(),
        "Final Frame Size": `${this.finalFrameSize.width}" x ${this.finalFrameSize.height}"`,
        "Includes": includes,
        "_artwork_url": this.artworks[0].customerSpecified.url,
        "_original_artwork_url": this.artworks[0].originalUnedited.url,
        "_preview_url": previewUrl,
        "_oversized": JSON.stringify(this.isOversized()),
        "_get_this_look_product_id": this.catalog.shopifyGetThisLookProductId,
        "_get_this_look_variant_id": this.catalog.shopifyGetThisLookVariantId,
        ...(extraProperties || {})
      }, isEmpty);

      return await fetch(`${cartUrl}/api/cart/add?cid=${encode(localShopifyCustomerId)}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({items: [
          {
            id: this.catalog.shopifyVariantId,
            properties: lineProperties
          },
          ...extraItems
        ]})
      });
    }
  }

  async setVariantToClosestVariant (optionId) {
    let possibleVariants = this.product.variants;

    const possibleVariantsForOptionId = possibleVariants.filter((variant) => {
      const optionValuesWithCorrectId = variant.option_value_ids.filter((optionValueId) => {
        return optionValueId === optionId;
      });

      return optionValuesWithCorrectId.length;
    });

    if (!possibleVariantsForOptionId.length) {
      this.variant = possibleVariants[0];
      await this.enforceFixedSizeVariant();
      return;
    }

    possibleVariants = possibleVariantsForOptionId;

    this.variant.option_value_ids.forEach((previousOptionValueId) => {
      const matchingVariants = possibleVariants.filter((possibleVariant) => {
        return possibleVariant.option_value_ids.includes(previousOptionValueId);
      });

      if (matchingVariants.length) {
        possibleVariants = matchingVariants;
      }
    });

    this.variant = possibleVariants[0];
    await this.enforceFixedSizeVariant();
  }

  async setProductFromMouldingShopify () {
    const mouldingOptions = await this.getMouldingOptions();
    const currentMouldingOption = mouldingOptions.mouldings
      .filter( mouldingOption => mouldingOption.shopifyProductId )
      .find( mouldingOption => mouldingOption.permalink == this.moulding.permalink );
    if (currentMouldingOption) {
      if (this.catalog.shopifyProductId != currentMouldingOption.shopifyProductId) {
        this.catalog.shopifyProductId = currentMouldingOption.shopifyProductId;
        await this.loadShopifyProduct();
      }
    } else {
      console.error("Matching moulding not found!");
    }
  }

  // based on: moulding, mat, accent mat, size
  setVariantToClosestVariantShopify () {
    let possibleVariants = this.product.variants.filter(v => v.frameSpec);;

    const mouldingVariants = possibleVariants.filter( v => v.frameSpec.moulding.permalink === this.moulding.permalink );
    if (mouldingVariants.length) {
      possibleVariants = mouldingVariants;
    }

    const matVariants = possibleVariants.filter(
      v => v.frameSpec.mats[0] && this.mats[0] && (v.frameSpec.mats[0].name === this.mats[0].name)
    );
    if (matVariants.length) {
      possibleVariants = matVariants;
    }

    const accentMatVariants = possibleVariants.filter(
      v => v.frameSpec.mats[1] && this.mats[1] && (v.frameSpec.mats[1].name === this.mats[1].name)
    );
    if (accentMatVariants.length) {
      possibleVariants = accentMatVariants;
    }

    // Select on exact size for curated
    if (this.isCuratedDesignFrame()) {
      const sizeVariants = possibleVariants.filter(
        v => v.frameSpec.artworks[0]
          && this.artworks[0]
          && this.artworks[0].exterior
          && (v.frameSpec.artworks[0].exterior.widthInInches === this.artworks[0].exterior.widthInInches)
          && (v.frameSpec.artworks[0].exterior.heightInInches === this.artworks[0].exterior.heightInInches)
      );
      if (sizeVariants.length) {
        possibleVariants = sizeVariants;
      }
    } else {
      // TODO: Select on size group for custom
    }

    this.variant = possibleVariants[0];
    this.catalog.shopifyVariantId = possibleVariants[0].shopifyVariantId;
  }

  async enforceFixedSizeVariantShopify () {
    // Use template artwork size and auto-crop if necessary
    if (this.artworks[0]) {
      const oldExteriorRatio = this.artworks[0].exterior.widthInInches / this.artworks[0].exterior.heightInInches;

      this.artworks[0].exterior = {
        widthInInches: parseFloat(this.variant.frameSpec.artworks[0].exterior.widthInInches),
        heightInInches: parseFloat(this.variant.frameSpec.artworks[0].exterior.heightInInches)
      };

      if (!this.artworks[0].productCategory && this.isDigital()) {
        this.artworks[0].productCategory = "digital_photo";
      }

      const newExteriorRatio = this.artworks[0].exterior.widthInInches / this.artworks[0].exterior.heightInInches;

      if (newExteriorRatio !== oldExteriorRatio) {
        await this.autoCrop();
      }
    }

    // Save the mat caption and moulding caption
    const matCaption = this.mats[0] ? this.mats[0].matCaption : false;
    const mouldingPlate = this.moulding.mouldingPlate

    this.setMoulding({permalink: this.variant.frameSpec.moulding.permalink});
    this.adornments = this.variant.adornments;
    this.mount = this.variant.frameSpec.mount;
    this.mats = this.variant.frameSpec.mats;

    // Slice back in the possibly-customized mat caption
    if (this.mats[0]) {
      this.setMatCaption(matCaption);
    }

    // Slice back in the mouldingPlate
    this.moulding.mouldingPlate = mouldingPlate;
  }

  setVariantFromSizeGroupAndConveyance () {
    let possibleVariants = [...this.product.variants];

    const sizeGroup = this.sizeGroup() && this.sizeGroup().name;
    const sizeGroupVariants = possibleVariants.filter( v => v.selectedOptions[0].value === sizeGroup );
    if (sizeGroupVariants.length) {
      possibleVariants = sizeGroupVariants;
    }

    const conveyance = this.isPhysical() ? "Physical" : "Digital";
    const conveyanceVariants = possibleVariants.filter( v => v.selectedOptions[1].value === conveyance );
    if (conveyanceVariants.length) {
      possibleVariants = conveyanceVariants;
    }

    this.variant = possibleVariants[0];
    this.catalog.shopifyVariantId = possibleVariants[0].shopifyVariantId;
  }

  async updateVariantAndConstraints () {
    if (this.designersChoiceRequested) {
      this.setVariantFromSizeGroupAndConveyance();
    } else if (this.isCustomProduct()) {
      await this.setProductFromMouldingShopify();
      this.setVariantFromSizeGroupAndConveyance();
    } else if (this.isCuratedDesignFrame()) {
      this.setVariantToClosestVariantShopify();
      await this.enforceFixedSizeVariantShopify();
    } else if (this.isGetThisLookProduct()) {
      this.catalog.shopifyGetThisLookVariantId = this.catalog.shopifyVariantId;
      this.catalog.shopifyGetThisLookProductId = this.catalog.shopifyProductId;
      await this.setProductFromMouldingShopify();
    } else {
      throw new Error("Unknown frame spec type");
    }
  }

  async enforceFixedSizeVariant () {
    const initialMatCaption = this.mats[0] ? this.mats[0].matCaption : false;

    this.moulding.permalink = this.variant.moulding.name;

    const oldExteriorRatio =
      this.artworks[0].exterior.widthInInches / this.artworks[0].exterior.heightInInches;

    this.artworks[0].exterior = {
      widthInInches: parseFloat(this.variant.fixed_art_width.name),
      heightInInches: parseFloat(this.variant.fixed_art_height.name)
    };

    // TODO: Fixed size does not guarantee digital and we should get rid of this magic value
    this.artworks[0].productCategory = "digital_photo";
    const newExteriorRatio =
      this.artworks[0].exterior.widthInInches / this.artworks[0].exterior.heightInInches;

    if (newExteriorRatio !== oldExteriorRatio) {
      await this.autoCrop();
    }

    if (this.variant.mat.name === noMatName ||
        this.variant.mat.name === acrylicMatName) {
      this.mats = [];
    } else {
      this.mats = snackCaseToCamelCase(this.variant.frame_spec.mats);
      if (initialMatCaption) {
        this.setMatCaption(initialMatCaption);
      }
    }
    this.adornments = snackCaseToCamelCase(this.variant.frame_spec.adornments);

    this.mount = snackCaseToCamelCase(this.variant.frame_spec.mount);
  }

  async autoCrop (args) {
    if (this.needsCrop()) {
      const artworkEditor = await ArtworkEditor.create({
        glazeClient: this.joineryClient,
        frameSpec: this
      });

      artworkEditor.cropToExterior();
      await artworkEditor.applyEdits();

      if (args && args.saveToServer) {
        await artworkEditor.saveToServer();
      }

      this.artworkEditsApplied = true; // So we know to upload the edited image later
    }
  }

  setArtwork (artwork, index = 0) {
    const initialArtwork = {...this.artworks[index]};
    this.artworks[index] = artwork;
    if (this.isCuratedDesignFrame()) {
      this.artworks[index].exterior = initialArtwork.exterior;
    }
  }

  clearArtworkOpeningDimensions () {
    if (this.artworks[0]) {
      this.artworks[0].exterior.artworkOpeningWidthInInches = undefined;
      this.artworks[0].exterior.artworkOpeningHeightInInches = undefined;
    }
  }

  syncArtworkOpeningDimensionsToArtwork () {
    if (this.artworks[0]) {
      this.artworks[0].exterior.artworkOpeningWidthInInches = this.artworks[0].exterior.widthInInches;
      this.artworks[0].exterior.artworkOpeningHeightInInches = this.artworks[0].exterior.heightInInches;
    }
  }

  handleArtworkOpeningDimensions () {
    if (this.artworks[0] && this.isDigital() && this.hasMat()) {
      this.clearArtworkOpeningDimensions();
    } else {
      this.syncArtworkOpeningDimensionsToArtwork();
    }
  }

  setArtworkWidth (value) {
    if (!this.artworks[0]) {
      return;
    }

    this.artworks[0].exterior.widthInInches = value;
    this.artworks[0].exterior.artworkOpeningWidthInInches = undefined;
  }

  setArtworkHeight (value) {
    if (!this.artworks[0]) {
      return;
    }

    this.artworks[0].exterior.heightInInches = value;
    this.artworks[0].exterior.artworkOpeningHeightInInches = undefined;
  }

  setMoulding (moulding) {
    if (!moulding) {
      return;
    }

    this.moulding = {
      permalink: moulding.permalink,
      mouldingPlateAllowed: moulding.mouldingPlateAllowed
    };
  }

  changeToCanvas () {
    this.setMoulding({ permalink: "heathrow-black-canvas-frame" });
    this.removeMats();
    if (this.artworks[0]) {
      this.artworks[0].medium = "canvas";
    }
  }

  changeFromCanvas () {
    this.setMoulding({ permalink: "mercer-slim-black-frame" });
    const mat = { name: whiteMatName };
    this.setMat(mat)
  }

  environment () {
    if (this.joineryHost.includes("docker.internal")) {
      return "development";
    } else if (this.joineryHost.includes("joinery")) {
      return "production";
    } else {
      return "staging";
    }
  }

  convexBaseUrl () {
    if (this.environment() === "development") {
      return "http://host.docker.internal:5000";
    } else if (this.environment() === "staging") {
      return "https://convex.staging.framebridge.io";
    } else {
      return "https://convex.framebridge.io";
    }
  }

  async retrieveProductById (productId) {
    try {
      const resp = await axios.get(`${this.joineryHost}/api/v1/shopify_product_details/${productId}`);
      return resp.data;
    } catch (error) {
      console.error("Error when fetching product", error);
      return null;
    }
  }

  async retrieveJerseyProduct () {
    const jerseyProductId = this.environment() === "production" ? "9050290422075" : "8882862522658";
    if (jerseyProductId) {
      return await this.retrieveProductById(jerseyProductId);
    } else {
      return null;
    }
  }

  setMat (value, customReveal) {
    const fakeMatSkus = [noMatName, canvasMatName, floatMountMatName, acrylicMatName, jerseyMatName, accentMatName];

    if (!fakeMatSkus.includes(value.name)) {
      let reveal;

      if (!this.isCustomProduct()) {
        reveal = {
          topInInches: parseFloat(this.variant.framing_specification.mat_width_in_inches_top),
          rightInInches: parseFloat(this.variant.framing_specification.mat_width_in_inches_right),
          bottomInInches: parseFloat(this.variant.framing_specification.mat_width_in_inches_bottom),
          leftInInches: parseFloat(this.variant.framing_specification.mat_width_in_inches_left)
        };
      } else if (customReveal) {
        reveal = {
          topInInches:  customReveal.topInInches,
          rightInInches:  customReveal.rightInInches,
          bottomInInches:   customReveal.bottomInInches,
          leftInInches:   customReveal.leftInInches
        };
      } else if (this.mats[0] && Boolean(this.mats[0].reveal)) {
        reveal = this.mats[0].reveal;
      } else {
        reveal = {
          topInInches:  this.sizeGroup() ? this.sizeGroup().matWidth : undefined,
          rightInInches:  this.sizeGroup() ? this.sizeGroup().matWidth : undefined,
          bottomInInches:   this.sizeGroup() ? this.sizeGroup().matWidth : undefined,
          leftInInches:   this.sizeGroup() ? this.sizeGroup().matWidth : undefined
        };
      }

      this.mats[0] = {
        name: value.name,
        reveal,
        matCaption: this.mats[0] ? this.mats[0].matCaption : undefined,
        matStyle: value.style ? value.style : undefined
      };
    }
  }

  removeMats () {
    this.mats = [];
  }

  setAccentMat (value) {
    if (!value) {
      value = this.mats[1] ? this.mats[1] : this.product.accent_mat_options[0];
    }

    const accentMatReveal = {
      topInInches: defaultAccentMatReveal,
      rightInInches: defaultAccentMatReveal,
      bottomInInches: defaultAccentMatReveal,
      leftInInches: defaultAccentMatReveal
    };

    const accentMat = {
      name: value.name,
      reveal: accentMatReveal
    };

    this.mats[1] = accentMat;
  }

  removeAccentMats () {
    if (this.mats.length) {
      this.mats.length = 1;
    }
  }

  setReveal (value, index = 0) {
    const mat = this.mats[index];
    if (!mat) {
      return;
    }

    mat.reveal = {
      topInInches: value.topInInches,
      rightInInches: value.rightInInches,
      bottomInInches: value.bottomInInches,
      leftInInches: value.leftInInches
    };
  }

  setMount (value, hasCustomWidths = false) {
    if (!this.isCustomProduct()) {
      const variantFS = this.variant.framing_specification;
      this.mount.reveal = {
        topInInches: parseFloat(variantFS.artwork_mount_reveal_in_inches_top),
        rightInInches: parseFloat(variantFS.artwork_mount_reveal_in_inches_right),
        bottomInInches: parseFloat(variantFS.artwork_mount_reveal_in_inches_bottom),
        leftInInches: parseFloat(variantFS.artwork_mount_reveal_in_inches_left)
      };
      this.mount.elevated = false;
    } else if (value.name === floatMountMatName || value.name === acrylicMatName) {
      this.mount.reveal = {
        topInInches: floatMountMatReveal,
        rightInInches: floatMountMatReveal,
        bottomInInches: floatMountMatReveal,
        leftInInches: floatMountMatReveal
      };
      this.mount.elevated = true;
      this.mount.name = value.name === acrylicMatName ? value.name : whiteMatName;
      this.mount.sku = value.name === acrylicMatName ? value.name : whiteMatName;
    } else if (hasCustomWidths) {
       this.mount.reveal = {
        topInInches: value.topInInches,
        rightInInches: value.rightInInches,
        bottomInInches: value.bottomInInches,
        leftInInches: value.leftInInches
      };
      this.mount.elevated = true;
    } else {
      this.mount.reveal = {topInInches: 0, rightInInches: 0, bottomInInches: 0, leftInInches: 0};
      this.mount.elevated = false;
      this.mount.name = whiteMatName;
      this.mount.sku = whiteMatName;
    }
  }

  setKraftPaper (kraftPaper) {
    this.customKraftPaper = kraftPaper;
  }

  removeKraftPaper () {
    delete this.customKraftPaper;
  }

	setCustomAcrylic (customAcrylic) {
		this.customCustomAcrylic = customAcrylic;
	}

	removeCustomAcrylic () {
		delete this.customCustomAcrylic;
	}

  isKraftPaperAvailable () {
    const disabledFor = ["piccolo", "mezzo", "regalo"];
    const isDisabled = disabledFor.find(
      (frame) => this.moulding.permalink.includes(frame)
    ) || this.isOrnamentFrame();

    return this.product && this.product.tags ? (
      this.product.tags.includes("custom-kraft-paper")
      || this.product.tags.includes("custom-kraft-paper-auto")
    ) : !isDisabled;
  }
  isAutoKraftPaper () {
    return this.product && this.product.tags && this.product.tags.includes("custom-kraft-paper-auto");
  }

  isAutoGiftBox () {
    return this.product && this.product.tags && this.product.tags.includes("gift-box-auto");
  }
  setMatCaption (matCaption) {
    if (this.mats[0] && matCaption) {
      const scratchMatCaption = {...matCaption};

      const stylesDefinedPerLine = Boolean(matCaption.lines && Object.values(matCaption.lines).some((line) => line.fontStyle));

      if (stylesDefinedPerLine) {
        const lines = matCaption && matCaption.lines ? matCaption.lines : {};
        scratchMatCaption.layoutName = matCaption.layoutName;
        scratchMatCaption.lines = {
          line1: {
            fontAlignment: matCaption.fontAlignment,
            fontStyle: lines.line1 && lines.line1.fontStyle ? lines.line1.fontStyle : undefined,
            fontSize: lines.line1 && lines.line1.fontSize ? lines.line1.fontSize : undefined,
            message: lines.line1 && lines.line1.message ? lines.line1.message : undefined,
          },
          line2: {
            fontAlignment: matCaption.fontAlignment,
            fontStyle: lines.line2 && lines.line2.fontStyle ? lines.line2.fontStyle : undefined,
            fontSize: lines.line2 && lines.line2.fontSize ? lines.line2.fontSize : undefined,
            message: lines.line2 && lines.line2.message ? lines.line2.message : undefined,
          },
          line3: {
            fontAlignment: matCaption.fontAlignment,
            fontStyle: lines.line3 && lines.line3.fontStyle ? lines.line3.fontStyle : undefined,
            fontSize: lines.line3 && lines.line3.fontSize ? lines.line3.fontSize : undefined,
            message: lines.line3 && lines.line3.message ? lines.line3.message : undefined,
          },
          line4: {
            fontAlignment: matCaption.fontAlignment,
            fontStyle: lines.line4 && lines.line4.fontStyle ? lines.line4.fontStyle : undefined,
            fontSize: lines.line4 && lines.line4.fontSize ? lines.line4.fontSize : undefined,
            message: lines.line4 && lines.line4.message ? lines.line4.message : undefined,
          },
        }
      }

      this.mats[0].matCaption = scratchMatCaption;
    }
  }

  removeMatCaption () {
    if (this.mats[0]) {
      delete this.mats[0].matCaption;
    }
  }

  hasMatCaption () {
    return Boolean(this.mats
      && this.mats[0]
      && this.mats[0].matCaption
    );
  }

  setStoryPocket (storyPocket) {
    this.storyPocket = storyPocket;
  }

  removeStoryPocket () {
    delete this.storyPocket;
  }

  setHangingHardwareToDefault() {
    delete this.hangingHardware;
  }

  setMiniStoryPocket (miniStoryPocket) {
    this.miniStoryPocket = miniStoryPocket;
  }

  removeMiniStoryPocket () {
    delete this.miniStoryPocket;
  }

  setCustomKraftPaper (customKraftPaper) {
    this.customKraftPaper = customKraftPaper;
  }

  removeCustomKraftPaper () {
    delete this.customKraftPaper;
  }
  setMouldingPlate (mouldingPlate) {
    if (!this.moulding) {
      return;
    }

    this.moulding.mouldingPlate = mouldingPlate;
  }

  removeMouldingPlate () {
    if (!this.moulding) {
      return;
    }

    delete this.moulding.mouldingPlate;
  }

  hasMouldingPlate () {
    return Boolean(
      this.moulding
      && this.moulding.mouldingPlate
      && (
        this.moulding.mouldingPlate.line1
        || this.moulding.mouldingPlate.line2
      )
    );
  }

  setGiftBox (giftBoxSku) {
    this.giftBox = { sku: giftBoxSku };
  }

  removeGiftBox (config = {
    soft: false
  }) {
    if (config.soft) {
      delete this.giftBox;
    } else {
      this.giftBox = { explicitlyRemoved: true };
    }
  }

  setAcrylic () {
    this.customAcrylic = { sku: '13-022' };
  }

  removeAcrylic () {
    delete this.customAcrylic;
  }

  async setDesignersChoiceRequested() {
    this.designersChoiceRequested = true;

    // TODO: Look up by slug instead of hard-wiring the ID
    const envDesignerChoiceId = this.shopifyHost == "https://staging.framebridge.dev" ? "8238233420066" : "8671385878843";
    this.catalog.shopifyProductId = envDesignerChoiceId;

    if (this.productCategory() === "canvas") {
      this.changeToCanvas();
    }

    await this.loadShopifyProduct();
  }

  getFromLocalStorage () {
    // While it is true that we store the user's frameSpec in localstorage, this is a practice that
    // we try to avoid.
    if (typeof window === "undefined") {
      return;
    }

    const stringyFrameSpec = window.localStorage.getItem("currentFrameSpec");

    if (stringyFrameSpec) {
      return JSON.parse(stringyFrameSpec);
    }
  };

  setInLocalStorage () {
    // While it is true that we store the user's frameSpec in localstorage, this is a practice that
    // we try to avoid.
    if (typeof window === "undefined") {
      return;
    }

    window.localStorage.setItem("currentFrameSpec", JSON.stringify(this));

    return window.localStorage.getItem("currentFrameSpec");
  };

  clearFromLocalStorage () {
    // While it is true that we store the user's frameSpec in localstorage, this is a practice that
    // we try to avoid.
    if (typeof window === "undefined") {
      return;
    }

    window.localStorage.removeItem("currentFrameSpec");
  };

  // FrameSpec Setters and Mutations ↑
}
